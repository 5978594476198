
import { releaseToken } from "../components/shared/dataManager/SharedPersistenceManager";
import CasosAPI from "../../shared/api/CasosAPI";
import ClientesAPI from "../../shared/api/ClientesAPI";
import MensajeriaAPI from "../../shared/api/MensajeriaAPI";
import MEVAPI from "../../shared/api/MEVAPI";
import UsersAPI from "../../shared/api/UsersAPI";
import DataSession from "./DataSession";
import { host, endPointLogout } from "../../shared/api/APIConsts";
import axios from "axios";
import ContactosAPI from "../../shared/api/ContactosAPI";


class Session {

    static _instance = null;

    _dataSession = new DataSession(); // Session's data cache

    constructor() {
        if (Session._instance !== null)
            throw new Error('Falla de instanciación: Session es singleton. Utilizar Session.getInstance()');
        else
            Session._instance = this;
    }

    static getInstance = () => {
        if (Session._instance === null)
            Session._instance = new Session();
        return Session._instance;
    };

    
    static setInstance = async (user) => {
        // Reacomodamos tal como el BE responde el request del sign_in
        user.ciudad = user._loggedEstudio.ciudad;
        user['código postal'] = user._loggedEstudio['código postal'];
        user['dirección'] = user._loggedEstudio['dirección'];
        user['telefono_estudio'] = user._loggedEstudio['telefono_estudio'];
        user['email_estudio'] = user._loggedEstudio['email_estudio'];
        //------------------------------------------------------------

        this.getInstance().setLoggedUser(user);
        await this.getInstance().getInitialData(user.email);
        return ;
    };

    /* static setInstance = async (recoveryInstance) => {
        delete this._instance;
        this._instance = null;
        return await Session.getInstance()._dataSession.setLoggedUser(recoveryInstance._dataSession._loggedUser)
        .then(() => {
            Object.keys(recoveryInstance._dataSession).map(key => {
                if (key === '_criterioBusquedaCasos' | key === '_criterioBusquedaClientes' | key === '_criterioBusquedaMEV' | key === '_roles') {
                    Object.keys(recoveryInstance._dataSession[key]).map(subkey => {
                        Session.getInstance()._dataSession[key][subkey] = recoveryInstance._dataSession[key][subkey];
                    });
                } else {
                    Session.getInstance()._dataSession[key] = recoveryInstance._dataSession[key];
                }
            });
            return ;
        })
    }; */

    // Roles y Permisos
    isRoleDisabled = (r) => { return this._dataSession.isRoleDisabled(r) }

    // Setters
    setLoggedUser = (u) => { this._dataSession.setLoggedUser(u) }

    // Getters
    getLoggedUser = () => { return this._dataSession.getLoggedUser() }


    // Register of DataSession Setters
    setQuasarUsers = (us) => { return this._dataSession.setQuasarUsers(us) }
    setUsers = (us) => { return this._dataSession.setUsers(us) }
    setCasos = (casos) => { return this._dataSession.setCasos(casos) }
    setContactos = (contactos) => { return this._dataSession.setContactos(contactos) }
    setClientes = (clientes) => { return this._dataSession.setClientes(clientes) }
    setMails = (mails) => { return this._dataSession.setMails(mails) }
    setExpedientesMEV = (mevs) => { return this._dataSession.setExpedientesMEV(mevs) }
    setMisSetsMEV = (sets) => { return this._dataSession.setMisSetsMEV(sets) }

    setCriterioBusquedaCasos = (c, newCli) => { return this._dataSession.setCriterioBusquedaCasos(c, newCli) }
    setCriterioBusquedaContactos = (c) => { return this._dataSession.setCriterioBusquedaContactos(c) }
    setCriterioBusquedaClientes = (c) => { return this._dataSession.setCriterioBusquedaClientes(c) }
    setCriterioBusquedaMEV = (c) => { return this._dataSession.setCriterioBusquedaMEV(c) }

    setMailsCountCasesClients = (c) => { return this._dataSession.setMailsCountCasesClients(c) }
    updateCasosCountUnreadMails = (c, count) => { return this._dataSession.updateCasosCountUnreadMails(c, count) }
    updateClientesCountUnreadMails = (c, count) => { return this._dataSession.updateClientesCountUnreadMails(c, count) }

    // Register of DataSession Getters
    //hasContactoUnreadMails = (contacto) => { return this._dataSession.hasContactoUnreadMails(contacto) }
    hasCasoUnreadMails = (caso) => { return this._dataSession.hasCasoUnreadMails(caso) }
    hasClienteUnreadMails = (cli) => { return this._dataSession.hasClienteUnreadMails(cli) }

    getUsers = () => { return this._dataSession.getUsers() }
    getUserById = (id) => { return this._dataSession.getUserById(id) }

    getCasos = () => { return this._dataSession.getCasos() }
    getCasosBackEnd = async () => { return await this._dataSession.getCasosBackEnd() }
    getContactos = () => { return this._dataSession.getContactos() }
    getContactosBackEnd = async () => { return await this._dataSession.getContactosBackEnd() }
    getClientes = () => { return this._dataSession.getClientes() }
    getClientesBackEnd = async () => { return await this._dataSession.getClientesBackEnd() }
    getMails = () => { return this._dataSession.getMails() }

    getExpedientesMEV = () => { return this._dataSession.getExpedientesMEV() }
    getExpedientesMEVBackEnd = async () => { return await this._dataSession.getExpedientesMEVBackEnd() }
    getExpedientesMEVBackEndChangedUser = async (idUser) => { return await this._dataSession.getExpedientesMEVBackEndChangedUser(idUser) }
    getExpedientesMEVBackEndChangedUserUnfiltered = async (idUser) => { return await this._dataSession.getExpedientesMEVBackEndChangedUserUnfiltered(idUser) }

    getMisSetsMEV = () => { return this._dataSession.getMisSetsMEV() }

    getCriterioBusquedaCasos = () => { return this._dataSession.getCriterioBusquedaCasos() }
    getAlternativasBusquedaCasos = () => { return this._dataSession.getAlternativasBusquedaCasos() }

    getCriterioBusquedaContactos = () => { return this._dataSession.getCriterioBusquedaContactos() }
    getAlternativasBusquedaContactos = () => { return this._dataSession.getAlternativasBusquedaContactos() }

    getCriterioBusquedaClientes = () => { return this._dataSession.getCriterioBusquedaClientes() }
    getAlternativasBusquedaClientes = () => { return this._dataSession.getAlternativasBusquedaClientes() }

    getCriterioBusquedaMEV = () => { return this._dataSession.getCriterioBusquedaMEV() }
    getAlternativasBusquedaMEV = () => { return this._dataSession.getAlternativasBusquedaMEV() }

    getMailsUnreadNumber = () => { return this._dataSession.getMailsUnreadNumber() }


    // Straight (Non-Cached) BackEnd Getters
    getNonCachedClients = () => { 
        return ClientesAPI.getInstance().getEstudioClients(false)
        .then(clientesOut => {
            return clientesOut.sort((elemA, elemB) => elemA.apellido < elemB.apellido? -1 : 1);
        })
        .catch (err => {
            throw err;
        });
    }

    getNonCachedCasos = () => { 
        return CasosAPI.getInstance().getEstudioCasos(false)
        .then(casosOut => {
            return casosOut.sort((elemA, elemB) => elemA.titulo < elemB.titulo? -1: 1);
        })
        .catch (err => {
            throw err;
        });
    }

    // Utilities
    getUnfilteredContactos = () => {
        return this._dataSession.getUnfilteredContactos();
    }

    getUnfilteredClientes = () => {
        return this._dataSession.getUnfilteredClientes();
    }

    getUnfilteredCasos = () => {
        return this._dataSession.getUnfilteredCasos();
    }

    getUserString = (id) => {
        var user = Object.values(this.getUsers()).filter(u => u.id === id)[0];
        if (!user) { // Esto salva las entradas viejas donde se utilizaba el email como id en lugar del id numérico de usuario
            user = this.getUsers()[id];
        }
        return `${user.nombre} ${user.apellido}`;
    }
    getContactoString = (id, label) => {
        const contacto = this.getUnfilteredContactos().filter(contacto => contacto.id === id)[0];
        return contacto === undefined ? label ? label : <em>[desvinculado]</em> : `${contacto.nombre} ${contacto.apellido}`
    }
    getClienteString = (id, label) => {
        const cliente = this.getUnfilteredClientes().filter(cliente => cliente.id === id)[0];
        return cliente === undefined ? label ? label : <em>[desvinculado]</em> : `${cliente.nombre} ${cliente.apellido}`
    }
    getCasoString = (id, label) => {
        const caso = this.getUnfilteredCasos().filter(caso => caso.id === id)[0];
        return caso === undefined ? label ? label : <em>[desvinculado]</em> : caso.name
    }


    refreshUserMails = async () => {
        await Promise.resolve(MensajeriaAPI.getInstance().getMensajesUser());
    };


    // Initial Data
    getInitialData = async (email) => {
        try {
            if (UsersAPI.getInstance().isBackOfficeON()) {
                await UsersAPI.getInstance().getQuasarUsersAuth0()
                .then(quasaries => {
                    this.setQuasarUsers(quasaries);
                })
            }

            const criterioBusquedaMEV = this.getCriterioBusquedaMEV();
            await Promise.all(
                [
                    await Promise.resolve(UsersAPI.getInstance().getAssociatedUsers()),

                    await Promise.resolve(MensajeriaAPI.getInstance().getMensajesUser()),

                    Promise.resolve(CasosAPI.getInstance().getEstudioCasos(true)), // coment
                    Promise.resolve(ClientesAPI.getInstance().getEstudioClients(true)),
                    Promise.resolve(ContactosAPI.getInstance().getEstudioContactos(true)),
                    Promise.resolve(MEVAPI.getInstance().getMiMEV(criterioBusquedaMEV)),
                    Promise.resolve(MEVAPI.getInstance().getMisSetsMEV(criterioBusquedaMEV)),
                    //Promise.resolve(MensajeriaAPI.getInstance().getMensajesUser())
                ]);
        } catch (error) {
            console.log(error);
            return 'ERROR while retrieving initial data';
        }
    }

    // Instance attachment
    login = async (email, pass) => {
        const args = { 'email': email, 'pass': pass };
        try {
            await UsersAPI.getInstance().signIn(args).then((async data => {
                if ((data !== 'EMAIL_NOT_FOUND') && (data !== 'INVALID_PASSWORD')) {
                    await this.getInitialData(email);
                }
                return data;
            }));
        } catch (error) {
            console.log(error);
            return 'LOGIN_ERROR';
        }
    }

    // Instance detachment
    logout = () => releaseToken()
        .then(releaseResponse => axios.get(`${host}/${endPointLogout}`))
        .then(logoutResponse => {
            UsersAPI.getInstance().unsetAuthorization();
            delete this._instance;
            //Session._instance = null;
        });
}

export default Session;
