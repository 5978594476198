import React from "react";

import AdministradorIcon from '@mui/icons-material/AdminPanelSettings';
import CuentaIcon from '@mui/icons-material/Settings';
import EstadisticaIcon from '@mui/icons-material/ShowChart';
import UsuariosIcon from '@mui/icons-material/People';
import PlantillasIcon from '@mui/icons-material/DocumentScanner';
import ModelosIcon from '@mui/icons-material/FindInPage';
import SuscripcionIcon from "@material-ui/icons/AccountBalance";

import EstructurasOperations from "../../../../shared/components/TabComponents/EstructurasOperations"

export default class EstructurasConst {
    // Estos son los id de las collections correspondientes en firebase
    static idEstadistica = 'Estadistica';                                       // 1
    static idCuenta = 'Cuenta';                                                 // 2
    static idUsuarios = 'Usuarios';                                             // 3
    static idPlantillas = 'Plantillas';                                         // 4
    static idSuscripcion = 'Suscripcion';                                       // 5
    static idModelos = 'Modelos';                                               // 6

    static idAdministrador = 'Admin de Cuenta';
    static idContenidos = 'Admin de Plantillas';

    static clasesExcludedEstructura = [EstructurasConst.idAdministrador, EstructurasConst.idContenidos];
    static clasesForcedIcon = [EstructurasConst.idAdministrador];

    static idClaseZero = EstructurasConst.idAdministrador;
    static idEstructuraZero = EstructurasConst.idEstadistica;
    static idEstructuraHome = EstructurasConst.idEstadistica;

    static clasesEstructuraIds = {
        [EstructurasConst.idAdministrador]: [EstructurasConst.idEstadistica, EstructurasConst.idUsuarios, EstructurasConst.idCuenta, EstructurasConst.idSuscripcion],
        [EstructurasConst.idContenidos]: [EstructurasConst.idPlantillas, EstructurasConst.idModelos],
    };

    static estructuraLabels = {
        [EstructurasConst.idEstadistica]: 'Centro Estadístico',
        [EstructurasConst.idCuenta]: 'Detalles de Cuenta',
        [EstructurasConst.idUsuarios]: 'Usuarios del Estudio',
        [EstructurasConst.idPlantillas]: 'Mis Plantillas',
        [EstructurasConst.idModelos]: 'Repositorio de Modelos',
        [EstructurasConst.idSuscripcion]: 'Suscripción',
    };
    
    static arrayOfIdClases = Object.keys(EstructurasConst.clasesEstructuraIds);
    static estructuraIds = Object.keys(EstructurasConst.estructuraLabels);

    static estructuraVacia = {
        [EstructurasConst.idEstadistica]: {}, 
        [EstructurasConst.idCuenta]: {}, 
        [EstructurasConst.idUsuarios]: {}, 
        [EstructurasConst.idPlantillas]: {}, 
        [EstructurasConst.idModelos]: {}, 
        [EstructurasConst.idSuscripcion]: {}, 
    }

    static icons = {
        [EstructurasConst.idEstadistica]: <EstadisticaIcon key={1} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idCuenta]: <CuentaIcon key={2} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idUsuarios]: <UsuariosIcon key={3} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idPlantillas]: <PlantillasIcon key={4} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idModelos]: <ModelosIcon key={6} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idSuscripcion]: <SuscripcionIcon key={5} fontSize={EstructurasOperations.fontSize} />,

        [EstructurasConst.idAdministrador]: <AdministradorIcon key={'0.clase'} fontSize={EstructurasOperations.fontSize}/>,
    }
}