import React, { lazy, useCallback } from "react";

// Icons para Mensajería
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import PersonIcon from '@material-ui/icons/Person';
import ContactMailIcon from '@material-ui/icons/ContactMail';

// Otros Icons
import AssignmentIcon from '@material-ui/icons/Assignment';
import GavelIcon from '@material-ui/icons/Gavel';
import AgendaIcon from '@material-ui/icons/EventAvailable';
import PermMediaIcon from "@material-ui/icons/PermMedia";
import HomeIcon from "@material-ui/icons/Home";
import DescriptionIcon from "@material-ui/icons/Description";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import MonetizationIcon from '@material-ui/icons/MonetizationOn';
import EmailIcon from '@material-ui/icons/Email';
import PaymentIcon from '@material-ui/icons/Payment';
import HonorariosIcon from '@material-ui/icons/Work';
import HonorariosAbonoIcon from '@material-ui/icons/HomeWork';
import MEVIcon from '@material-ui/icons/CastConnected';
import StarIcon from '@material-ui/icons/Star';
import TelegramIcon from '@material-ui/icons/Telegram';
import GastosIcon from '@material-ui/icons/Money';
import CerradosIcon from '@material-ui/icons/Lock';
import AbiertosIcon from '@material-ui/icons/LockOpen';
import FacturacionIcon from '@material-ui/icons/Receipt';
import PagosIcon from '@material-ui/icons/Payment';
import BalanceIcon from '@material-ui/icons/AccountBalanceWallet';
import AbonoIcon from '@material-ui/icons/Business';

import EstructurasOperations from "../../../../shared/components/TabComponents/EstructurasOperations";

export default class EstructurasConst {
    
    // Identifico si corresponde a Clientes o Casos para los componentes que reciben esta clase por parámetro
    static soyClientes = true;

    // Estos son los id de las collections correspondientes en firebase
    static idDirectorioClientes = 'Directorio de Clientes';                 // 0
    static idEstructura = 'Estructura';                                     // 1
    static idDatosCliente = 'Datos del Cliente';                            // 3
    static idMailsVinculados = 'Mails Cliente';                             // 5
    static idCasos = 'Casos';                                               // 8
    static idPiezasPostales = 'Piezas Postales';                            // 9
    static idRecientes = 'Recientes';                                       // 14
    static idHonorariosPactados = 'Honorarios Pactados';                    // 15
    static idConsultas = 'Consultas';                                       // 16
    static idGastos = 'Gastos a Cuenta';                                    // 17
    static idMailsRecibidos = 'Mails Recibidos';                            // 18
    static idMailsEnviados = 'Mails Enviados';                              // 19
    static idAgendaCliente = 'Agenda Cliente';                              // 20
    static idAbono = 'Abonos';                                              // 21
    static idPagos = 'Pagos';                                               // 22
    static idBalance = 'Balance';                                           // 23
    static idFacturacion = 'Facturación';                                   // 24
    static idHonorariosAbono = 'Honorarios por Abono';                      // 25
    
    static idClaseDirectorio = EstructurasConst.idDirectorioClientes;
    static idClaseAccesosDirectos = 'Accesos Directos';
    static idClaseInformacion = 'Información General';
    static idClaseConsultas = 'Tareas';
    static idClaseHonorarios = 'Honorarios';
    static idClaseMensajeria = 'Mensajería';


    static clasesExcludedEstructura = [EstructurasConst.idClaseDirectorio, EstructurasConst.idClaseAccesosDirectos];
    static clasesForcedIcon = [EstructurasConst.idClaseDirectorio, EstructurasConst.idClaseAccesosDirectos];

    static idClaseZero = EstructurasConst.idClaseAccesosDirectos;
    static idEstructuraZero = EstructurasConst.idEstructura;
    static idEstructuraHome = EstructurasConst.idEstructura;


    static clasesEstructuraIds = {
        [EstructurasConst.idClaseDirectorio]: [EstructurasConst.idDirectorioClientes],
        [EstructurasConst.idClaseAccesosDirectos]: [EstructurasConst.idEstructura, EstructurasConst.idRecientes],
        [EstructurasConst.idClaseInformacion]: [EstructurasConst.idDatosCliente, EstructurasConst.idCasos, EstructurasConst.idAgendaCliente, EstructurasConst.idAbono], 
        [EstructurasConst.idClaseConsultas]: [EstructurasConst.idConsultas, EstructurasConst.idPiezasPostales, EstructurasConst.idGastos], 
        [EstructurasConst.idClaseHonorarios]: [EstructurasConst.idHonorariosPactados, EstructurasConst.idHonorariosAbono, EstructurasConst.idPagos, EstructurasConst.idFacturacion, EstructurasConst.idBalance], 
        [EstructurasConst.idClaseMensajeria]: [EstructurasConst.idMailsRecibidos, /* EstructurasConst.idMailsEnviados, */ EstructurasConst.idMailsVinculados], 
    };
    

    static estructuraLabels = {
        [EstructurasConst.idDirectorioClientes]: 'Directorio de Clientes',
        [EstructurasConst.idEstructura]: 'Estructura',
        [EstructurasConst.idRecientes]: 'Modificaciones Recientes',
        [EstructurasConst.idDatosCliente]: 'Datos del Cliente',
        [EstructurasConst.idMailsVinculados]: 'Externos',
        [EstructurasConst.idMailsRecibidos]: 'Internos',
        [EstructurasConst.idCasos]: 'Casos',
        [EstructurasConst.idAgendaCliente]: 'Agenda del Cliente',    
        [EstructurasConst.idPiezasPostales]: 'Redacción Piezas Postales',
        [EstructurasConst.idHonorariosAbono]: 'Honorarios por Abono',
        [EstructurasConst.idHonorariosPactados]: 'Honorarios por Tarea',
        [EstructurasConst.idConsultas]: 'Trabajos/Consultas',
        [EstructurasConst.idGastos]: 'Gastos',
        [EstructurasConst.idAbono]: 'Abonos Pactados',
        [EstructurasConst.idPagos]: 'Pagos Recibidos',
        [EstructurasConst.idFacturacion]: 'Facturación',
        [EstructurasConst.idBalance]: 'Balance',
    };

    static arrayOfIdClases = Object.keys(EstructurasConst.clasesEstructuraIds);
    static estructuraIds = Object.keys(EstructurasConst.estructuraLabels);

    static datosCliente = [
        {id: 0, name: 'Tipo', type: 'Select', src: 'tipo', items: ['Persona Física', 'Persona Jurídica'], defaultItem: 0},
        {id: 1, name: {'Persona Física': 'Apellido Cliente', 'Persona Jurídica': 'Razón Social'}, type: 'SelectableString', srcSelect: 'tipo', src: 'apellido'},
        {id: 2, name: {'Persona Física': 'Nombre Cliente', 'Persona Jurídica': 'Tipo Societario'}, type: 'SelectableString', srcSelect: 'tipo', src: 'nombre'},
        
        {id: 3, name: 'Domicilio', type: 'String', src: 'direccion'},
        {id: 4, name: 'Localidad', type: 'String', src: 'localidad'},
        {id: 5, name: 'Código Postal', type: 'String', src: 'cp'},
        
        {id: 6, name: {'Persona Física': 'Teléfono', 'Persona Jurídica': 'Teléfono Contacto Ppal'}, type: 'SelectableString', srcSelect: 'tipo', src: 'phone_main'},
        {id: 7, name: {'Persona Física': 'Email', 'Persona Jurídica': 'Email Contacto Ppal'}, type: 'SelectableString', srcSelect: 'tipo', src: 'email'},
        {id: 8, name: {'Persona Física': 'DNI', 'Persona Jurídica': 'DNI Contacto Ppal'}, type: 'SelectableString', srcSelect: 'tipo', src: 'dni'},

        {id: 9, name: 'Identificación', type: 'Select', src: 'cui_sel', items: ['CUIL', 'CUIT'], defaultItem: 0},
        {id: 10, name: {'CUIL': 'CUIL', 'CUIT': 'CUIT'}, type: 'SelectableString', srcSelect: 'cui_sel', src: 'cuil_cuit'},
        //{id: 10, name: {'Persona Física': 'CUIL/CUIT', 'Persona Jurídica': 'CUIT'}, type: 'SelectableString', srcSelect: 'tipo', src: 'cuil_cuit'},
        {id: 11, name: 'Condición Impositiva', type: 'Select', src: 'impositiva', items: ['IVA Responsable Inscripto', 'IVA Exento', 'Monotributo', 'Consumidor Final', 'Cliente Extranjero'], defaultItem: 0},
        {id: 12, name: 'Actividad', type: 'String', src: 'actividad'},
        {id: 13, name: 'Registro del Cliente', type: 'Date', src: 'fecha_inicio'}, // Si cambia de orden el registro del cliente, cambiar la constante
        {id: 14, name: 'Personas y Otros Datos de Contacto', type: 'Autosize', src: 'telefono'},
    ];

    static getDatosClienteSinRegistroCliente = () => {
        return this.datosCliente.filter(attribute => attribute.src !== 'fecha_inicio')
    }

    static getPersJuridicaLabel = () => {
        return this.datosCliente.filter(attribute => attribute.src === 'tipo')[0].items[1]
    }

    static getPersFisicaLabel = () => {
        return this.datosCliente.filter(attribute => attribute.src === 'tipo')[0].items[0]
    }

    static getCUITLabel = () => {
        return this.datosCliente.filter(attribute => attribute.src === 'cui_sel')[0].items[1]
    }
    
    static getCUILLabel = () => {
        return this.datosCliente.filter(attribute => attribute.src === 'cui_sel')[0].items[0]
    }

    static datosAbono = [ // Si se cambia este orden hay que corregir Abonos.js
        {id: 0, name: 'Modalidad de Pago', type: 'String', src: 'modalidad', disabled: true},
        {id: 1, name: 'Fecha de Inicio', type: 'Date', src: 'fecha', disabled: true},
        {id: 2, name: 'Período', type: 'Select', src: 'periodo', items: ['Mensual', 'Bimestral', 'Semestral', 'Anual'], disabled: true},
        {id: 3, name: 'Honorarios por Período', type: 'String', src: 'monto', disabled: true},
        {id: 4, name: 'Acuerdo Detallado del Abono', type: 'TextArea', src: 'acuerdo', disabled: true},
    ];

        
    static estructuraVacia = {
        [EstructurasConst.idDirectorioClientes]: {}, 
        [EstructurasConst.idEstructura]: {}, 
        [EstructurasConst.idRecientes]: {}, 
        [EstructurasConst.idDatosCliente]: 
            EstructurasOperations.getElementsDatos(EstructurasConst.datosCliente),
        [EstructurasConst.idMailsVinculados]: {},
        [EstructurasConst.idMailsRecibidos]: {},
        //[EstructurasConst.idMailsEnviados]: {},
        [EstructurasConst.idCasos]: {},
        [EstructurasConst.idAgendaCliente]: {},
        [EstructurasConst.idPiezasPostales]: {},
        [EstructurasConst.idHonorariosAbono]: {},
        [EstructurasConst.idHonorariosPactados]: {},
        [EstructurasConst.idConsultas]: {},
        [EstructurasConst.idGastos]: {},
        [EstructurasConst.idAbono]: {},
        [EstructurasConst.idPagos]: {},
        [EstructurasConst.idBalance]: {},
        [EstructurasConst.idFacturacion]: {},
    }

    static icons = {
        [EstructurasConst.idDirectorioClientes]: <PermMediaIcon key={0} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idClaseDirectorio]: <PermMediaIcon key={'0.clase'} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idEstructura]: <HomeIcon key={1} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idClaseAccesosDirectos]: <HomeIcon key={'1.clase'} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idRecientes]: <StarIcon key={14} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idDatosCliente]: <PersonIcon key={3} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsVinculados]: <ContactMailIcon key={5} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idCasos]: <GavelIcon key={8} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idPiezasPostales]: <TelegramIcon key={9} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idHonorariosAbono]: <HonorariosAbonoIcon key={25} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idHonorariosPactados]: <HonorariosIcon key={15} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idConsultas]: <AssignmentIcon key={16} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idGastos]: <GastosIcon key={17} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsRecibidos]: <InboxIcon key={18} fontSize={EstructurasOperations.fontSize} />,
        //[EstructurasConst.idMailsEnviados]: <SendIcon key={19} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idAgendaCliente]: <AgendaIcon key={20} fontSize={EstructurasOperations.fontSize} />,
        
        [EstructurasConst.idAbono]: <AbonoIcon key={21} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idPagos]: <PagosIcon key={22} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idBalance]: <BalanceIcon key={23} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idFacturacion]: <FacturacionIcon key={24} fontSize={EstructurasOperations.fontSize} />,
    }

    // -----------------------------------------------------------------------------------------
    static headCellsAdjuntos = [ 
        { id: 'nro', numeric: false, disablePadding: true, label: 'No.' },
        { id: 'texto', numeric: false, disablePadding: true, label: 'Adjuntos' },
    ];
    
    // Mails Recibidos -------------------------------------------------------------------------
    static headCellsRecibidos = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente' }, 
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso Vinculado' }, 
    ];
      
    static headCellsReplyRecibidos = [ 
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Enviados -------------------------------------------------------------------------
    static headCellsEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];
    
    static headCellsReplyEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Vinculados -------------------------------------------------------------------------
    static headCellsVinculados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso Vinculado' }, 
    ];
    
}