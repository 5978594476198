import React from "react";

// Icons para Mensajería
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import PersonIcon from '@material-ui/icons/Person';
import ContactMailIcon from '@material-ui/icons/ContactMail';

// Otros Icons
import GavelIcon from '@material-ui/icons/Gavel';
import PermMediaIcon from "@material-ui/icons/PermMedia";
import HomeIcon from "@material-ui/icons/Home";

import EstructurasOperations from "../../../../shared/components/TabComponents/EstructurasOperations";

export default class EstructurasConst {
    
    // Identifico si corresponde a Contactos o Casos para los componentes que reciben esta clase por parámetro
    static soyContactos = true;

    static idDirectorioContactos = 'Directorio de Contactos';               // 0
    static idEstructura = 'Estructura';                                     // 1
    static idDatosContacto = 'Datos del Contacto';                          // 3
    static idMailsVinculados = 'Mails Contacto';                            // 5
    static idCasos = 'Casos';                                               // 8
    static idMailsRecibidos = 'Mails Recibidos';                            // 18
    static idMailsEnviados = 'Mails Enviados';                              // 19
    
    static idClaseDirectorio = EstructurasConst.idDirectorioContactos;
    static idClaseAccesosDirectos = 'Accesos Directos';
    static idClaseInformacion = 'Información General';
    static idClaseMensajeria = 'Mensajería';


    static clasesExcludedEstructura = [EstructurasConst.idClaseDirectorio, EstructurasConst.idClaseAccesosDirectos];
    static clasesForcedIcon = [EstructurasConst.idClaseDirectorio, EstructurasConst.idClaseAccesosDirectos];

    static idClaseZero = EstructurasConst.idClaseAccesosDirectos;
    static idEstructuraZero = EstructurasConst.idEstructura;
    static idEstructuraHome = EstructurasConst.idEstructura;


    static clasesEstructuraIds = {
        [EstructurasConst.idClaseDirectorio]: [EstructurasConst.idDirectorioContactos],
        [EstructurasConst.idClaseAccesosDirectos]: [EstructurasConst.idEstructura],
        [EstructurasConst.idClaseInformacion]: [EstructurasConst.idDatosContacto, EstructurasConst.idCasos], 
        //[EstructurasConst.idClaseMensajeria]: [EstructurasConst.idMailsRecibidos, EstructurasConst.idMailsVinculados], 
    };
    

    static estructuraLabels = {
        [EstructurasConst.idDirectorioContactos]: 'Directorio de Contactos',
        [EstructurasConst.idEstructura]: 'Estructura',
        [EstructurasConst.idDatosContacto]: 'Datos del Contacto',
        [EstructurasConst.idMailsVinculados]: 'Externos',
        [EstructurasConst.idMailsRecibidos]: 'Internos',
        [EstructurasConst.idCasos]: 'Casos',
    };

    static arrayOfIdClases = Object.keys(EstructurasConst.clasesEstructuraIds);
    static estructuraIds = Object.keys(EstructurasConst.estructuraLabels);

    static datosContacto = [
        {id: 0, name: 'Tipo', type: 'Select', src: 'tipo', items: ['Persona Física', 'Persona Jurídica'], defaultItem: 0},
        {id: 1, name: {'Persona Física': 'Apellido Cliente', 'Persona Jurídica': 'Razón Social'}, type: 'SelectableString', srcSelect: 'tipo', src: 'apellido'},
        {id: 2, name: {'Persona Física': 'Nombre Cliente', 'Persona Jurídica': 'Tipo Societario'}, type: 'SelectableString', srcSelect: 'tipo', src: 'nombre'},
        {id: 3, name: 'Actividad', type: 'Select', src: 'actividad', items: ['Abogado', 'Secretario Juzgado', 'Juez', 'Contador', 'Otro'], defaultItem: 0},
        {id: 4, name: 'Especialidad', type: 'String', src: 'especialidad'},
        {id: 5, name: 'Teléfono', type: 'String', src: 'phone_main'},
        {id: 6, name: 'Email', type: 'String', src: 'email'},
        {id: 7, name: 'Domicilio', type: 'String', src: 'direccion'},
        {id: 8, name: 'Localidad', type: 'String', src: 'localidad'},
        {id: 9, name: 'Código Postal', type: 'String', src: 'cp'},
        {id: 10, name: 'Registro del Contacto', type: 'Date', src: 'fecha_inicio'}, // Si cambia de orden el registro del contacto, cambiar la constante
        {id: 11, name: 'Personas y Otros Datos de Contacto', type: 'Autosize', src: 'telefono'},
    ];

    static getDatosContactoSinRegistroContacto = () => {
        return this.datosContacto.filter(attribute => attribute.src !== 'fecha_inicio')
    }

    static getPersJuridicaLabel = () => {
        return this.datosContacto.filter(attribute => attribute.src === 'actividad')[0].items[1]
    }

    static getPersFisicaLabel = () => {
        return this.datosContacto.filter(attribute => attribute.src === 'actividad')[0].items[0]
    }

        
    static estructuraVacia = {
        [EstructurasConst.idDatosContacto]: 
            EstructurasOperations.getElementsDatos(EstructurasConst.datosContacto),
        /* [EstructurasConst.idMailsVinculados]: {},
        [EstructurasConst.idMailsRecibidos]: {}, */
        [EstructurasConst.idCasos]: {},
    }

    static icons = {
        [EstructurasConst.idDirectorioContactos]: <PermMediaIcon key={0} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idClaseDirectorio]: <PermMediaIcon key={'0.clase'} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idEstructura]: <HomeIcon key={1} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idClaseAccesosDirectos]: <HomeIcon key={'1.clase'} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idDatosContacto]: <PersonIcon key={3} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsVinculados]: <ContactMailIcon key={5} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idCasos]: <GavelIcon key={8} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsRecibidos]: <InboxIcon key={18} fontSize={EstructurasOperations.fontSize} />,
        //[EstructurasConst.idMailsEnviados]: <SendIcon key={19} fontSize={EstructurasOperations.fontSize} />,
    }

    // -----------------------------------------------------------------------------------------
    static headCellsAdjuntos = [ 
        { id: 'nro', numeric: false, disablePadding: true, label: 'No.' },
        { id: 'texto', numeric: false, disablePadding: true, label: 'Adjuntos' },
    ];
    
    // Mails Recibidos -------------------------------------------------------------------------
    static headCellsRecibidos = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente' }, 
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso Vinculado' }, 
    ];
      
    static headCellsReplyRecibidos = [ 
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Enviados -------------------------------------------------------------------------
    static headCellsEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];
    
    static headCellsReplyEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Vinculados -------------------------------------------------------------------------
    static headCellsVinculados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso Vinculado' }, 
    ];
    
}