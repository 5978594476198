import EstructurasConst from "../../clientes/shared/EstructurasConst";
import CriterioFiltrado from "./CriterioFiltrado";

export default class CriterioFiltradoClientes extends CriterioFiltrado{
    constructor(loggedUser){
        super();
        this._estaEntidad = 'Clientes';
        const today = new Date(); 
        const defaultSearchDate = new Date(today.setFullYear(today.getFullYear() - this._timeLapse));

        this._defaultValue = defaultSearchDate;
        this._defaultSelected = this.getAlternativasBusquedaClientes()[2];
        this._defaultCriterio = this._defaultSelected.criterio;
        this._defaultUsers = loggedUser;
        this.setDefaultCriterio();
    }

    
    applyFilterAndOrder = (_clientes) => {
        var filtrados = null;
        if (this._criterio === 'nombre' || this._criterio === 'apellido') {
            return filtrados = this.applyFilterAndOrderByStartsWith(_clientes);
        } else if (this._criterio === 'ultima_modificacion') {
            return filtrados = this.applyFilterAndOrderByRecientes(_clientes, EstructurasConst.idRecientes);
        } else if (this._criterio === 'fecha_inicio') {
            return filtrados = this.applyFilterAndOrderByDate(_clientes);
        } else if (this._criterio === 'ultima_consulta') {
            return filtrados = this.applyFilterAndOrderByConsultas(_clientes, EstructurasConst.idConsultas);
        } else {
            filtrados = _clientes
        }
        return filtrados;
    };

    getAlternativasBusquedaClientes = () => {
        return [
            { group:'Alfabética', label: 'Apellido o Razón Social', criterio: 'apellido'},
            //{ group:'Alfabética', label: 'Nombre', criterio: 'nombre'},
            { group:'Fecha', label: 'Última Modificación', criterio: 'ultima_modificacion'}, // (Mensajes / Agenda / Gastos / Honorarios) MENSAJES QUEDA FUERA ¿?
            { group:'Fecha', label: 'Última Consulta o Registro', criterio: 'ultima_consulta'},
            { group:'Fecha', label: 'Fecha Inicio', criterio: 'fecha_inicio'},
            { group:'Honorarios', label: 'Monto Adeudado', criterio: 'adeudado'},
            { group:'Honorarios', label: 'Honorarios Generados', criterio: 'honorarios'},
        ];
    }
}
