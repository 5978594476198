import React, { useState, useCallback, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  TextField,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import FormDialog from "../../../shared/components/FormDialog";
import HighlightedInformation from "../../../shared/components/HighlightedInformation";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import UsersAPI from "../../../shared/api/UsersAPI";

const styles = (theme) => ({
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
});

function RegisterDialog(props) {
  const { setStatus, theme, onClose, openTermsDialog, status, classes, pushMessageToSnackbar } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [hasTermsOfServiceError, setHasTermsOfServiceError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const registerTermsCheckbox = useRef();
  const registerNombre = useRef();
  const registerApellido = useRef();
  const registerEmail = useRef();
  const registerEstudio = useRef();
  const registerPassword = useRef();
  const registerPasswordRepeat = useRef();
  const activada = 'Hemos creado su cuenta. De click en el link del email que le hemos enviado para activarla.';

  const register = useCallback(() => {
    if (!registerTermsCheckbox.current.checked) {
      setHasTermsOfServiceError(true);
      return;
    }
    if (
      registerPassword.current.value !== registerPasswordRepeat.current.value
    ) {
      setStatus("passwordsDontMatch");
      return;
    }

    const fetchCreateBackEnd = async (nombre, apellido, email, pass, estudio) => {
      return await UsersAPI.getInstance().register(nombre, apellido, email, pass, estudio)
      .then(response => {
        if (response.id){
          setStatus("accountCreated");
          setTimeout(() => {
          }, 1500);
          setTimeout(() => {
            pushMessageToSnackbar({
              text: activada,
            });
            onClose();
            setStatus(null);
          }, 1500);
        } else {
          setTimeout(() => {
            pushMessageToSnackbar({
              text: 'Algo salió mal, verifique el nombre de estudio o contáctenos',
            });
            setStatus("invalidEstudio");
            setIsLoading(false);
          }, 1500);
        }
      })
      .catch(err => {
        pushMessageToSnackbar({text: 'Algo no funcionó: verifique sus datos y el nombre de estudio o contáctenos'});
        setStatus("invalidEstudio");
        setIsLoading(false);
        return ;
      })
    };

    setStatus(null);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    fetchCreateBackEnd(registerNombre.current.value, registerApellido.current.value, registerEmail.current.value, registerPassword.current.value, registerEstudio.current.value);
  }, [
    setIsLoading,
    setStatus,
    setHasTermsOfServiceError,
    registerNombre,
    registerApellido,
    registerEmail,
    registerEstudio,
    registerPassword,
    registerPasswordRepeat,
    registerTermsCheckbox,
    activada,
    onClose,
    pushMessageToSnackbar,
  ]);

    
  return (
    <FormDialog
      loading={isLoading}
      onClose={onClose}
      open
      headline="Registro"
      onFormSubmit={(e) => {
        e.preventDefault();
        register();
      }}
      hideBackdrop
      hasCloseIcon
      content={
        <Fragment>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={status === "invalidNombre"}
            label="Nombre"
            inputRef={registerNombre}
            autoFocus
            autoComplete="off"
            onChange={() => {
              if (status === "invalidNombre") {
                setStatus(null);
              }
            }}
            FormHelperTextProps={{ error: true }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={status === "invalidApellido"}
            label="Apellido"
            inputRef={registerApellido}
            autoComplete="off"
            onChange={() => {
              if (status === "invalidApellido") {
                setStatus(null);
              }
            }}
            FormHelperTextProps={{ error: true }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={status === "invalidEmail"}
            label="Email"
            inputRef={registerEmail}
            autoComplete="off"
            type="email"
            onChange={() => {
              if (status === "invalidEmail") {
                setStatus(null);
              }
            }}
            FormHelperTextProps={{ error: true }}
          />
          <VisibilityPasswordTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={
              status === "passwordTooShort" || status === "passwordsDontMatch"
            }
            label="Password"
            inputRef={registerPassword}
            autoComplete="off"
            onChange={() => {
              if (
                status === "passwordTooShort" ||
                status === "passwordsDontMatch"
              ) {
                setStatus(null);
              }
            }}
            helperText={(() => {
              if (status === "passwordTooShort") {
                return "Generar un password de al menos 6 caracteres.";
              }
              if (status === "passwordsDontMatch") {
                return "Sus passwords no concuerdan.";
              }
              return null;
            })()}
            FormHelperTextProps={{ error: true }}
            isVisible={isPasswordVisible}
            onVisibilityChange={setIsPasswordVisible}
          />
          <VisibilityPasswordTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={
              status === "passwordTooShort" || status === "passwordsDontMatch"
            }
            label="Repetir Password"
            inputRef={registerPasswordRepeat}
            autoComplete="off"
            onChange={() => {
              if (
                status === "passwordTooShort" ||
                status === "passwordsDontMatch"
              ) {
                setStatus(null);
              }
            }}
            helperText={(() => {
              if (status === "passwordTooShort") {
                return "Generar un password de al menos 6 caracteres.";
              }
              if (status === "passwordsDontMatch") {
                return "Sus passwords no concuerdan.";
              }
            })()}
            FormHelperTextProps={{ error: true }}
            isVisible={isPasswordVisible}
            onVisibilityChange={setIsPasswordVisible}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={status === "invalidEstudio"}
            label="Estudio Asociado"
            inputRef={registerEstudio}
            //autoFocus
            autoComplete="off"
            //type="estudio"
            onChange={() => {
              if (status === "invalidEstudio") {
                setStatus(null);
              }
            }}
            FormHelperTextProps={{ error: true }}
          />
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Checkbox
                color="primary"
                inputRef={registerTermsCheckbox}
                onChange={() => {
                  setHasTermsOfServiceError(false);
                }}
              />
            }
            label={
              <Typography variant="body1">
                Estoy de acuerdo con los
                <span
                  className={classes.link}
                  onClick={isLoading ? null : openTermsDialog}
                  tabIndex={0}
                  role="button"
                  onKeyDown={(event) => {
                    // For screenreaders listen to space and enter events
                    if (
                      (!isLoading && event.keyCode === 13) ||
                      event.keyCode === 32
                    ) {
                      openTermsDialog();
                    }
                  }}
                >
                  {" "}
                  términos de servicio
                </span>
              </Typography>
            }
          />
          {hasTermsOfServiceError && (
            <FormHelperText
              error
              style={{
                display: "block",
                marginTop: theme.spacing(-1),
              }}
            >
              Para crear una cuenta debe aceptar nuestros términos de servicio.
            </FormHelperText>
          )}
          {status === "accountCreated" ? (
            <HighlightedInformation>
              {activada}
            </HighlightedInformation>
          ) : status === "emailExists" ? (
            <HighlightedInformation>
              El email ingresado ya está registrado en nuestros sistemas.
            </HighlightedInformation>
          ) : (
            <HighlightedInformation>
              Generar un password de al menos 6 caracteres. No olvide revisar y 
              aceptar los términos de servicio.
            </HighlightedInformation>
          )}
        </Fragment>
      }
      actions={
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
          disabled={isLoading}
        >
          Registro
          {isLoading && <ButtonCircularProgress />}
        </Button>
      }
    />
  );
}

RegisterDialog.propTypes = {
  theme: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  openTermsDialog: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  pushMessageToSnackbar: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(RegisterDialog);
