import axios from 'axios';

import { serializeDateAsJsonUTC, deserializeDateStrLocalGmt } from '../utils/DateUtils'
import { isDefined } from '../utils/Utils';

import { 
    endPointMensajeria, 
    endPointMessagesLink, 
    endPointMessagesRead,
    endPointForceExternalMessagesRead, 
    endPointForceExternalMessagesReadStatus,
    host,
} from './APIConsts';

import Session from '../../logged_in/session/Session';
import EstructurasConst from '../../logged_in/components/mensajeria/shared/EstructurasConst';
import FileManagerAPI from './FileManagerAPI';

export default class MensajeriaAPI {
    static _instance = null;

    constructor() {
        if (MensajeriaAPI._instance !== null)
            throw new Error('Falla de instanciación: MensajeriaAPI es singleton. Utilizar MensajeriaAPI.getInstance()');
        else
            MensajeriaAPI._instance = this;
    }

    static getInstance = () => {
        if (MensajeriaAPI._instance === null)
            MensajeriaAPI._instance = new MensajeriaAPI();
        return MensajeriaAPI._instance;
    };

    session = Session.getInstance();

    getMailsCountCasesClients = (msgs) => {
        const msgsUnread = msgs.filter(msg => !msg.isRead && msg.recipient === this.session.getLoggedUser().id);
        var casos = new Set();
        var casosCount = {};
        var clientes = new Set();
        var clientesCount = {};
        msgsUnread.map(msg => {
            if (casos.has(msg.caso)) {
                casosCount[msg.caso]++;
            } else {
                casos.add(msg.caso);
                casosCount[msg.caso] = 1;
            }
            
            if (clientes.has(msg.cliente)) {
                clientesCount[msg.cliente]++;
            } else {
                clientes.add(msg.cliente);
                clientesCount[msg.cliente] = 1;
            }
        });

        return {casos: casos, casosCount, casosCount, clientes: clientes, clientesCount: clientesCount};
    };


    getMensajesCliente = async (estudio_id, cliente_id) => {
        await this.getMensajesUser(); // Actualiza Session primero
        return await axios.get(`${host}/${endPointMensajeria}?estudio_id=${estudio_id}&cliente_id=${cliente_id}`)
        .then((msgs)=>this._deserializeMessagesPresenter(msgs, false))
        .catch (err => {
            throw err;
        });
    }


    getMensajesUser = async () => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const user_id = this.session.getLoggedUser().id;
        //const user_id = this.session.getLoggedUser().email; // ---------------------------------------------------------- Corregir cuando fixed en BE ----------
        return await axios.get(`${host}/${endPointMensajeria}?estudio_id=${estudio_id}&user_id=${user_id}`)
        .then((msgs) => {
            const formattedMails = this._deserializeMessagesPresenter(msgs, true);
            this.session.setMailsCountCasesClients(this.getMailsCountCasesClients(msgs));
            this.session.setMails(formattedMails)
        })
        .catch (err => {
            throw err;
        });
    }


    getMensajesCaso = async (estudio_id, caso_id) => {
        await this.getMensajesUser(); // Actualiza Session primero
        return await axios.get(`${host}/${endPointMensajeria}?estudio_id=${estudio_id}&caso_id=${caso_id}`)
        .then((msgs)=>this._deserializeMessagesPresenter(msgs, false))
        .catch (err => {
            throw err;
        });
    }


    forceExternalMessagesRead = async () => {
        return await axios.post(`${host}/${endPointForceExternalMessagesRead}?`)
        .then((data) => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    };

    
    isStatusForceExternalMessagesReadOK = async (job_id) => {
        return await axios.get(`${host}/${endPointForceExternalMessagesReadStatus}?job_id=${job_id}`)
        .then((data) => { 
            // Los estados son "started", "finished" o "failed". 
            // Si viene {"message":"Job not found"} lo filtra _processResponse.
            // Si viene "failed" lo filtramos acá
            if (data === 'failed') throw new Error("Error en chequeo de status del job_process");
            return data === "finished";
        })
        .catch (err => {
            throw err;
        });
    };

    
    createMensaje = async (asunto, recipient, text, optionals) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const sender = this.session.getLoggedUser().id;
        
        const msg_json = await this._serializeMessageBackEnd(estudio_id, asunto, sender, recipient, text, optionals);
                
        return await axios.post(`${host}/${endPointMensajeria}?estudio_id=${estudio_id}`, msg_json)
        .then((msg_id) => { // Updateamos los mails en Session -> DataSession
            const refreshMails = (listaDestino, formattedMails, msg_id, count) => {
                const newId = (listaDestino.data !== undefined) && (listaDestino.data.headersMails !== undefined) ? listaDestino.data.headersMails.length : 0;
                
                const newMailHeader = formattedMails.data.headersMails[0];
                const newMailContent = formattedMails.data.contentsMails[0];

                newMailHeader.i = newId;
                newMailHeader.id = newId;
                newMailHeader.msg_id = msg_id;

                newMailHeader.leido = newMailHeader.recipient === this.session.getLoggedUser().id? false: true; 
                // Marcamos el nuevo como no leido cuando me lo envié a mí mismo. 
                // Esto es únicamente la vista para el usuario registrado, es diferente la consideración para el usuario destinatario (para él, el mensaje estará no-leído)
                if (newMailHeader.recipient === this.session.getLoggedUser().id){
                    if (newMailHeader.caso) {
                        this.session.updateCasosCountUnreadMails(newMailHeader.caso, count);
                    }
                    if (newMailHeader.cliente) {
                        this.session.updateClientesCountUnreadMails(newMailHeader.cliente, count);
                    }
                };

                if (newId === 0) {
                    listaDestino['data'] = {
                        'headersMails': [newMailHeader], 
                        'contentsMails': [newMailContent],
                        'unReadMails': newMailHeader.recipient === this.session.getLoggedUser().id? 1: 0 // si me lo envío yo mismo queda como no leido
                    };
                } else {
                    listaDestino['data']['headersMails'].push(newMailHeader);
                    listaDestino['data']['contentsMails'].push(newMailContent);
                    if (newMailHeader.recipient === this.session.getLoggedUser().id) {
                        listaDestino['data']['unReadMails']++;
                    }
                }
                return {'header': JSON.parse(JSON.stringify(newMailHeader)), 'content': JSON.parse(JSON.stringify(newMailContent))};
            }

            const formattedMails = {};
            this._deserializeMessage(msg_json, formattedMails);

            var listaDestino = {};
            if (optionals.replyMessageId === undefined || optionals.replyMessageId === '') { // Es un mail nuevo
                if (recipient === this.session.getLoggedUser().id) { // El user se lo autoenvió, actualizo recibidos también
                    listaDestino = this.session.getMails()[EstructurasConst.idMailsRecibidos];
                    
                    // Hago copia de formattedMails para que no lo pise luego en la segunda llamada previo al return
                    const formattedMailsCopy = JSON.parse(JSON.stringify(formattedMails));
                    
                    // Reformateamos la fecha según requiere el FE luego de realizar la copia
                    const newId = formattedMailsCopy.data.headersMails.length-1;
                    formattedMailsCopy['data']['headersMails'][newId]['fecha'] = deserializeDateStrLocalGmt(formattedMailsCopy['data']['headersMails'][newId]['fecha']);

                    // Evito sumar Caso o Cliente unread pq lo sumará luego previo al return
                    refreshMails(listaDestino, formattedMailsCopy, msg_id.id, 0);
                }
                listaDestino = this.session.getMails()[EstructurasConst.idMailsEnviados];
            } else { // Es un reply
                if (recipient === this.session.getLoggedUser().id) { 
                    // Como es un reply tengo q actualizar los unreads del general aparte
                    this.session.getMails()[EstructurasConst.idMailsRecibidos]['data']['unReadMails']++;
                };
                listaDestino = this.session.getMails().replies[optionals.replyMessageId];
                if (listaDestino===undefined) {
                    this.session.getMails().replies[optionals.replyMessageId] = {};
                    listaDestino = this.session.getMails().replies[optionals.replyMessageId];
                }
            }

            return refreshMails(listaDestino, formattedMails, msg_id.id, 1);
        })
        .catch (err => {
            throw err;
        });
    }

    markMessagesRead = async (message_ids, read, replyMessageId) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const msg_json = {
            "message_ids": message_ids,
            "read": read
        };
        return await axios.put(`${host}/${endPointMessagesRead}?estudio_id=${estudio_id}`, msg_json)
        .then((msg_id) => {
            const updateUnReads = (listaDestino) => {
                listaDestino['data']['unReadMails'] = 
                read? 
                    listaDestino['data']['unReadMails'] - message_ids.length
                : 
                    listaDestino['data']['unReadMails'] + message_ids.length;
            }
            
            msg_id.updated_ids.map(updated_id => {
                var mailList = null;
                if (replyMessageId !== undefined && replyMessageId !== null && replyMessageId !== '') { // Es un reply
                    mailList = this.session.getMails().replies[replyMessageId];
                } else { // Es un root, verificamos con Recibidos
                    mailList = this.session.getMails()[EstructurasConst.idMailsRecibidos];
                }

                var thisMail = mailList['data']['headersMails'].filter(mail => mail.msg_id === updated_id)[0];

                if (thisMail) { // Es un interno, hay que verificar si está vinculado a caso o cliente
                    const count = read? -1 : +1;
                    if (thisMail.caso) {
                        this.session.updateCasosCountUnreadMails(thisMail.caso, count);
                    };
    
                    if (thisMail.cliente) {
                        this.session.updateClientesCountUnreadMails(thisMail.cliente, count);
                    };
                } else { // Es un desvinculado externo
                    mailList = this.session.getMails()[EstructurasConst.idMailsDesvinculados];
                    thisMail = mailList['data']['headersMails'].filter(mail => mail.msg_id === updated_id)[0];
                }
                
                thisMail.leido = read;
            });

            updateUnReads(this.session.getMails()[EstructurasConst.idMailsRecibidos]); // Update unreads recibidos general del usuario
            if (replyMessageId !== undefined && replyMessageId !== null && replyMessageId !== '') { 
                updateUnReads(this.session.getMails().replies[replyMessageId]); // Update unreads de la cadena de replies del root msg
            }
        })
        .catch (err => {
            throw err;
        });
    }
    
    _serializeMessageBackEnd = async (estudio_id, asunto, sender, recipient, text, optionals) => {
        /* ******************************************** *
            En optionals esperamos:
                adjuntos: Array de names,
                links: Array de URIs,
                caso: casoId,
                cliente: clienteId,
                replyMessageId: messageId 
         * ********************************************* */

        const msg_json = {
            "subject": asunto,
            "sender": sender,
            "recipient": recipient,
            "text": text,
            "datetime": serializeDateAsJsonUTC(new Date()),
            //"isRead": false,
        };
        
        if (optionals.caso !== undefined && optionals.caso !== "") {
            msg_json["caso"] = optionals.caso;
        }

        if (optionals.cliente !== undefined && optionals.cliente !== "") {
            msg_json["cliente"] = optionals.cliente;
        }

        if (optionals.replyMessageId !== undefined && optionals.replyMessageId !== "") {
            msg_json["replyMessageId"] = optionals.replyMessageId;
        }

        
        msg_json["attachments"] = await FileManagerAPI.getInstance().attachmentsPersistence(optionals.adjuntos, optionals.links, estudio_id, sender, "Mensajeria", null, null);

        return msg_json;
    }
    
    _updateVinculadosDesvinculadosPorCambioLinks = (idListaPrevia, idListaNueva, message_ids, caso_id, cliente_id) => {
        const mails = this.session.getMails();

        const _splitListaPrevia = () => {
            const contentsNuevosListaNueva = [];
            const contentsRestantesListaPrevia = [];
            const headersNuevosListaNueva = [];
            const headersRestantesListaPrevia = [];

            if (mails[idListaPrevia]['data'] === undefined) {
                mails[idListaPrevia]['data'] = {'contentsMails': [], 'headersMails': [], 'unReadMails': 0};
            }
            
            mails[idListaPrevia]['data']['headersMails'].map((header, index) => {
                var listaHeadersDestino = [];
                var listaContentsDestino = [];
                if (message_ids.includes(header.msg_id)) {
                    listaHeadersDestino = headersNuevosListaNueva;
                    listaContentsDestino = contentsNuevosListaNueva;
                } else {
                    listaHeadersDestino = headersRestantesListaPrevia;
                    listaContentsDestino = contentsRestantesListaPrevia;
                }
                listaHeadersDestino.push(header);
                listaContentsDestino.push(mails[idListaPrevia]['data']['contentsMails'][index]);
            });

            return {
                contentsNuevosListaNueva: contentsNuevosListaNueva,
                contentsRestantesListaPrevia: contentsRestantesListaPrevia,
                headersNuevosListaNueva: headersNuevosListaNueva,
                headersRestantesListaPrevia: headersRestantesListaPrevia
            }
        }
        
        const splittedListPrevia = _splitListaPrevia();

        if (splittedListPrevia.headersRestantesListaPrevia.length === 0) { // Si no quedó nada en la lista previa borro la lista
            delete mails[idListaPrevia].data;
        } else { // Si quedan en lista previa, actualizo los ids del FE para la CustomTable
            mails[idListaPrevia]['data']['headersMails'] = splittedListPrevia.headersRestantesListaPrevia.map((item, index) => Object.assign({}, item, {i: index, id: index})); 
            mails[idListaPrevia]['data']['contentsMails'] = splittedListPrevia.contentsRestantesListaPrevia;
        }

        if (mails[idListaNueva]['data'] === undefined) {
            mails[idListaNueva]['data'] = {'contentsMails': [], 'headersMails': [], 'unReadMails': 0};
        } else if (typeof cliente_id !=='boolean') { 
            // La lista nueva no es vacía. Revisamos si hay hit y controlamos si es una revinculación
            mails[idListaNueva]['data']['headersMails'].map((header, index) => {
                if (message_ids.includes(header.msg_id)) {
                    // Es un hit reviso si ya había links entonces hay que actualizarlos
                    if (header.cliente !== null) { // Estoy en vinculados y quiero revincular
                        header.cliente = cliente_id;
                        header.caso = caso_id;
                        
                    }
                }
            });
        }

        var newId = Object.keys(mails[idListaNueva]['data']['headersMails']).length;
        
        // Actualizo los ids para la CustomTable en la lista nueva
        mails[idListaNueva]['data']['headersMails'] = mails[idListaNueva]['data']['headersMails'].concat(
            splittedListPrevia.headersNuevosListaNueva.map((item, index) => Object.assign({}, item, {i: newId+index, id: newId+index, 'caso': caso_id, 'cliente': cliente_id}))
        );
        mails[idListaNueva]['data']['contentsMails'] = mails[idListaNueva]['data']['contentsMails'].concat(splittedListPrevia.contentsNuevosListaNueva);
    };

    setMensajeLinks = async (message_ids, caso_id, cliente_id, isInterno) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const msg_json = this._serializeMsgLinks(message_ids, caso_id, cliente_id);
        return await axios.post(`${host}/${endPointMessagesLink}?estudio_id=${estudio_id}`, msg_json)
        .then((resp)=> {
            if (isInterno) {
                const mails = this.session.getMails();
                const idRecibidos = EstructurasConst.idMailsRecibidos;
                const idEnviados = EstructurasConst.idMailsEnviados;
                const idReplies = 'replies';
                message_ids.map(msg_id => {
                    var found = false;
                    [idRecibidos, idEnviados].map(idLista => {
                        const msg = mails[idLista].data.headersMails.filter(msg => msg.msg_id === msg_id)[0];
                        if (msg) {
                            found = true;
                            msg.caso = caso_id;
                            msg.cliente = cliente_id;
                        };
                    });
                    //if (!found) { // el msg_id corresponde a un reply, no hace falta tocar }
                });
            } else {
                const idListaNueva = EstructurasConst.idMailsVinculados;
                const idListaPrevia = EstructurasConst.idMailsDesvinculados;
                this._updateVinculadosDesvinculadosPorCambioLinks(idListaPrevia, idListaNueva, message_ids, caso_id, cliente_id);
            }
        })
        .catch (err => {
            throw err;
        });
    }

    setMensajeUnlink = async (message_ids, caso_id, cliente_id, isInterno) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const msg_json = this._serializeMsgLinks(message_ids, caso_id, cliente_id);
        return await axios.delete(`${host}/${endPointMessagesLink}?estudio_id=${estudio_id}`, msg_json)
        .then((resp)=> {
            if (isInterno) {
                const mails = this.session.getMails();
                const idRecibidos = EstructurasConst.idMailsRecibidos;
                const idEnviados = EstructurasConst.idMailsEnviados;
                const idReplies = 'replies';
                message_ids.map(msg_id => {
                    var found = false;
                    [idRecibidos, idEnviados].map(idLista => {
                        const msg = mails[idLista].data.headersMails.filter(msg => msg.msg_id === msg_id)[0];
                        if (msg) {
                            found = true;
                            if (caso_id) msg.caso = null;
                            if (cliente_id) msg.cliente = null;
                        };
                    });
                    //if (!found) { // el msg_id corresponde a un reply, no hace falta tocar }
                });
            } else {
                const idListaNueva = EstructurasConst.idMailsDesvinculados;
                const idListaPrevia = EstructurasConst.idMailsVinculados;
                this._updateVinculadosDesvinculadosPorCambioLinks(idListaPrevia, idListaNueva, message_ids, caso_id, cliente_id);
            }
        })
        .catch (err => {
            throw err;
        });
    };

    _deserializeMessage = (msg, msgesJson) => {
        if (msgesJson['data']===undefined) {
            msgesJson['data'] = {'contentsMails': [], 'headersMails': [], 'unReadMails': 0};
        }

        if (msg.recipient === this.session.getLoggedUser().id && !msg.isRead){
            msgesJson['data']['unReadMails']++;
        };

        var count = Object.keys(msgesJson['data']['headersMails']).length;

        msgesJson['data']['headersMails'][count] = {
            'caso': null,
            'cliente': null,
            'asunto': msg.subject,
            'fecha': deserializeDateStrLocalGmt(msg.datetime),
            'sender': msg.sender,
            'recipient': msg.recipient,
            'i': count,
            'id': count,
            'msg_id': msg.id,
            'leido': msg.recipient !== this.session.getLoggedUser().id || msg.isRead,
            'attachs': msg.attachments !== undefined && msg.attachments.length > 0
        };
        if (msg.caso!==undefined) {
            msgesJson['data']['headersMails'][count]['caso'] = msg.caso;
        }
        if (msg.cliente!==undefined) {
            msgesJson['data']['headersMails'][count]['cliente'] = msg.cliente;
        }
        msgesJson['data']['contentsMails'][count] = {
            'links': [],
            'tablaAdjuntos': [],
            'texto': msg.text
        };
        if (msg.attachments !== undefined) {
            var attId = 0;
            msg.attachments.map(async (att) => {
                msgesJson['data']['contentsMails'][count]['links'].push({
                    'nro': attId, 
                    'link': att.link
                });
                msgesJson['data']['contentsMails'][count]['tablaAdjuntos'].push({
                    'nro': attId, 
                    'texto': att.filename
                });
                attId++;
            });
        }
    }

    sessionUsers = this.session.getUsers();
    
    _workaroundSenderRecipientEmails2IDsOldMessage = (msg) => {
        const isEMail = (id) => {
            return (typeof id === 'string' && id.indexOf('@') !== -1)
        };

        if (isEMail(msg.sender)) {
            msg.sender = this.sessionUsers[msg.sender].id;
        };

        if (isEMail(msg.recipient)) {
            msg.recipient = this.sessionUsers[msg.recipient].id;
        };
    };

    _deserializeMessagesPresenter = (msgs, isGeneralUser) => {
        const _isRootIn = (root, msgesJson) => { // el root es un mail backEnd
            if (root.in) {
                return true;
            };
            root['in'] = true; // Lo marcamos por anticipado porque va a ser incorporado a la vuelta del return o bien ya está por lo que la marca sirve para futuros chequeos
            if (msgesJson['data']===undefined) {
                return false;
            };
            
            const resp = msgesJson.data.headersMails.find(m => m.msg_id === root.id);
            return !(resp === undefined);
        }


        const idRecibidos = EstructurasConst.idMailsRecibidos;
        const idEnviados = EstructurasConst.idMailsEnviados;
        const idVinculados = EstructurasConst.idMailsVinculados;
        const idDesvinculados = EstructurasConst.idMailsDesvinculados;

        var msgesJsonOut = {[idEnviados]: {}, [idRecibidos]: {}, [idVinculados]: {}, [idDesvinculados]: {}, 'replies': {}};
        if (Object.keys(msgs).length>0){
            Object.values(msgs).reverse().map((msg) => { 
                // Hago el reversed para que el mensaje más reciente tenga el último id, esto simplifica 
                // el agregado de mensajes en el FE porque evita que tengamos que renombrar todos los ids
                // del presenter.

                this._workaroundSenderRecipientEmails2IDsOldMessage(msg);

                var msgesJson = {};
                
                if (msg.sender==="" || msg.sender===null) { // Externo
                    if (msg.cliente===null) { // Desvinculado
                    //if (msg.caso===undefined && msg.cliente===undefined) { // Desvinculado
                        msgesJson = msgesJsonOut[idDesvinculados]
                    } else { // Vinculado
                        msgesJson = msgesJsonOut[idVinculados]
                    }
                    this._deserializeMessage(msg, msgesJson);
                } else 
                { // Interno
                    
                    // Si es reply va para el replies solamente y además:
                        // Si el root es enviado y el reply recibido => copiar el root a recibidos también (el root aparecerá en enviados y recibidos)
                            // Así veremos los replies recibidos en recibidos
                        // Si el root es recibido y el reply enviado => copiar el root a enviados también (el root aparecerá en enviados y recibidos)
                            // Así veremos los replies enviados en enviados
                    // Si es un root puede ser enviado o recibido, o ambos => sumar en cada uno
                        
                    if (isDefined(msg.replyMessageId) && msg.replyMessageId !== ''){ // Es un reply
                        if (msgesJsonOut.replies[msg.replyMessageId] === undefined) {
                            msgesJsonOut.replies[msg.replyMessageId] = {};
                        }
                        msgesJson = msgesJsonOut.replies[msg.replyMessageId];
                        this._deserializeMessage(msg, msgesJson);
                        
                        if (!msg.isRead && msg.recipient === this.session.getLoggedUser().id){ 
                            // el reply es recibido y está marcado como no-leído por lo que debo sumarlo a los unreads de Recibidos
                            if (msgesJsonOut[idRecibidos]['data']===undefined){
                                msgesJsonOut[idRecibidos] = {'data': {'contentsMails': [], 'headersMails': [], 'unReadMails': 1}};
                            } else {
                                msgesJsonOut[idRecibidos]['data']['unReadMails']++;
                            }
                        };

                        var root = msgs.find(mBack => mBack.id === msg.replyMessageId);
                        if (!isGeneralUser || msg.recipient === this.session.getLoggedUser().id) { // El reply es recibido => chequear su root
                            if (!isGeneralUser || root.sender === this.session.getLoggedUser().id){ // El root es enviado
                                msgesJson = msgesJsonOut[idRecibidos]; // Chequear si el root está en recibidos
                                !_isRootIn(root, msgesJson) && this._deserializeMessage(root, msgesJson); // El root aún no está en recibidos => sumarlo
                            };
                        };
                        if (msg.sender === this.session.getLoggedUser().id) { // El reply es enviado => chequear el root
                            if (root){ // La excepción surge cdo se contesta un mail en casos q no fue dirigido al loggedUser (verificar si deberíamos incluirlo en enviados general)
                            /* if (root.recipient === this.session.getLoggedUser().id){ // El root es recibido */
                                msgesJson = msgesJsonOut[idEnviados]; // Chequear si el root está en enviados
                                !_isRootIn(root, msgesJson) && this._deserializeMessage(root, msgesJson); // El root aún no está en enviados => sumarlo
                            /* } */
                            }
                        }
                    } else { // Es un root
                        if (msg.sender === this.session.getLoggedUser().id) { // Enviado
                            msgesJson = msgesJsonOut[idEnviados];
                            this._deserializeMessage(msg, msgesJson);
                        };
                        if (!isGeneralUser || msg.recipient === this.session.getLoggedUser().id) { // Recibido
                            msgesJson = msgesJsonOut[idRecibidos];
                            this._deserializeMessage(msg, msgesJson);
                        }
                    }
                }
            });
        }
        return msgesJsonOut;
    }

    _serializeMsgLinks = (message_ids, caso_id, cliente_id) => {
        const jsonOut = {'message_ids': message_ids};
        if (caso_id!==undefined && caso_id!==null){
            jsonOut['caso_id'] = caso_id;
        };
        if (cliente_id!==undefined && cliente_id!==null){
            jsonOut['cliente_id'] = cliente_id;
        };
        return jsonOut;
    }
}
