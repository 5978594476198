
export default class EstructurasOperations {

    static getIdClaseFromIdEstructura(Constants, idEstructura){
        var idClaseOut = null;
        Constants.arrayOfIdClases.some((idClase) => {
            if (Constants.clasesEstructuraIds[idClase].includes(idEstructura)){
                idClaseOut = idClase;
                return;
            }
        });
        return idClaseOut;
    }

    static getIdClaseFromValue(Constants, value){
        return Constants.arrayOfIdClases[value];
    }

    static getValueFromIdClase(Constants, id){
        return Constants.arrayOfIdClases.indexOf(id);
    }

    static getIdEstructuraZero(Constants, idClase){
        return Constants.clasesEstructuraIds[idClase][0];
    }

    static getNextEstructuraId(Constants, idClase, idEstructura){
        const arrayClase = Constants.clasesEstructuraIds[idClase];
        const indexEstructura = arrayClase.indexOf(idEstructura);
        const nextIndexEstructura = arrayClase.length === indexEstructura+1? 0: indexEstructura+1;
        return arrayClase[nextIndexEstructura];
    }

    static getPreviousEstructuraId(Constants, idClase, idEstructura){
        const arrayClase = Constants.clasesEstructuraIds[idClase];
        const indexEstructura = arrayClase.indexOf(idEstructura);
        const previousIndexEstructura = indexEstructura === 0? arrayClase.length-1: indexEstructura-1;
        return arrayClase[previousIndexEstructura];
    }

    static getIdEstructuraFromValue(Constants, value){
        return Constants.estructuraIds[value];
    }

    static getValueFromIdEstructura(Constants, id){
        return Constants.estructuraIds.indexOf(id);
    }

    static getIndexOfIdEstructuraFromClase(Constants, idClase, idEstructura){
        return Constants.clasesEstructuraIds[idClase].indexOf(idEstructura);
    }

    static getPrimerClase = (Constants) => {
        const ids = Object.keys(Constants.clasesEstructuraIds);
        var out = null;
        ids.some((id) => {
            out = id;
            if (!Constants.clasesExcludedEstructura.includes(id)) return id;
        });
        return out;
    }

    static getElementsDatos(data){
        const out = {}
        data.forEach(element => {
            out[element.src] = element.type === 'Date' ? new Date() : ""
        })
        return out;
    }

    static fontSize = "small"
    
}