import axios from 'axios';
import Session from '../../logged_in/session/Session';
import {
    endPointRegister, endPointSignIn, endPointSignInAuth0, 
    endPointUsers, endpointUpdateUser, endpointUpdateUserDetails, endpointUpdateEstudioDetails, 
    endpointRoles, endpointResetPass, 
    host, hostHeroku, USE_AUTH0, accessTokenStoreId, 
    refreshTokenStoreId, pendingBrowserAction, recoveryLoggedUser, 
    endpointNewEstudio, endPointQuasarUsers, backOfficeEmail
} from './APIConsts';
import { isDefined } from '../utils/Utils';

export default class UsersAPI {
    static _instance = null;

    constructor() {
        if (UsersAPI._instance !== null)
            throw new Error('Falla de instanciación: LoggedUserAPI es singleton. Utilizar LoggedUserAPI.getInstance()');
        else
            UsersAPI._instance = this;
    }

    static getInstance = () => {
        if (UsersAPI._instance === null)
            UsersAPI._instance = new UsersAPI();
        return UsersAPI._instance;
    };


    session = Session.getInstance();

    newEstudio = async (nombre, estudio_name) => {
        const body = {
            nombre: nombre,
            estudio_name: estudio_name
        };
        return await axios.post(`${host}/${endpointNewEstudio}`, body)
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err;
        });
    }

    register = async (nombre, apellido, email, pass, estudio) => { //Payload:  firstname, lastname, email, password, estudio_name 
        const credentials = { 'firstname': nombre, 'lastname': apellido, 'email': email, 'password': pass, 'estudio_name': estudio };
        return await fetch(`${host}/${endPointRegister}?`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        .then(this._processResponse)
        .then(response => {
                return response;
        })
        .catch((err) => {
            throw err;
        })
    }

    signIn = async (credentials) => {
        if (USE_AUTH0) {
            return this.signInAuth0(credentials);
        }

        return await fetch(`${host}/${endPointSignIn}?`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        }).then(this._processResponse)
            .then(response => {
                this.session.setLoggedUser(response);
                return response;
            });
    }

    signInAuth0 = async (credentials) => {
        this.unsetAuthorization();
        return axios.post(`${host}/${endPointSignInAuth0}`, credentials)
            .then(response => {
                this.setAuthorization(response['auth_data']);
                this.session.setLoggedUser(response.user);
                return response.user;
            })
    }

    setAuthorization = authData => {
        const refreshToken = authData['refresh_token'];
        if (isDefined(refreshToken)) {
            localStorage.setItem(refreshTokenStoreId, refreshToken);
        }

        const accessToken = authData['access_token'];
        if (isDefined(accessToken)) {
            localStorage.setItem(accessTokenStoreId, accessToken);
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    unsetAuthorization = () => {
        localStorage.removeItem(recoveryLoggedUser);
        localStorage.removeItem(pendingBrowserAction);
        localStorage.removeItem(refreshTokenStoreId);
        localStorage.removeItem(accessTokenStoreId);
        delete axios.defaults.headers.common['Authorization'];
    }

    getQuasarUsersAuth0 = async () => {
        return await axios.get(`${host}/${endPointQuasarUsers}`)
        .then(quasaries => {
            return quasaries;
        });
    }

    isBackOfficeON = () => {
        return (Session.getInstance().getLoggedUser().email === backOfficeEmail);
    }

    getAssociatedUsers = async () => {
        if (USE_AUTH0) {
            return this.getAssociatedUsersAuth0();
        }
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const response = await fetch(`${host}/${endPointUsers}?estudio_id=${estudio_id}`);
        const users = await response.json();
        this.session.setUsers(users);
    }

    getAssociatedUsersAuth0 = async () => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        return await axios.get(`${host}/${endPointUsers}?estudio_id=${estudio_id}`)
        .then(users => {
            this.session.setUsers(users);
            return users;
        });
    }

    updateUser = async (body) => {
        if (USE_AUTH0) {
            return this.updateUserAuth0(body);
        }
        if (!body['userId']) {
            throw new Error('Campo userId obligatorio');
        }

        const estudioId = this.session.getLoggedUser().estudio_id;
        return fetch(`${host}/${endpointUpdateUser}?estudio_id=${estudioId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(async response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(error => {
                    throw new Error(error.message);
                });
            }
        });
    }

    updateUserAuth0 = async (body) => {
        if (!body['userId']) {
            throw new Error('Campo userId obligatorio');
        }

        const estudioId = this.session.getLoggedUser().estudio_id;
        return await axios.put(`${host}/${endpointUpdateUser}?estudio_id=${estudioId}`, body)
        .then(response => {
            return response;
        });
    }

    updateUserDetails = async (body) => {
        const estudioId = this.session.getLoggedUser().estudio_id;
        const userId = this.session.getLoggedUser().id;
        this._processBodyUpdate(body);
        return await axios.put(`${host}/${endpointUpdateUserDetails}?estudio_id=${estudioId}&user_id=${userId}`, body)
        .then(response => {
            this.session.setLoggedUser(response.contents);
            localStorage.setItem(recoveryLoggedUser, JSON.stringify(Session.getInstance().getLoggedUser()));
            return response;
        });
    }

    _processBodyUpdate = (body) => {
        Object.keys(body).map(key => {
            if (!(!!body[key])) {
                delete body[key];
            } else if (key === 'cp') {
                body['código postal'] = body['cp'];
                delete body[key];
            } else if (key === 'domicilio') {
                body['dirección'] = body['domicilio'];
                delete body[key];
            } else if (key === 'estudio') { // si tengo este key entonces modifica por estudioDetails
                body['nombre'] = body['estudio'];
                delete body[key];
            }
        })
    }

    updateEstudioDetails = async (body) => {
        const estudioId = this.session.getLoggedUser().estudio_id;
        this._processBodyUpdate(body);
        return await axios.put(`${host}/${endpointUpdateEstudioDetails}?estudio_id=${estudioId}`, body)
        .then(response => {
            response.contents.estudio = response.contents.nombre;
            delete response.contents.nombre;
            console.log("........................................... ", response);
            this.session.setLoggedUser(response.contents);
            localStorage.setItem(recoveryLoggedUser, JSON.stringify(Session.getInstance().getLoggedUser()));
            return response;
        });
    }

    getRoles = async () => await axios.get(`${host}/${endpointRoles}`);

    resetPassUserAuth0 = async () => {
        const loggedUser = this.session.getLoggedUser();
        const body = {
            email: loggedUser.email // Si viene el email por parámetro es un user no loggeado que olvidó el pass
        };
        return await axios.post(`${host}/${endpointResetPass}`, body)
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err;
        });
    }

    resetForgottenPassUserAuth0 = async (email) => {
        const body = {
            email: email
        };
        return await axios.post(`${host}/${endpointResetPass}`, body)
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err;
        });
    }

    
    _processResponse = (response) => {
        if (response.ok)
            return response.json();
        else
            return response.json().then(error => {
                throw new Error(error.message);
            });
    }
}
