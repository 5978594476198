import React from "react";

// Icons para Mensajería
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import PersonIcon from '@material-ui/icons/Person';
import ContactMailIcon from '@material-ui/icons/ContactMail';

// Otros Icons
import TareasIconCHISTE from '@material-ui/icons/SportsKabaddi';
import TareasIcon from '@material-ui/icons/PlaylistAdd';
import AgendaIcon from '@material-ui/icons/EventAvailable';
import PermMediaIcon from "@material-ui/icons/PermMedia";
import HomeIcon from "@material-ui/icons/Home";
import DescriptionIcon from "@material-ui/icons/Description";
import RegulacionesIcon from "@material-ui/icons/LibraryBooks";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import CallMadeIcon from "@material-ui/icons/CallMade";
import CallReceivedIcon from "@material-ui/icons/CallReceived";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import MonetizationIcon from '@material-ui/icons/MonetizationOn';
import EmailIcon from '@material-ui/icons/Email';
import MEVIcon from '@material-ui/icons/CastConnected';
import StarIcon from '@material-ui/icons/Star';
import TelegramIcon from '@material-ui/icons/Telegram';
import GastosIcon from '@material-ui/icons/Money';
import PagosIconALT from '@material-ui/icons/LocalAtm';
import FacturacionIcon from '@material-ui/icons/Receipt';
import HonorariosIcon from '@material-ui/icons/Work';
import PagosIcon from '@material-ui/icons/Payment';
import BalanceIcon from '@material-ui/icons/AccountBalanceWallet';
import EscritosIcon from '@mui/icons-material/DriveFileRenameOutline';
/* import EscritosIcon from '@material-ui/icons/Create';
import EscritosIcon from '@mui/icons-material/Psychology'; */

import EstructurasOperations from "../../../../shared/components/TabComponents/EstructurasOperations";
import EstructurasConstClientes from "../../clientes/shared/EstructurasConst";

export default class EstructurasConst {

    // Identifico si corresponde a Clientes o Casos para los componentes que reciben esta clase por parámetro
    static soyCasos;

    // Estos son los id de las collections correspondientes en firebase
    static idDirectorioCasos = 'Directorio de Casos';                             // 0
    static idEstructuraCasos = 'Estructura del Caso';                             // 1
    static idExpedienteMEV = 'Expediente MEV';                                    // 2
    static idDatosCliente = 'Datos del Cliente';                                  // 3
    static idDocumentalCliente = 'Documentación aportada por el Cliente';         // 4
    /* static idMailsVinculados = 'Vinculados';                                      // 5 */
    static idMailsVinculados = 'Interacción con el Cliente';                      // 5
    static idRegulacionesCostas = 'Honorarios Regulaciones';                      // 6
    static idHistorialLiquidaciones = 'Historial de Liquidaciones';               // 7
    static idDatosExpediente = 'Datos del Expediente';                            // 8
    static idDemanda = 'Demanda y Documentación';                                 // 9
    static idContestacionDemanda = 'Contestación de Demanda y Documentación';     // 10
    static idAperturaPrueba = 'Apertura a Prueba y Respuestas de Oficio';         // 11
    static idNotasCausasAlegatos = 'Notas para la Vista de Causa y Alegatos';     // 12
    static idNegociaciones = 'Negociaciones';                                     // 13
    static idRecientes = 'Recientes';                                             // 14
    static idHonorariosPactados = 'Honorarios Pactados';                          // 15
    static idPiezasPostales = 'Piezas Postales';                                          // 16
    static idGastos = 'Gastos del Caso';                                          // 17
    /* static idMailsEnviados = 'Enviados';                                          // 18 */
    static idMailsEnviados = 'Mails Enviados';                                    // 18
    /* static idMailsRecibidos = 'Recibidos';                                        // 19 */
    static idMailsRecibidos = 'Mails Recibidos';                                  // 19
    static idAgendaCaso = 'Agenda Caso';                                          // 20
    static idTareas = 'Tareas';                                                   // 21
    static idPagos = 'Pagos';                                                     // 22
    static idFacturacion = 'Facturación';                                         // 23
    static idBalance = 'Balance';                                                 // 24
    static idEscritos = 'Redacción Escritos';                                     // 25

    static idTiposEscritos = 'tipos_escritos';                                    // Identificamos los escritos que están tipificados por el usuario vía plantillas
        
    static idClaseDirectorio = EstructurasConst.idDirectorioCasos;
    static idClaseAccesosDirectos = 'Accesos Directos';
    static idClaseRelativoCliente = 'Información General';
    static idClaseCasoJudicializado = 'Caso Judicializado';
    static idClaseGestionPrivada = 'Gestión del Caso';
    static idClaseHonorarios = 'Honorarios';
    static idClaseMensajeria = 'Mensajería';

    static datosCaso = [
        {name: 'Título del Caso', type: 'FullWidthString', src: 'titulo'},
        {name: 'Responsable Legal', type: 'String', src: 'representante', disabled: true},
        {name: 'Representante del', type: 'Select', src: 'representacion', items: ['Actor', 'Demandado'], defaultItem: 0},
        {name: {'Actor': 'Responsable Legal del Demandado', 'Demandado': 'Responsable Legal del Actor'}, type: 'SelectableString', srcSelect: 'representacion', src: 'representante_contrario', disabled: true},
        {name: 'Inicio del Caso', type: 'Date', src: 'fecha_inicio'},
        {name: 'Descripción del Caso', type: 'Autosize', src: 'motivo'},
    ];

    static getDatosCliente = () => {
        var datosClienteCaso = JSON.parse(JSON.stringify(EstructurasConstClientes.datosCliente));
        datosClienteCaso.map(dato => dato['disabled'] = true); // Para datos del Caso, se deshabilita la posibilidad de modificación de los datos exclusivos del Cliente
        const lengthCli = datosClienteCaso.length;
        var datosCasoLocal = JSON.parse(JSON.stringify(EstructurasConst.datosCaso));
        datosCasoLocal.map((dato, index) => dato['id'] = index + lengthCli); // Agregamos el id de cada dato que debe seguir en secuencia con los datos del Cliente
        datosClienteCaso = datosClienteCaso.concat(datosCasoLocal);
        return datosClienteCaso;
    }
    static datosCliente = EstructurasConst.getDatosCliente();
    
    static getDatosCaso = () => { // Getter para crear un nuevo caso
        // Extraigo unicamente los datos exclusivos del Caso
        const soloDatosCaso = EstructurasConst.datosCliente.slice(-EstructurasConst.datosCaso.length); 

        // Quitamos los datos que no son editables
        return soloDatosCaso.filter(dato => !(!!dato.disabled));
    }

    static datosExpediente = [
        {id: 0, name: 'Carátula del Expediente', type: 'FullWidthString', src: 'caratula', disabled: true},
        {id: 1, name: 'Número de Expediente', type: 'String', src: 'nro_exp', disabled: true},
        {id: 2, name: 'Número de Receptoría', type: 'String', src: 'nro_receptoria', disabled: true},
        {id: 3, name: 'Juzgado/Tribunal', type: 'String', src: 'juzgado', disabled: true},
        {id: 4, name: 'Fecha de Inicio', type: 'Date', src: 'fecha_ini', disabled: true},
        {id: 5, name: 'Fecha Último Paso', type: 'Date', src: 'fecha_paso', disabled: true},
    ];

    static datosHonorariosPactados = [
        {id: 0, name: 'Concepto', type: 'String', src: 'concepto', disabled: true},
        {id: 1, name: 'Fecha del Acuerdo', type: 'Date', src: 'fecha', disabled: true},
        //{id: 2, name: 'Modalidad de Pago', type: 'String', src: 'modalidad', disabled: true},
        {id: 2, name: 'Modalidad de Pago', type: 'Select', src: 'modalidad', items: ['Único Pago (1)', 'Dos (2)', 'Tres (3)', 'Cuatro (4)', 'Cinco (5)', 'Seis (6)'], defaultItem: 0, disabled: true},
        {id: 3, name: 'Monto Total de Honorarios', type: 'String', src: 'monto', disabled: true},
        {id: 4, name: 'Pacto de Cuota Litis / Detalle del Acuerdo', type: 'TextArea', src: 'acuerdo', disabled: false},
    ];

        
    static clasesExcludedEstructura = [EstructurasConst.idClaseDirectorio, EstructurasConst.idClaseAccesosDirectos];
    static clasesForcedIcon = [EstructurasConst.idClaseDirectorio, EstructurasConst.idClaseAccesosDirectos];

    static idClaseZero = EstructurasConst.idClaseAccesosDirectos;
    static idEstructuraZero = EstructurasConst.idEstructuraCasos;
    static idEstructuraHome = EstructurasConst.idEstructuraCasos;


    static clasesEstructuraIds = {
        [EstructurasConst.idClaseDirectorio]: [EstructurasConst.idDirectorioCasos],
        [EstructurasConst.idClaseAccesosDirectos]: [EstructurasConst.idEstructuraCasos, EstructurasConst.idRecientes],
        [EstructurasConst.idClaseRelativoCliente]: [EstructurasConst.idDatosCliente, EstructurasConst.idDocumentalCliente, EstructurasConst.idAgendaCaso], 
        [EstructurasConst.idClaseCasoJudicializado]: [EstructurasConst.idExpedienteMEV, EstructurasConst.idDatosExpediente, EstructurasConst.idDemanda, EstructurasConst.idContestacionDemanda, EstructurasConst.idAperturaPrueba], 
        [EstructurasConst.idClaseGestionPrivada]: [EstructurasConst.idEscritos, EstructurasConst.idNotasCausasAlegatos, EstructurasConst.idHistorialLiquidaciones, EstructurasConst.idNegociaciones, EstructurasConst.idGastos, EstructurasConst.idPiezasPostales],
        [EstructurasConst.idClaseHonorarios]: [EstructurasConst.idRegulacionesCostas, EstructurasConst.idHonorariosPactados, EstructurasConst.idTareas, EstructurasConst.idPagos, EstructurasConst.idFacturacion, EstructurasConst.idBalance], 
        [EstructurasConst.idClaseMensajeria]: [EstructurasConst.idMailsRecibidos, /* EstructurasConst.idMailsEnviados, */ EstructurasConst.idMailsVinculados], 
    };

    
    static estructuraLabels = {
        [EstructurasConst.idDirectorioCasos]: 'Directorio de Casos',
        [EstructurasConst.idEstructuraCasos]: 'Estructura del Caso',
        [EstructurasConst.idRecientes]: 'Modificaciones Recientes',
        [EstructurasConst.idExpedienteMEV]: 'Expediente MEV',
        [EstructurasConst.idDatosCliente]: 'Datos',
        [EstructurasConst.idDocumentalCliente]: 'Documentación',
        [EstructurasConst.idMailsVinculados]: 'Externos',
        [EstructurasConst.idMailsRecibidos]: 'Internos',
        [EstructurasConst.idRegulacionesCostas]: 'Regulaciones y Costas',
        [EstructurasConst.idHistorialLiquidaciones]: 'Liquidaciones',
        [EstructurasConst.idDatosExpediente]: 'Datos del Expediente',
        [EstructurasConst.idDemanda]: 'Demanda y Documentación',
        [EstructurasConst.idContestacionDemanda]: 'Contestación Demanda y Documentación',
        [EstructurasConst.idAperturaPrueba]: 'Apertura a Prueba y Respuestas a Oficios',
        [EstructurasConst.idAgendaCaso]: 'Agenda del Caso', 
        [EstructurasConst.idNotasCausasAlegatos]: 'Notas', //'Notas para Audiencias', // (ex) 'Notas para la Vista de Causa y Alegatos',
        [EstructurasConst.idNegociaciones]: 'Negociaciones',
        [EstructurasConst.idHonorariosPactados]: 'Honorarios Pactados',
        [EstructurasConst.idTareas]: 'Tareas Adicionales',
        [EstructurasConst.idPiezasPostales]: 'Redacción Piezas Postales',
        [EstructurasConst.idGastos]: 'Gastos del Caso',
        [EstructurasConst.idPagos]: 'Pagos Recibidos',
        [EstructurasConst.idFacturacion]: 'Facturación',
        [EstructurasConst.idBalance]: 'Balance del Caso',
        [EstructurasConst.idEscritos]: 'Redacción de Escritos',
    };

    static arrayOfIdClases = Object.keys(EstructurasConst.clasesEstructuraIds);
    static estructuraIds = Object.keys(EstructurasConst.estructuraLabels);

    static estructuraVacia = {
        [EstructurasConst.idDirectorioCasos]: {}, //'Directorio de Casos',
        [EstructurasConst.idEstructuraCasos]: {}, //'Estructura del Caso', 
        [EstructurasConst.idRecientes]: {}, //'Modificaciones Recientes', 
        [EstructurasConst.idExpedienteMEV]: {}, //'Expediente MEV',
        [EstructurasConst.idDatosCliente]: //'Datos del Cliente', 
            EstructurasOperations.getElementsDatos(EstructurasConst.datosCliente),
        [EstructurasConst.idDocumentalCliente]: { //'Documentación aportada por el Cliente', 
            },
        [EstructurasConst.idMailsVinculados]: { //'Interacción con el Cliente', 
            },
        /* [EstructurasConst.idMailsEnviados]: { //'Interacción con otros Usuarios', 
            }, */
        [EstructurasConst.idMailsRecibidos]: { //'Interacción con otros Usuarios', 
            },
        [EstructurasConst.idRegulacionesCostas]: { //'Honorarios Regulaciones', 
            },
        [EstructurasConst.idHistorialLiquidaciones]: { //'Historial de Liquidaciones', 
            },
        [EstructurasConst.idDatosExpediente]: //'Datos del Expediente', 
            EstructurasOperations.getElementsDatos(EstructurasConst.datosExpediente),
        [EstructurasConst.idDemanda]: { //'Demanda y Documentación', 
            },
        [EstructurasConst.idContestacionDemanda]: { //'Contestación de Demanda y Documentación', 
            },
        [EstructurasConst.idAperturaPrueba]: { //'Apertura a Prueba y Respuestas de Oficio', 
            },
        [EstructurasConst.idAgendaCaso]: { 
            },
        [EstructurasConst.idNotasCausasAlegatos]: { //'Notas para la Vista de Causa y Alegatos', 
            },
        [EstructurasConst.idNegociaciones]: { //'Negociaciones'
            },
        [EstructurasConst.idHonorariosPactados]: //'Honorarios Pactados', 
            EstructurasOperations.getElementsDatos(EstructurasConst.datosHonorariosPactados),
        [EstructurasConst.idTareas]: { //'Tareas', 
            },
        [EstructurasConst.idPiezasPostales]: { //'Piezas Postales', 
            },
        [EstructurasConst.idGastos]: { //'Gastos del Casos'
            },
        [EstructurasConst.idPagos]: { //'Pagos y Saldo del Caso'
            },
        [EstructurasConst.idFacturacion]: { //'Facturación y comprobantes'
            },
        [EstructurasConst.idBalance]: { //'Balance del Caso'
            },
        [EstructurasConst.idEscritos]: { //'Redacción Escritos'
            },
    }

    static icons = {
        [EstructurasConst.idDirectorioCasos]: <PermMediaIcon key={0} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idClaseDirectorio]: <PermMediaIcon key={'0.clase'} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idEstructuraCasos]: <HomeIcon key={1} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idClaseAccesosDirectos]: <HomeIcon key={'1.clase'} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idRecientes]: <StarIcon key={14} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idExpedienteMEV]: <MEVIcon key={2} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idDatosCliente]: <PersonIcon key={3} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idDocumentalCliente]: <DescriptionIcon key={4} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsVinculados]: <ContactMailIcon key={5} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idRegulacionesCostas]: <RegulacionesIcon key={6} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idHistorialLiquidaciones]: <MonetizationIcon key={7} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idDatosExpediente]: <MenuBookIcon key={8} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idDemanda]: <CallMadeIcon key={9} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idContestacionDemanda]: <CallReceivedIcon key={10} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idAperturaPrueba]: <FingerprintIcon key={11} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idNotasCausasAlegatos]: <RecordVoiceOverIcon key={12} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idNegociaciones]: <ThumbsUpDownIcon key={13} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idHonorariosPactados]: <HonorariosIcon key={15} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idPiezasPostales]: <TelegramIcon key={16} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idGastos]: <GastosIcon key={17} fontSize={EstructurasOperations.fontSize} />,
        //[EstructurasConst.idMailsEnviados]: <SendIcon key={18} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsRecibidos]: <InboxIcon key={19} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idAgendaCaso]: <AgendaIcon key={20} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idTareas]: <TareasIcon key={21} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idPagos]: <PagosIcon key={22} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idFacturacion]: <FacturacionIcon key={23} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idBalance]: <BalanceIcon key={24} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idEscritos]: <EscritosIcon key={25} fontSize={EstructurasOperations.fontSize} />,
    }

    // -----------------------------------------------------------------------------------------
    static headCellsAdjuntos = [ 
        { id: 'nro', numeric: false, disablePadding: true, label: 'No.' },
        { id: 'texto', numeric: false, disablePadding: true, label: 'Adjuntos' },
    ];
    
    // Mails Recibidos -------------------------------------------------------------------------
    static headCellsRecibidos = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente' }, 
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];
    
    static headCellsReplyRecibidos = [ 
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Enviados -------------------------------------------------------------------------
    static headCellsEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];
    
    static headCellsReplyEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Vinculados -------------------------------------------------------------------------
    static headCellsVinculados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    
    // Tipos Escritos y rutas Firebase para templates
    static escritos = {
        Demanda: {
            label: 'Demanda', 
            firebase: 'Demanda', 
        },
        Contestacion: {
            label: 'Contestación', 
            firebase: 'Contestación', 
        },
        Escrito_judicial_con_patrocinio_civil: {
            label: 'Escrito judicial con patrocinio (CIVIL)',
            firebase: 'Escrito judicial con patrocinio CIVIL',
        },
        Escrito_judicial_con_patrocinio_laboral: {
            label: 'Escrito judicial con patrocinio (LABORAL)',
            firebase: 'Escrito judicial con patrocinio LABORAL',
        },
        Escrito_judicial_con_poder_civil: {
            label: 'Escrito judicial con poder (CIVIL)',
            firebase: 'Escrito judicial con poder CIVIL',
        },
        Escrito_judicial_con_poder_laboral: {
            label: 'Escrito judicial con poder (LABORAL)',
            firebase: 'Escrito judicial con poder LABORAL',
        },
        Escrito_judicial_de_mero_tramite: {
            label: 'Escrito judicial de mero trámite', 
            firebase: 'Escrito judicial de mero trámite', 
        },
        Oficio: {
            label: 'Oficio', 
            firebase: 'Oficio', 
        },
        Cédula: {
            label: 'Cédula',
            firebase: 'Cédula',
        },
        Contrato: {
            label: 'Contrato',
            firebase: 'Contrato',
        },
        Acuerdo_conciliatorio: {
            label: 'Acuerdo conciliatorio',
            firebase: 'Acuerdo conciliatorio',
        },
    }

    static getLabelsEscritos = () => {
        return Object.values(this.escritos).map(key => key.label);
    }
}