import axios from 'axios';

import { serializeDateAsJsonUTC } from '../utils/DateUtils'

import Session from '../../logged_in/session/Session';
import {
    endPointContactos,
    endPointRestoreContacto,
    endPointTrashContactos,
    host
} from './APIConsts';
import EstructurasConst from '../../logged_in/components/contactos/shared/EstructurasConst';
import MensajeriaAPI from './MensajeriaAPI';
import CasosAPI from './CasosAPI';


export default class ContactosAPI {
    static _instance = null;

    constructor() {
        if (ContactosAPI._instance !== null)
            throw new Error('Falla de instanciación: ContactosAPI es singleton. Utilizar ContactosAPI.getInstance()');
        else
            ContactosAPI._instance = this;
    }

    static getInstance = () => {
        if (ContactosAPI._instance === null)
            ContactosAPI._instance = new ContactosAPI();
        return ContactosAPI._instance;
    };

    session = Session.getInstance();


    getEstudioContactos = async (cache_it, email) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const creador = email ? email : null;
        const params = email ? `estudio_id=${estudio_id}&creador=${creador}` : `estudio_id=${estudio_id}`;
        
        return axios.get(`${host}/${endPointContactos}?${params}`)
        .then(contactos => {
            const contactosOut = [];
            contactos.map((contacto) => {
                contactosOut.push(this._deserializeContactoPresenter(contacto));
            });
            if (cache_it)
                this.session.setContactos(contactosOut);
            return contactosOut;
        })
        .catch (err => {
            throw err;
        });
    }


    getContacto = async (estudio_id, contacto_id) => {
        return axios.get(`${host}/${endPointContactos}?estudio_id=${estudio_id}&contacto_id=${contacto_id}`)
        .then(contacto => {
            return this._deserializeContacto(contacto, estudio_id, contacto_id);
        })
        .catch (err => {
            throw err;
        });
    }

    
    getSection = async (estudio_id, contacto_id, idSection) => {
        return axios.get(`${host}/${endPointContactos}?estudio_id=${estudio_id}&contacto_id=${contacto_id}`)
        .then(contacto => {
            return this._deserializeSection(contacto, estudio_id, contacto_id, idSection);
        })
        .catch (err => {
            throw err;
        });
    }


    addContacto = async (estructura) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const newContacto = this._serializeNewContacto(estudio_id, estructura);
        return axios.post(`${host}/${endPointContactos}?`, newContacto)
        .then(contacto => {
            return this._deserializeContacto(contacto, estudio_id, contacto.id);
        })
        .catch (err => {
            throw err;
        });
    }


    editContacto = async (estudio_id, contacto_id, idEstructura, data) => {
        const user_id = this.session.getLoggedUser().id;
        const edit = this._serializeEstructuraContacto(estudio_id, user_id, contacto_id, idEstructura, data);
        return axios.put(`${host}/${endPointContactos}?`, edit)
        .then(contacto => {
            return this._deserializeContactoEdited(contacto, estudio_id, contacto.id, data, idEstructura);
        })
        .catch (err => {
            throw err;
        });
    }


    deleteContacto = async (contacto_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const data = this._serializeContactoDelete(contacto_id, estudio_id);
        return axios.delete(`${host}/${endPointContactos}?estudio_id=${estudio_id}&contacto_id=${contacto_id}`)
        .catch (err => {
            throw err;
        });
    }


    getPapelera = async () => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        return axios.get(`${host}/${endPointTrashContactos}?estudio_id=${estudio_id}`)
        .then(contactos => {
            return this._deserializePapelera(contactos);
        })
        .catch (err => {
            throw err;
        });
    }


    restoreContacto = async (contacto_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = {
            "contacto_id": contacto_id,
            "estudio_id": estudio_id
        }
        return axios.put(`${host}/${endPointRestoreContacto}?`, edit)
        .then(data => {
            return data
        })
        .catch (err => {
            throw err;
        });
    }

    _deserializeContactoEdited = async (contactoJson, estudio_id, contacto_id, contactoOriginal, idEstructura) => {
        contactoJson = await this._deserializeContacto(contactoJson, estudio_id, contacto_id);
        const idEditActual = idEstructura[0]; // Guarda, se asume que el primero del array corresponde con la sección que el usuario está editando

        Object.keys(contactoJson).map(key => {
            if (key !== idEditActual) {
                contactoOriginal[[key]] = contactoJson[[key]];
            }
        });

        return contactoOriginal;
    }


    _serializeNewContacto = (estudio_id, estructura) => {
        return {
            'estudio_id': estudio_id,
            'data': estructura
        };
    }

    _serializeContactoDelete = (contacto_id, estudio_id) => {
        return {
            'contacto_id': contacto_id,
            'estudio_id': estudio_id
        };
    }

    _serializeEstructuraContacto = (estudio_id, user_id, contacto_id, idEstructura, data) => {
        const date = new Date();

        const payload = {};
        idEstructura.map(
            (id) => {
                payload[id] = data[id]
            }
        );

        return {
            'contacto_id': contacto_id,
            'estudio_id': estudio_id,
            'usuario_id': user_id,
            'key': idEstructura,
            'data': payload,
            'timestamp': serializeDateAsJsonUTC(date)
        }
    }

    
    _deserializeSection = async (contactoJson, estudio_id, contacto_id, idSection) => {
        const creador_id = contactoJson.creador_id;
        const id = contactoJson.id;
        contactoJson = contactoJson['contents'];
        contactoJson.creador_id = creador_id;
        contactoJson.id = id;

        contactoJson.actividad = contactoJson[EstructurasConst.idDatosContacto].actividad;
        contactoJson.nombre = contactoJson[EstructurasConst.idDatosContacto].nombre;
        contactoJson.apellido = contactoJson[EstructurasConst.idDatosContacto].apellido;
        contactoJson.fecha_inicio = contactoJson[EstructurasConst.idDatosContacto].fecha_inicio;
        
        switch (idSection) {
            case EstructurasConst.idCasos:
                contactoJson[EstructurasConst.idCasos] = await CasosAPI.getInstance().getEstudioCasosContacto(contacto_id);
                break;
            /* case EstructurasConst.idClaseMensajeria:
                contactoJson[EstructurasConst.idClaseMensajeria] = await MensajeriaAPI.getInstance().getMensajesCliente(estudio_id, contacto_id);
                break; */
        };
        return contactoJson[idSection];
    }


    _deserializeContacto = async (contactoJson, estudio_id, contacto_id) => {
        const creador_id = contactoJson.creador_id;
        const id = contactoJson.id;
        contactoJson = contactoJson['contents'];
        contactoJson.creador_id = creador_id;
        contactoJson.id = id;

        contactoJson.actividad = contactoJson[EstructurasConst.idDatosContacto].actividad;
        contactoJson.nombre = contactoJson[EstructurasConst.idDatosContacto].nombre;
        contactoJson.apellido = contactoJson[EstructurasConst.idDatosContacto].apellido;
        contactoJson.fecha_inicio = contactoJson[EstructurasConst.idDatosContacto].fecha_inicio;
        
        contactoJson[EstructurasConst.idCasos] = await CasosAPI.getInstance().getEstudioCasosContacto(contacto_id);
        /* contactoJson[EstructurasConst.idClaseMensajeria] = await MensajeriaAPI.getInstance().getMensajesCliente(estudio_id, contacto_id); */
        
        return contactoJson;
    }

    
    _deserializeContactoPresenter = (contactoJson) => {
        const contactoOut = contactoJson;
        contactoOut.hasUnreadMails = false;
        //contactoOut.hasUnreadMails = this.session.hasContactoUnreadMails(contactoJson.id);
        contactoOut.id = contactoJson.id;
        contactoOut.creador_id = this.session.getLoggedUser().id; // Este es necesario para que no los saltee el filtro pensando que son contactos de otros usuarios (los contactos son del estudio entero)
        contactoOut.actividad = contactoJson['contents'][EstructurasConst.idDatosContacto].actividad;
        contactoOut.nombre = contactoJson['contents'][EstructurasConst.idDatosContacto].nombre;
        contactoOut.apellido = contactoJson['contents'][EstructurasConst.idDatosContacto].apellido;
        contactoOut.email = contactoJson['contents'][EstructurasConst.idDatosContacto].email;
        contactoOut.fecha_inicio =
            contactoJson['contents'][EstructurasConst.idDatosContacto].fecha_inicio ?
                contactoJson['contents'][EstructurasConst.idDatosContacto].fecha_inicio
                :
                serializeDateAsJsonUTC(new Date());
        return contactoOut;
    }

    _deserializePapelera = (contactos) => {
        contactos.map((contacto,index) => {
            contacto['contacto_id'] = contacto.id;
            contacto['contacto'] = `${contacto.contents[EstructurasConst.idDatosContacto].nombre} ${contacto.contents[EstructurasConst.idDatosContacto].apellido}`;
            contacto['actividad'] = contacto.contents[EstructurasConst.idDatosContacto].actividad;
            contacto['email'] = contacto.contents[EstructurasConst.idDatosContacto].email;
            contacto['fecha_inicio'] = contacto.contents[EstructurasConst.idDatosContacto].fecha_inicio;
            contacto['id'] = index;
            contacto['i'] = index;
        });
        return contactos;
    }
}
