const prefix_root = "/c";
const prefix_casos = "/c/casos";
const prefix_clientes = "/c/clientes";
const prefix_contactos = "/c/contactos";
const prefix_mev = "/c/mev";
const prefix_agenda = "/c/agenda";
const prefix_mails = "/c/mails";
const prefix_honorarios = "/c/honorarios";
const prefix_administrador = "/c/administrador";
const prefix_backOffice = "/c/backOffice";
const prefix_liquidaciones = "/c/liquidaciones";
const prefix_biblioteca = "/c/biblioteca";
const prefix_papelera = "/c/papelera";
const prefix_planificador = "/c/planificador";

        
export const getPathRoot = () => {
    return getPath(prefix_root)
}

const getPath = (prefix, idSection, idSubsection) => {
    if (!!idSection && !!idSubsection) {
        return `${prefix}/${idSection}/${idSubsection}`;
    } else if (!!idSection) {
        return `${prefix}/${idSection}`;
    } else {
        return `${prefix}`;
    }
}

export const getPathCaso = (idSection, idSubsection) => {
    return getPath(prefix_casos, idSection, idSubsection)
}

export const getPathCliente = (idSection, idSubsection) => {
    return getPath(prefix_clientes, idSection, idSubsection)
}

export const getPathPlanificador = (idSection, idSubsection) => {
    return getPath(prefix_planificador, idSection, idSubsection)
}

export const getPathContacto = (idSection, idSubsection) => {
    return getPath(prefix_contactos, idSection, idSubsection)
}

export const getPathMEV = (idSection, idSubsection) => {
    return getPath(prefix_mev, idSection, idSubsection)
}

export const getPathAgenda = (idSection, idSubsection) => {
    return getPath(prefix_agenda, idSection, idSubsection)
}

export const getPathMails = (idSection, idSubsection) => {
    return getPath(prefix_mails, idSection, idSubsection)
}

export const getPathHonorarios = (idSection, idSubsection) => {
    return getPath(prefix_honorarios, idSection, idSubsection)
}

export const getPathAdministrador = (idSection, idSubsection) => {
    return getPath(prefix_administrador, idSection, idSubsection)
}

export const getPathBackOffice = (idSection, idSubsection) => {
    return getPath(prefix_backOffice, idSection, idSubsection)
}

export const getPathLiquidaciones = (idSection, idSubsection) => {
    return getPath(prefix_liquidaciones, idSection, idSubsection)
}

export const getPathBiblioteca = (idSection, idSubsection) => {
    return getPath(prefix_biblioteca, idSection, idSubsection)
}

export const getPathPapelera = (idSection, idSubsection) => {
    return getPath(prefix_papelera, idSection, idSubsection)
}


export const getDataFromPath = (path) => {
    const pathArray = path.split('/');
    const objectOut = {};
    objectOut['section'] = pathArray[2];
    objectOut['id'] = Number(pathArray[3]);
    objectOut['subsection'] = pathArray[4];
    return objectOut;
}

export const getCurrentPath = () => {
    return window.location.href.split('c/')[1];
}