import axios from 'axios';
import { 
    getTimeAsStrShortFormatES
} from '../utils/DateUtils'

import { 
    endPointRequestToken,
    endPointReleaseToken,
    endPointCheckToken,
    host,
} from './APIConsts';

import Session from '../../logged_in/session/Session';

import EstructurasConstCases from "../../logged_in/components/casos/shared/EstructurasConst";
import EstructurasConstContactos from "../../logged_in/components/contactos/shared/EstructurasConst";
import EstructurasConstClients from "../../logged_in/components/clientes/shared/EstructurasConst";
import EstructurasConstAdmin from '../../logged_in/components/administrador/shared/EstructurasConst';


export default class TokensAPI {
    static _instance = null;

    constructor() {
        if (TokensAPI._instance !== null)
            throw new Error('Falla de instanciación: TokensAPI es singleton. Utilizar TokensAPI.getInstance()');
        else
            TokensAPI._instance = this;
    }

    static getInstance = () => {
        if (TokensAPI._instance === null)
            TokensAPI._instance = new TokensAPI();
        return TokensAPI._instance;
    };

    session = Session.getInstance();
    
    _tokensDependecySetsContactos = [
        [EstructurasConstContactos.idDatosContacto],
    ];

    _tokensDependecySetsClients = [
        [
            EstructurasConstClients.idConsultas, 
            EstructurasConstClients.idPiezasPostales, 
            EstructurasConstClients.idHonorariosPactados, 
            EstructurasConstClients.idFacturacion, 
            EstructurasConstClients.idPagos, 
            EstructurasConstClients.idHonorariosAbono
        ],
        [EstructurasConstClients.idAbono],
        [EstructurasConstClients.idDatosCliente], 
        [EstructurasConstClients.idGastos], 
    ];
    
    _tokensDependecySetsCases = [
        [
            EstructurasConstCases.idRegulacionesCostas, 
            EstructurasConstCases.idHonorariosPactados, 
            EstructurasConstCases.idTareas, 
            EstructurasConstCases.idFacturacion,

            EstructurasConstCases.idExpedienteMEV, 
            EstructurasConstCases.idDemanda, 
            EstructurasConstCases.idContestacionDemanda, 
            EstructurasConstCases.idAperturaPrueba
        ],
        [EstructurasConstCases.idDocumentalCliente],
        [EstructurasConstCases.idHistorialLiquidaciones],
        [EstructurasConstCases.idNegociaciones], 
        [EstructurasConstCases.idNotasCausasAlegatos], 
        [EstructurasConstCases.idEscritos],
        [EstructurasConstCases.idDatosCliente], 
        [EstructurasConstCases.idGastos], 
        [EstructurasConstCases.idPagos],
        [EstructurasConstCases.idPiezasPostales],
    ];

    _tokensDependecySetsAdmin = [
        [EstructurasConstAdmin.idEstadistica],
        [EstructurasConstAdmin.idUsuarios],
        [EstructurasConstAdmin.idCuenta],
        [EstructurasConstAdmin.idPlantillas],
        [EstructurasConstAdmin.idSuscripcion],
    ];
    
    _onlyReadSectionsContactos = [
        EstructurasConstContactos.idCasos,
    ];
    _onlyReadSectionsClients = [
        EstructurasConstClients.idCasos,
        EstructurasConstClients.idBalance,
        EstructurasConstClients.idRecientes,
        EstructurasConstClients.idClaseMensajeria,
    ];
    _onlyReadSectionsCases = [
        EstructurasConstCases.idDatosExpediente,
        EstructurasConstCases.idAperturaPrueba, 
        EstructurasConstCases.idContestacionDemanda, 
        EstructurasConstCases.idDemanda,
        EstructurasConstCases.idBalance,
        EstructurasConstCases.idRecientes,
        EstructurasConstCases.idClaseMensajeria,
    ];

    _onlyReadSectionsAdmin = [
        EstructurasConstAdmin.idEstadistica,
    ];
    
    
    
    _getTokensDependencySet = (idSection, dependencySets) => {
        const filteredSet = dependencySets.filter(dependecySet => dependecySet.includes(idSection));
        var dependecySet;
        if (filteredSet.length === 0) {
            dependecySet = [idSection];
        } else {
            dependecySet = filteredSet[0];
        }
        return dependecySet;
    }
    
    getTokensDependencySetCases = (idSection) => {
        return this._getTokensDependencySet(idSection, this._tokensDependecySetsCases);
    }
    
    getTokensDependencySetContactos = (idSection) => {
        return this._getTokensDependencySet(idSection, this._tokensDependecySetsContactos);
    }

    getTokensDependencySetClients = (idSection) => {
        return this._getTokensDependencySet(idSection, this._tokensDependecySetsClients);
    }
    
    getTokensDependencySetAdmin = (idSection) => {
        return this._getTokensDependencySet(idSection, this._tokensDependecySetsAdmin);
    }

    isOnlyReadSectionContactos = (idSect) => {
        return this._onlyReadSectionsContactos.includes(idSect);
    }

    isOnlyReadSectionClients = (idSect) => {
        return this._onlyReadSectionsClients.includes(idSect);
    }

    isOnlyReadSectionCases = (idSect) => {
        return this._onlyReadSectionsCases.includes(idSect);
    }

    isOnlyReadSectionAdmin = (idSect) => {
        return this._onlyReadSectionsAdmin.includes(idSect);
    }
    
    _isActiveToken = async (model_id, idSeccion, type) => {
        const loggedUser = this.session.getLoggedUser();
        const params = `usuario_id=${loggedUser.id}&estudio_id=${loggedUser.estudio_id}&type=${type}&model_id=${model_id}&secciones=${idSeccion}`;
        return axios.get(`${host}/${endPointCheckToken}?${params}`)
        .then(response => {
            return response;
        })
        .catch (err => {
            throw err;
        });
    }

    
    isActiveTokenCaso = async (model_id, idSeccion) => {
        return await this._isActiveToken(model_id, idSeccion, 'caso');
    }

    isActiveTokenCliente = async (model_id, idSeccion) => {
        return await this._isActiveToken(model_id, idSeccion, 'cliente');
    }

    
    requestTokenCaso = async (caso_id, idSeccion, flexibleTokenization) => {
        const loggedUser = this.session.getLoggedUser();
        const body = this._serializeRequestCaso(loggedUser.id, loggedUser.estudio_id, caso_id, idSeccion);// arraySeccionesDependientes);
        return axios.post(`${host}/${endPointRequestToken}?`, body)
        .then(response => {
            return this._deserializeToken(response, loggedUser.id, idSeccion, flexibleTokenization);
        })
        .catch (err => {
            throw err;
        });
    }

    requestTokenContacto = async (contacto_id, idSeccion, flexibleTokenization) => {
        const loggedUser = this.session.getLoggedUser();
        const body = this._serializeRequestContacto(loggedUser.id, loggedUser.estudio_id, contacto_id, idSeccion);
        return axios.post(`${host}/${endPointRequestToken}?`, body)
        .then(response => {
            return this._deserializeToken(response, loggedUser.id, idSeccion, flexibleTokenization);
        })
        .catch (err => {
            throw err;
        });
    }

    requestTokenCliente = async (cliente_id, idSeccion, flexibleTokenization) => {
        const loggedUser = this.session.getLoggedUser();
        const body = this._serializeRequestCliente(loggedUser.id, loggedUser.estudio_id, cliente_id, idSeccion);
        return axios.post(`${host}/${endPointRequestToken}?`, body)
        .then(response => {
            return this._deserializeToken(response, loggedUser.id, idSeccion, flexibleTokenization);
        })
        .catch (err => {
            throw err;
        });
    }

    requestTokenAdmin = async (idSeccion, flexibleTokenization) => {
        const loggedUser = this.session.getLoggedUser();
        const body = this._serializeRequestAdmin(loggedUser.id, loggedUser.estudio_id, idSeccion);// arraySeccionesDependientes);
        return axios.post(`${host}/${endPointRequestToken}?`, body)
        .then(response => {
            return this._deserializeToken(response, loggedUser.id, idSeccion, flexibleTokenization);
        })
        .catch (err => {
            throw err;
        });
    }

    releaseToken = async () => {
        const loggedUser = this.session.getLoggedUser();
        const body = this._serializeRelease(loggedUser.id, loggedUser.estudio_id);
        return axios.put(`${host}/${endPointReleaseToken}?`, body)
        .then(resp => {
            return resp;
        })
        .catch (err => {
            throw err;
        });
    }

    _serializeRelease = (usuario_id, estudio_id) => {
        return {
            'usuario_id': usuario_id,
            'estudio_id': estudio_id,
        }
    }


    _serializeRequest = (usuario_id, estudio_id, model_id, arraySeccionesDependientes) => {
        return {
            "usuario_id": usuario_id,
            "estudio_id": estudio_id,
            "model_id": model_id, 
            "secciones": arraySeccionesDependientes, 
        }
    }

    _serializeRequestCaso = (usuario_id, estudio_id, caso_id, idSeccion /* arraySeccionesDependientes */) => {
        const jsonOut = this._serializeRequest(usuario_id, estudio_id, caso_id, this.getTokensDependencySetCases(idSeccion)); // arraySeccionesDependientes);
        jsonOut['type'] = "caso";
        return jsonOut;
    }

    _serializeRequestContacto = (usuario_id, estudio_id, contacto_id, idSeccion) => {
        const jsonOut = this._serializeRequest(usuario_id, estudio_id, contacto_id, this.getTokensDependencySetContactos(idSeccion));
        jsonOut['type'] = "contacto";
        return jsonOut;
    }

    _serializeRequestCliente = (usuario_id, estudio_id, cliente_id, idSeccion) => {
        const jsonOut = this._serializeRequest(usuario_id, estudio_id, cliente_id, this.getTokensDependencySetClients(idSeccion));
        jsonOut['type'] = "cliente";
        return jsonOut;
    }

    _serializeRequestAdmin = (usuario_id, estudio_id, idSeccion /* arraySeccionesDependientes */) => {
        const jsonOut = this._serializeRequest(usuario_id, estudio_id, 1, this.getTokensDependencySetAdmin(idSeccion)); // arraySeccionesDependientes);
        jsonOut['type'] = "admin";
        return jsonOut;
    }

    _serializeCheckToken = (usuario_id, estudio_id, model_id, idSeccion) => {
        return {
            "usuario_id": usuario_id,
            "estudio_id": estudio_id,
            "model_id": model_id, 
            "secciones": [idSeccion],
        }
    }
    
    _serializeCheckTokenCaso = (usuario_id, estudio_id, caso_id, idSeccion) => {
        const jsonOut = this._serializeCheckToken(usuario_id, estudio_id, caso_id, idSeccion);
        jsonOut['type'] = "caso";
        return jsonOut;
    }

    _serializeCheckTokenCliente = (usuario_id, estudio_id, cliente_id, idSeccion) => {
        const jsonOut = this._serializeCheckToken(usuario_id, estudio_id, cliente_id, idSeccion);
        jsonOut['type'] = "cliente";
        return jsonOut;
    }

    /* 
    ------------------------------------------------------
        Responses del request write_token
    ------------------------------------------------------

    - cuando el usuario que pidio el token pudo obtenerlo:
    {
        "active_user_token": 
        {
            "expires": "Wed, 04 May 2022 01:23:23 GMT",
            "requested": "Wed, 04 May 2022 00:23:23 GMT",
            "secciones": 
            [
                "pepito"
            ],
            "usuario_id": 8
        },
        "other_users_token": []
    }
      
    - cuando el token lo tenia otro (u otros, este es un ejemplo donde varios users tienen el token que se pide):
    {
        "active_user_token": null,
        "other_users_token": 
        [
            {
                "expires": "Wed, 04 May 2022 01:23:23 GMT",
                "requested": "Wed, 04 May 2022 00:23:23 GMT",
                "secciones": [
                    "pepito"
                ],
                "usuario_id": 8
            },
            {
                "expires": "Wed, 04 May 2022 01:22:06 GMT",
                "requested": "Wed, 04 May 2022 00:22:06 GMT",
                "secciones": 
                    [
                        "pepita"
                    ],
                    "usuario_id": 2
            }
        ]
    }  
    */
    _deserializeToken = (response, loggedUser_id, idSeccion, flexibleTokenization) => {
        const getRequestedSectionToken = () => {
            var requestedSectionToken = null;
            response.other_users_token.some(token => {
                if (token.secciones.includes(idSeccion)) {
                    requestedSectionToken = token;
                    return true;
                };
                return false;
            });
            
            // Si aún requestedSectionToken sigue en null es porque nadie tiene un token sobre la sección de la cual se hizo el request, 
            // sino que hay alguna de las secciones dependientes con un token otorgado
            
            /* if (!requestedSectionToken) { 
                requestedSectionToken = response.other_users_token[0];
            }; */
            return requestedSectionToken;
        };
        const requestedSectionToken = getRequestedSectionToken();
        const tokenDisablesSection = !(!!response.active_user_token);
        const tokenizedUser = !tokenDisablesSection? loggedUser_id : requestedSectionToken? requestedSectionToken.usuario_id : null;
        const sectionTokenExpiration = getTimeAsStrShortFormatES(new Date(!tokenDisablesSection? response.active_user_token.expires : requestedSectionToken? requestedSectionToken.expires: null));

        return {
            tokenizedUser: tokenizedUser, // Usuario_id que tiene el token de la seccion requerida (idSection)
            tokenDisablesSection: tokenDisablesSection, // Token request fue negado
            idSection: idSeccion, // Seccion desde la que se requiere el token
            sectionTokenExpiration: sectionTokenExpiration, // Tiempo restante del token actualmente activo para la sección requerida
    
            // Objeto que determina las condiciones para habilitar o deshabilitar botones de las customTables de acuerdo a haber sido aprobado el token request
            flexibleTokenization: !!flexibleTokenization?flexibleTokenization[tokenDisablesSection]: undefined, 
        };
    }
}
