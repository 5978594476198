import React from "react";

import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import ExternalIcon from '@material-ui/icons/CallReceived';
import InternalIcon from '@material-ui/icons/MailOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EstructurasOperations from "../../../../shared/components/TabComponents/EstructurasOperations";

export default class EstructurasConst {
    // Estos son los id de las collections correspondientes en firebase
    static idMailsRecibidos = 'Recibidos';                                            // 1
    static idMailsEnviados = 'Enviados';                                              // 2
    static idMailsVinculados = 'Vinculados';                                          // 3
    static idMailsDesvinculados = 'Desvinculados';                                    // 4


    static idClaseInternos = 'Mensajes Internos';
    static idClaseExternos = 'Mensajes Externos';


    static clasesExcludedEstructura = [EstructurasConst.idClaseInternos, EstructurasConst.idClaseExternos];
    static clasesForcedIcon = [EstructurasConst.idClaseInternos, EstructurasConst.idClaseExternos];

    /* static idClaseZero = EstructurasConst.idClaseInternos;
    static idEstructuraZero = EstructurasConst.idMailsRecibidos;
    static idEstructuraHome = EstructurasConst.idMailsRecibidos; */

    static idClaseZero = EstructurasConst.idClaseExternos;
    static idEstructuraZero = EstructurasConst.idMailsDesvinculados;
    static idEstructuraHome = EstructurasConst.idMailsDesvinculados;

    /* static clasesEstructuraIds = {
        [EstructurasConst.idClaseInternos]: [EstructurasConst.idMailsRecibidos, EstructurasConst.idMailsEnviados],
        [EstructurasConst.idClaseExternos]: [EstructurasConst.idMailsVinculados, EstructurasConst.idMailsDesvinculados], 
    }; */

    static clasesEstructuraIds = {
        [EstructurasConst.idClaseExternos]: [EstructurasConst.idMailsDesvinculados, EstructurasConst.idMailsVinculados], 
        [EstructurasConst.idClaseInternos]: [EstructurasConst.idMailsRecibidos, EstructurasConst.idMailsEnviados],
    };

    
    static estructuraLabels = {
        [EstructurasConst.idMailsRecibidos]: 'Recibidos',
        [EstructurasConst.idMailsEnviados]: 'Enviados',
        [EstructurasConst.idMailsVinculados]: 'Vinculados a clientes o casos',
        [EstructurasConst.idMailsDesvinculados]: 'Desvinculados',
    };

    static arrayOfIdClases = Object.keys(EstructurasConst.clasesEstructuraIds);
    static estructuraIds = Object.keys(EstructurasConst.estructuraLabels);
    
    static estructuraVacia = {
        [EstructurasConst.idMailsRecibidos]: {}, 
        [EstructurasConst.idMailsEnviados]: {}, 
        [EstructurasConst.idMailsVinculados]: {},
        [EstructurasConst.idMailsDesvinculados]: {},
    }

    static icons = {
        [EstructurasConst.idMailsRecibidos]: <InboxIcon key={1} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsEnviados]: <SendIcon key={2} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsVinculados]: <LinkIcon key={3} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idMailsDesvinculados]: <LinkOffIcon key={4} fontSize={EstructurasOperations.fontSize} />,
        [EstructurasConst.idClaseInternos]: <InternalIcon key={'0.clase'} fontSize={EstructurasOperations.fontSize}/>,
        [EstructurasConst.idClaseExternos]: <ExternalIcon key={'1.clase'} fontSize={EstructurasOperations.fontSize}/>,
    }

    // -----------------------------------------------------------------------------------------
    static headCellsAdjuntos = [ 
        { id: 'nro', numeric: false, disablePadding: true, label: 'No.' },
        { id: 'texto', numeric: false, disablePadding: true, label: 'Adjuntos' },
    ];
    
    // Mails Recibidos -------------------------------------------------------------------------
    static headCellsRecibidos = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'cliente', isCliente: true, numeric: false, disablePadding: false, label: 'Cliente Vinculado' }, 
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso Vinculado' }, 
        { id: 'leido', isMailLeido: true, numeric: false, disablePadding: true, label: 'Leído', disabled: true },
    ];
      
    static headCellsReplyRecibidos = [ 
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'sender', isUser: true, numeric: false, disablePadding: false, label: 'Remitente', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Enviados -------------------------------------------------------------------------
    static headCellsEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario' }, 
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'cliente', isCliente: true, numeric: false, disablePadding: false, label: 'Cliente Vinculado' }, 
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso Vinculado' }, 
    ];
      
    static headCellsReplyEnviados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'recipient', isUser: true, numeric: false, disablePadding: false, label: 'Destinatario', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];

    // Mails Vinculados -------------------------------------------------------------------------
    static headCellsVinculados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
        { id: 'cliente', isCliente: true, numeric: false, disablePadding: false, label: 'Cliente' }, 
        { id: 'caso', isCaso: true, numeric: false, disablePadding: false, label: 'Caso' }, 
    ];
    
    // Mails Desvinculados -------------------------------------------------------------------------
    static headCellsDesvinculados = [
        { id: 'id', numeric: false, disablePadding: true, label: 'No.', disabled: true },
        { id: 'asunto', numeric: false, disablePadding: false, label: 'Asunto', showsAttach: true }, 
        { id: 'fecha', numeric: false, isDateTime: true, disablePadding: true, label: 'Fecha' },
    ];
}