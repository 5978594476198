/* 
------------ ALGUNOS IMPORTS QUE PROBÉ PARA GENERAR UN PDF DEL TEXTO HTML QUILL (borrar) -----------------------

import {jsPDF} from 'jspdf';
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';

//import ReactPDF from '@react-pdf/renderer';

//import * as pdfMake from 'pdfmake/build/pdfmake';
//import htmlToPdf from './../shared/htmlToPdf';

import ReactDOMServer from "react-dom/server";
import RNHTMLtoPDF from 'react-native-html-to-pdf-rd';

//import * as quill from 'quilljs';

//import RNHTMLtoPDF from 'react-native-html-to-pdf';
//import reactNativeHtmlToPdfRd from "react-native-html-to-pdf-rd";

----------------------------------------------------------------------------------------------------------------
 */

import { pdfExporter } from "quill-to-pdf";
import FileManagerAPI from "../api/FileManagerAPI";

/**
 * Funciones de utilidad de uso comun
 */
export const isDefined = (x) => {
  var undefined;
  var nullObj = null;
  return x !== undefined && x !== nullObj;
};

export const range = (size, startAt = 0) => {
  return [...Array(size).keys()].map(i => i + startAt);
}

export const createPDFFromQuillEditor = async (quillContents) => {
  const link = await createPDFLinkFromQuillEditor(quillContents);
  window.open(link, "_blank");
  //openLinkBlankTab(link);
}

export const createPDFLinkFromQuillEditor = async (quillContents) => {
  console.log("QUILL CONTENTS: ", quillContents);
  const pdfBlob = await pdfExporter.generatePdf(quillContents);
  //saveAs(pdfBlob, 'pdf-export.pdf'); // -- Esta línea permite bajar directamente el archivo en lugar de abrirlo en un tab nuevo del browser

  console.log("QUILL PDFBLOB: ", pdfBlob);

  const link = window.URL.createObjectURL(pdfBlob);
  console.log("QUILL PDF LINK: ", link);
  return link;
}

export const openLinkBlankTab = async (link) => {
  const absLink = await FileManagerAPI.getInstance().getAbsoluteLink(link);
  window.open(absLink, "_blank");
}

/* export const openLinkBlankTab = async (link) => {
  window.open(link, "_blank");
} */

export const openLinkBuffer = async (link) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/pdf');
  headers.append('Accept', 'application/pdf');
  //headers.append('Authorization', 'Basic ' + base64.encode(username + ":" +  password));
  //headers.append('Origin', '*');
  headers.append('Origin', 'http://localhost:3000');

  let blob = await fetch(link, 
    {
      mode: 'no-cors',
      //credentials: 'include',
      method: 'GET',
      headers: headers
    },
    /* {
      method: "GET",
      //mode: 'cors',
      
      headers: {
        //"Access-Control-Allow-Origin": "*",
        //"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        'Accept': 'application/pdf',
        'Content-Type': 'application/pdf'
      },
    } */
  
  ).then(async r => {console.log("FETCHEO: ", r); const blobOut = await r.blob(); console.log("BLOB OUT: ", blobOut); return blobOut});

  /* const blob = new Blob(link);
  await blob.arrayBuffer(link); */
  console.log("BLOB: ", blob);
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(blob);
  return await new Uint8Array(await new ArrayBuffer(await fileReader));
}

export const htmlToPlainString = (htmlString) => {
  var plainStr = htmlString;
  plainStr = plainStr.replace(/<style([\s\S]*?)<\/style>/gi, '');
  plainStr = plainStr.replace(/<script([\s\S]*?)<\/script>/gi, '');
  plainStr = plainStr.replace(/<\/div>/ig, '\n');
  plainStr = plainStr.replace(/<\/li>/ig, '\n');
  plainStr = plainStr.replace(/<li>/ig, '  *  ');
  plainStr = plainStr.replace(/<\/ul>/ig, '\n');
  plainStr = plainStr.replace(/<\/p>/ig, '\n');
  plainStr = plainStr.replace(/<br\s*[\/]?>/gi, "\n");
  plainStr = plainStr.replace(/<[^>]+>/ig, '');
  return plainStr;
}
