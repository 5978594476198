import axios from 'axios';
import UsersAPI from './shared/api/UsersAPI';
import { endpointRefreshToken, hostHeroku, refreshTokenStoreId } from './shared/api/APIConsts';

export default function setupAxios(setLoginExpired) {
    // Used for controlling multiple expired requests, so we show the login dialog only once
    let expiredCount = 0;

    const buildError = errorResponse => {
        return {
            "message": errorResponse.data.message,
            "status": errorResponse.status
        }
    }
    
    axios.interceptors.response.use(
        response => {
            if (!response.config || response.config.url.includes(endpointRefreshToken)) {
                // We need the status code if the response comes from refreshing a token
                return response;
            }
            return response.data;
        },
        error => {
            const originalRequest = error.config;
    
            // Refresh token expired. Send to login
            if (error.response && error.response.status === 403 && error.response.data.message === 'invalid_grant') {
                expiredCount--;
                if (expiredCount == 0) {
                    setLoginExpired(true);
                }
                return Promise.reject(buildError(error.response));
            }
    
            // Access token expired. Use refresh token to renew access token and retry original request
            if (error.response && error.response.status === 401 && error.response.data.message === 'Unauthorized access' && !originalRequest._retry) {
                originalRequest._retry = true;
                const refreshToken = localStorage.getItem(refreshTokenStoreId);
                expiredCount++;
                return axios.post(`${hostHeroku}/${endpointRefreshToken}`, {
                    token: refreshToken
                }).then(response => {
                    if (response.status === 200) {
                        expiredCount--;
                        UsersAPI.getInstance().setAuthorization(response.data);
                        originalRequest.headers.Authorization = `Bearer ${response.data['access_token']}`;
                        return axios(originalRequest);
                    }
                });
            }
    
            // Any other error, wrap status code and message into a common object
            if (error.response && error.response.data) {
                return Promise.reject(buildError(error.response));
            }
    
            return Promise.reject(error);
        }
    );
}
