export default class CriterioFiltrado{
    
    _users = '';
    _selected = '';
    _criterio = '';
    _value = '';
    
    _defaultUsers = '';
    _defaultSelected = '';
    _defaultCriterio = '';
    _defaultValue = '';
    _estaEntidad = null;

    _timeLapse = 10;
    
    constructor() {
    }

    setDefaultCriterio = () => {
        this._criterio = this._defaultCriterio;
        this._value = this._defaultValue;
        this._users = this._defaultUsers;
        this._selected = this._defaultSelected;
    };

    setCriterio = (criterio) => {
        this._criterio = criterio.criterio;
        this._value = criterio.value;
        this._users = criterio.users;
        this._selected = criterio.selected;
    };
    
    getCriterio = () => {
        const criterioOut = {
            'selected': this._selected,
            'criterio': this._criterio,
            'value': this._value,
            'users': this._users
        };
        return criterioOut;
    };

    checkUser = (elem) => {
        if (this._estaEntidad==='MEV' || this._estaEntidad === 'Contactos') { 
            // Para Casos y Clientes tenemos todo lo correspondiente al estudio completo, en cambio para el caso de la MEV el comportamiento es diferente: 
            // sólo tenemos lo correspondiente al usuario pedido, si se cambia el usuario del filtrado, se vuelve a realizar un request al BackEnd, por eso
            // este chequeo no corresponde y siempre deberá ser verdadero. Además, los expedientes MEV no tienen creadores a diferencia de Casos y Clientes.
            return true;
        };
        return elem.creador_id === this._users.id;
    }

    applyCriterioStartsWith = (elem) => {
        return this.checkUser(elem) && elem[this._criterio] && elem[this._criterio].toLowerCase().startsWith(this._value.toLowerCase());
    }

    applyFilterAndOrderByStartsWith = (_array) => {
        const filtrados = _array.filter(a => this.applyCriterioStartsWith(a));
        return filtrados.sort((a, b) => {return (a[this._criterio] > b[this._criterio]) ? 1 : ((b[this._criterio] > a[this._criterio]) ? -1 : 0)});
    }

    applyCriterioIncludes = (elem) => {
        return this.checkUser(elem) && elem[this._criterio] && elem[this._criterio].toLowerCase().includes(this._value.toLowerCase());
    }

    applyFilterAndOrderByIncludes = (_array) => {
        const filtrados = _array.filter(a => this.applyCriterioIncludes(a));
        return filtrados.sort((a, b) => {return (a[this._criterio] > b[this._criterio]) ? 1 : ((b[this._criterio] > a[this._criterio]) ? -1 : 0)});
    }

    applyCriterioDates = (fecha) => {
        return new Date(fecha).getTime() >= new Date(this._value).getTime();
    }

    applyFilterAndOrderByDate = (_array) => {
        const filtrados = _array.filter(a => this.checkUser(a) && this.applyCriterioDates(a[this._criterio]));
        return filtrados.sort((a, b) => {
            const first = new Date(a[this._criterio]).getTime();
            const second = new Date(b[this._criterio]).getTime();
            return (first > second) ? -1 : ((second > first) ? 1 : 0);
        });
    }

    applyCriterioRecientes = (elem, idRecientes) => {
        return ( // La data de Casos y Clientes vienen dentro de contents
            // BORRAR NO CONTENTS ---------------------------------------------------------------------------------------
            (
                elem['contents'] && elem['contents'][idRecientes] && elem['contents'][idRecientes].data && this.applyCriterioDates(elem['contents'][idRecientes].data[elem['contents'][idRecientes].data.length-1].fecha)
            /* ) || (
                elem[idRecientes] && elem[idRecientes].data && this.applyCriterioDates(elem[idRecientes].data[elem[idRecientes].data.length-1].fecha) */
            ) || (
                !(elem['contents'] && elem['contents'][idRecientes] && elem['contents'][idRecientes].data) && this.applyCriterioDates(elem['fecha_inicio'])
            /* ) || (
                !(elem[idRecientes] && elem[idRecientes].data) && this.applyCriterioDates(elem['fecha_inicio']) */
            )
        );
    }

    applyFilterAndOrderByRecientes = (_array, idRecientes) => {
        const _getDate = (elem) => { // BORRAR NO CONTENTS ---------------------------------------------------------------------------------------
            if (elem['contents'] && elem['contents'][idRecientes] && elem['contents'][idRecientes].data)
            {
                return new Date(elem['contents'][idRecientes].data[elem['contents'][idRecientes].data.length-1].fecha).getTime();
            /* } else if (elem[idRecientes] && elem[idRecientes].data)
            {
                return new Date(elem[idRecientes].data[elem[idRecientes].data.length-1].fecha).getTime(); */
            } else return new Date(elem['fecha_inicio']).getTime();
        };

        const filtrados = _array.filter(a => this.checkUser(a) && this.applyCriterioRecientes(a, idRecientes));
        return filtrados.sort((a, b) => {
            const first = _getDate(a);
            const second = _getDate(b);
            return (first > second) ? -1 : ((second > first) ? 1 : 0);
        });
    }

    applyCriterioConsultas = (elem, idConsulta) => {
        return (
            (
                elem['contents'][idConsulta] && 
                elem['contents'][idConsulta].data && 
                elem['contents'][idConsulta].data.headersMails && 
                this.applyCriterioDates(elem['contents'][idConsulta].data.headersMails[elem['contents'][idConsulta].data.headersMails.length-1].fecha)
            ) || (
                !(elem['contents'][idConsulta] && elem['contents'][idConsulta].data && elem['contents'][idConsulta].data.headersMails) && 
                this.applyCriterioDates(elem['fecha_inicio'])
            )
        );
    }

    applyFilterAndOrderByConsultas = (_array, idConsultas) => {
        const _getDate = (elem) => {
            if (elem['contents'][idConsultas] && 
                elem['contents'][idConsultas].data && 
                elem['contents'][idConsultas].data.headersMails) 
            {
                return new Date(elem['contents'][idConsultas].data.headersMails[elem['contents'][idConsultas].data.headersMails.length-1].fecha).getTime();
            } else return new Date(elem['fecha_inicio']).getTime();
        };

        const filtrados = _array.filter(a => this.checkUser(a) && this.applyCriterioConsultas(a, idConsultas));
        return filtrados.sort((a, b) => {
            const first = _getDate(a);
            const second = _getDate(b);
            return (first > second) ? -1 : ((second > first) ? 1 : 0);
        });
    }
}
