import React, { useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import {
  TextField,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";
import FormDialog from "../../../shared/components/FormDialog";
import HighlightedInformation from "../../../shared/components/HighlightedInformation";
import ButtonCircularProgress from "../../../shared/components/ButtonCircularProgress";
import VisibilityPasswordTextField from "../../../shared/components/VisibilityPasswordTextField";
import Session from "../../../logged_in/session/Session";
import { getPathCaso, getPathPlanificador } from "../../../shared/utils/PathUtils";

const styles = (theme) => ({
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  disabledText: {
    cursor: "auto",
    color: theme.palette.text.disabled,
  },
  formControlLabel: {
    marginRight: 0,
  },
});

function LoginDialog(props) {
  const {
    setStatus,
    history,
    classes,
    onClose,
    openChangePasswordDialog,
    status,
    isSessionExpired,
    setCredentialsLogin,
    loggedUp,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const loginEmail = useRef();
  const loginPassword = useRef();

  const login = () => {
    setIsLoading(true);
    setStatus(null);

    if (isSessionExpired) {
      const args = { 'email': loginEmail.current.value, 'pass': loginPassword.current.value };
      setCredentialsLogin(args);
    } else {
      Session.getInstance().login(loginEmail.current.value, loginPassword.current.value).then((data => {
        if (data === 'EMAIL_NOT_FOUND') {
          setTimeout(() => {
            setStatus("invalidEmail");
            setIsLoading(false);
          }, 1500);
        } else if (data === 'INVALID_PASSWORD') {
          setTimeout(() => {
            setStatus("invalidPassword");
            setIsLoading(false);
          }, 1500);
        } else if (data === 'LOGIN_ERROR') {
          setTimeout(() => {
            setStatus("invalidEmail");
            setIsLoading(false);
          }, 1500);
        } else {
          setTimeout(() => {
            history.push(getPathPlanificador());
          }, 150);
        };
        loggedUp();
      }));
    }
  }

  return (
    <Fragment>
      <FormDialog
        open
        onClose={onClose}
        loading={isLoading}
        onFormSubmit={(e) => {
          e.preventDefault();
          login();
        }}
        hideBackdrop
        headline="Ingreso"
        content={
          <Fragment>
            <TextField
              variant="outlined"
              margin="normal"
              error={status === "invalidEmail"}
              required
              fullWidth
              label="Email"
              inputRef={loginEmail}
              autoFocus
              autoComplete="off"
              type="email"
              //defaultValue = "quasar.legalsolutions@gmail.com"
              defaultValue=""
              onChange={() => {
                if (status === "invalidEmail") {
                  setStatus(null);
                }
              }}
              helperText={
                status === "invalidEmail" &&
                "Este email no está asociado a una cuenta."
              }
              FormHelperTextProps={{ error: true }}
            />
            <VisibilityPasswordTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={status === "invalidPassword"}
              label="Password"
              inputRef={loginPassword}
              autoComplete="off"
              //defaultValue = "123456"
              defaultValue=""
              onChange={() => {
                if (status === "invalidPassword") {
                  setStatus(null);
                }
              }}
              helperText={
                status === "invalidPassword" ? (
                  <span>
                    Password incorrecto. Intentarlo nuevamente, o dar click en{" "}
                    <b>&quot;Olvidé mi Password&quot;</b> para resetear el accesso.
                  </span>
                ) : (
                  ""
                )
              }
              FormHelperTextProps={{ error: true }}
              onVisibilityChange={setIsPasswordVisible}
              isVisible={isPasswordVisible}
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Checkbox color="primary" />}
              label={<Typography variant="body1">Recordarme</Typography>}
            />
            {status === "verificationEmailSend" ? (
              <HighlightedInformation>
                Hemos enviado instrucciones a su casilla de email para resetear su password
              </HighlightedInformation>
            ) : null
              /* (
                <HighlightedInformation>
                  Email: <b>quasar.legalsolutions@gmail.com</b>
                  <br />
                  Password: <b>123456</b>
                </HighlightedInformation>
              ) */
            }
          </Fragment>
        }
        actions={
          <Fragment>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={isLoading}
              size="large"
            >
              Ingresar
              {isLoading && <ButtonCircularProgress />}
            </Button>
            {!isSessionExpired?
            <Typography
              align="center"
              className={classNames(
                classes.forgotPassword,
                isLoading ? classes.disabledText : null
              )}
              color="primary"
              onClick={isLoading ? null : openChangePasswordDialog}
              tabIndex={0}
              role="button"
              onKeyDown={(event) => {
                // For screenreaders listen to space and enter events
                if (
                  (!isLoading && event.keyCode === 13) ||
                  event.keyCode === 32
                ) {
                  openChangePasswordDialog();
                }
              }}
            >
              Olvidé mi Password
            </Typography>
            : null}
          </Fragment>
        }
      />
    </Fragment>
  );
}

LoginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  openChangePasswordDialog: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  status: PropTypes.string,
  isSessionExpired: PropTypes.bool,
  setCredentialsLogin: PropTypes.func,
  loggedUp: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(LoginDialog));
