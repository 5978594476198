import CriterioFiltrado from "./CriterioFiltrado";

export default class CriterioFiltradoContactos extends CriterioFiltrado{
    constructor(loggedUser){
        super();
        this._estaEntidad = 'Contactos';
        this._defaultValue = "";
        this._defaultSelected = this.getAlternativasBusquedaContactos()[0];
        this._defaultCriterio = this._defaultSelected.criterio;
        this._defaultUsers = loggedUser;
        this.setDefaultCriterio();
    }

    
    applyFilterAndOrder = (_contactos) => {
        var filtrados = null;
        if (this._criterio === 'nombre' || this._criterio === 'apellido') {
            return filtrados = this.applyFilterAndOrderByStartsWith(_contactos);
        } else if (this._criterio === 'fecha_inicio') {
            return filtrados = this.applyFilterAndOrderByDate(_contactos);
        } else if (this._criterio === 'actividad') {
            return filtrados = this.applyFilterAndOrderByStartsWith(_contactos);
        } else {
            filtrados = _contactos
        }
        return filtrados;
    };

    getAlternativasBusquedaContactos = () => {
        return [
            { group:'Alfabética', label: 'Apellido o Razón Social', criterio: 'apellido'},
            //{ group:'Alfabética', label: 'Nombre', criterio: 'nombre'},
            //{ group:'Fecha', label: 'Fecha Inicio', criterio: 'fecha_inicio'},
            { group:'Actividad', label: 'Abogado', criterio: 'actividad'},
            { group:'Actividad', label: 'Juez', criterio: 'actividad'},
            { group:'Actividad', label: 'Contador', criterio: 'actividad'},
            { group:'Actividad', label: 'Secretario Juzgado', criterio: 'actividad'},
            { group:'Actividad', label: 'Otro', criterio: 'actividad'},
        ];
    }
}
