import ReactDOM from 'react-dom';
import './index.css';
import React, { Fragment, Suspense, lazy, useState } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import setupAxios from "./quasar-axios"
import DialogSelector from './logged_out/components/register_login/DialogSelector';
import UsersAPI from './shared/api/UsersAPI';
import { CookiesProvider } from "react-cookie";
import { useCookies } from 'react-cookie';
import { getPathRoot } from './shared/utils/PathUtils';
import Session from './logged_in/session/Session';
import { recoveryLoggedUser } from './shared/api/APIConsts';

/* import * as serviceWorker from "./serviceWorker"; */

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

function App() {
  const [loginExpired, setLoginExpired] = useState(false);
  const [credentialsLogin, setCredentialsLogin] = useState(null);

  setupAxios(setLoginExpired);

  if (!!credentialsLogin) {
    UsersAPI.getInstance().signIn(credentialsLogin)
    .then( resp => {
      console.log("ENTRA_:;");
      //handleRecoverySession(credentialsLogin.email, credentialsLogin.pass);
      handleRecoverySession();
      setCredentialsLogin(null);
      setLoginExpired(false);
      setRecoveryDataOK(true);
    })
    .catch( err => {
      console.log(err, "Error en intento de reinicio de sesión");
    })
  }

  const handleRecoverySession = () => {
    localStorage.setItem(recoveryLoggedUser, JSON.stringify(Session.getInstance().getLoggedUser()));
  }

  const [recoveryDataOK, setRecoveryDataOK] = useState(false);


  /* const handleRecoverySession = (email, pass) => {
    localStorage.setItem("recoverySession", JSON.stringify({email: email, pass: pass}));
    handleSessionCookie(email, pass);
  }
  
  const [emailState, setEmail] = useState('');
  const [passState, setPass] = useState('');
  const [cookie, setCookie] = useCookies(['user']);

  const handleSessionCookie = (email, pass) => {
    setEmail(email);
    setPass(pass);
    setCookie('Email', email, { path: '/' });
    setCookie('Pass', pass, { path: '/' });
  }; */

  return (
    /* <React.StrictMode> */  //Anula el Warning: findDOMNode is deprecated in StrictMode.
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Suspense fallback={<Fragment />}>
          <Switch>
            <Route path={getPathRoot()}>
              <Fragment>
                <LoggedInComponent 
                  //sessionCookie = {cookie}
                  setLoginExpired = {setLoginExpired}
                  loginExpired = {loginExpired}
                  recoveryDataOK = {recoveryDataOK}
                />
                { loginExpired?
                  <DialogSelector
                    dialogOpen={"login"}
                    onClose={() => {}}
                    isSessionExpired={true}
                    setCredentialsLogin={setCredentialsLogin}
                    openLoginDialog={null}
                    openTermsDialog={null}
                    openRegisterDialog={null}
                    pushMessageToSnackbar={null}
                    openChangePasswordDialog={null}
                    loggedUp={handleRecoverySession}
                  />
                : null}
              </Fragment>
            </Route>
            <Route>
              <LoggedOutComponent
                loggedUp={handleRecoverySession}
              />
            </Route>
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  /* </React.StrictMode> */
  )
}

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>, 
  document.getElementById('root')
);