import axios from 'axios';
import Session from '../../logged_in/session/Session';
import { getDateAsStrShortFormatES, getDateMinusDays, isDateInMinutesLapse } from '../utils/DateUtils';
import { 
    endPointMiMEV, 
    endPointMisSetsMEV, 
    endPointExpedienteMEV, 
    endPointEtapasMEV, 
    endPointUpdateEtapasMEV, 
    host,

    endPointDoScrapUserMEV,
    endPointDoUpdateUserMEV,
    endPointRegisterMEVUser,
    endPointGetDownloadMEVStatus,
    endPointMEVUserExists,
    endPointGetMEVJurisdictions,
    endPointFetchExpedienteMEV,
    _timeLapseAlertNewPasosProcesales,
} from './APIConsts';

export default class MEVAPI {
    static _instance = null;

    constructor() {
        if (MEVAPI._instance !== null)
            throw new Error('Falla de instanciación: CasosAPI es singleton. Utilizar CasosAPI.getInstance()');
        else
            MEVAPI._instance = this;
    }

    static getInstance = () => {
        if (MEVAPI._instance === null)
            MEVAPI._instance = new MEVAPI();
        return MEVAPI._instance;
    };

    session = Session.getInstance();

    
    getMiMEV = async (criterio, idUser) => {
        const usuario_id = idUser? idUser : this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointMiMEV}?usuario_id=${usuario_id}`)
        .then(expedientesMEV => {
            expedientesMEV.map((expediente, index) => {
                expediente = this._deserializeExpMEVPresenter(expediente);
            });
            this.session.setExpedientesMEV(expedientesMEV);
            return expedientesMEV;
        })
        .catch (err => {
            throw err;
        });
    }


    getMisSetsMEV = async (criterio, idUser) => {
        const usuario_id = idUser? idUser : this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointMisSetsMEV}?usuario_id=${usuario_id}`)
        .then(misSetsMEV => {
            const misSetsMEVDeserialized = this._deserializeMisSetsMEV(misSetsMEV);
            this.session.setMisSetsMEV(misSetsMEVDeserialized);
            return misSetsMEVDeserialized;
        })
        .catch (err => {
            throw err;
        });
    }


    getExpedienteMEV = async (expediente_id, caso_id) => {
        var params = `expediente_id=${expediente_id}`;
        if (caso_id !== undefined) {
            params = params.concat(`&caso_id=${caso_id}`);
        }
        return axios.get(`${host}/${endPointExpedienteMEV}?${params}`)
        .then(expedienteMEV => {
            return this._deserializeFechaPasosProcesalesExpediente(expedienteMEV);
        })
        .catch (err => {
            throw err;
        });
    }
    

    getEtapasMEV = async () => {
        return axios.get(`${host}/${endPointEtapasMEV}`)
        .then(arrayEtapas => {
            const objectEtapas = {};
            arrayEtapas.map(etapa => {
                objectEtapas[etapa] = null;
            })
            return objectEtapas;
        })
        .catch (err => {
            throw err;
        });
    }


    updateEtapasMEV = async (caso_id, expediente_id, etapas) => {
        const edit = this._serializeUpdateEtapas(caso_id, expediente_id, etapas);
        return axios.post(`${host}/${endPointUpdateEtapasMEV}?`, edit)
        .catch (err => {
            throw err;
        });
    };


    getMEVJurisdictions = async () => {
        return axios.get(`${host}/${endPointGetMEVJurisdictions}?`)
        .then(data => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    };
    

    // GET mev_fetch?user_id=[###]&from_date=[DD/MM/YYYY]&until_date=[DD/MM/YYYY]
    scrapUserMEV = async (idUser, from, until) => {
        const user_id = idUser? idUser : this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointDoScrapUserMEV}?user_id=${user_id}`)
        .then(data => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    };


    //GET /fetch_expediente?nidCausa=<str>&pidJuzgado=<str>&email=<str>&set_code=<str>&juri_code=<str>&force_push=[true|false] 
    updateExpedienteMEV = async (nidCausa, pidJuzgado, set_code, juri_code) => {
        return axios.get(`${host}/${endPointFetchExpedienteMEV}?nidCausa=${nidCausa}&pidJuzgado=${pidJuzgado}&set_code=${set_code}&juri_code=${juri_code}&force_push=${true}`)
        .then(data => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    }

    // GET mev_update?user_id=[###]&from_date=[DD/MM/YYYY]&until_date=[DD/MM/YYYY]&jurisdiction=""
    updateUserMEV = async (jurisdiction) => {
        const user_id = this.session.getLoggedUser().id;
        const today = new Date();
        const from = getDateAsStrShortFormatES(getDateMinusDays(today, 2));         // Desde anteayer
        const until = getDateAsStrShortFormatES(new Date());                        // Hasta hoy
        const params = `user_id=${user_id}&from=${from}&until=${until}`;
        if (jurisdiction) {
            params = params.concat(`&jurisdiction=${jurisdiction}`);
        };
        return axios.get(`${host}/${endPointDoUpdateUserMEV}?${params}`)
        .then(data => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    };


    // POST mev_user ; payload: {user_id, mev_user, mev_pwd, overwrite}
    registerMEVUser = async (idUser, mev_user, mev_pwd, overwrite) => {
        const user_id = idUser? idUser : this.session.getLoggedUser().id;
        const credentials = {
            'user_id': user_id, 
            'mev_user': mev_user, 
            'mev_pwd': mev_pwd,
            'overwrite': overwrite,
        };
        return axios.post(`${host}/${endPointRegisterMEVUser}?`, credentials)
        .catch(err => {
            throw new Error(err)
        })
        .catch (err => {
            throw err;
        });
    };

    
    // GET mev_user/exists
    getMEVUser = async (idUser) => {
        const user_id = idUser? idUser : this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointMEVUserExists}?usuario_id=${user_id}`)
        .then(data => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    };


    //  Para chequear el status de bajada: GET expediente_fetch_status con response {enqueued: #, dowloaded: #}
    getDownloadMEVStatus = async (idUser) => {
        const user_id = idUser? idUser : this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointGetDownloadMEVStatus}?usuario_id=${user_id}`)
        .then(data => {
            return data;
        })
        .catch (err => {
            throw err;
        });
    };

    _serializeUpdateEtapas = (caso_id, expediente_id, etapas) => {
        const jsonOut = {
            'caso_id': caso_id,
            'expediente_id': expediente_id,
            'etapa_map': etapas
        };
        return jsonOut;
    }

    _deserializeMisSetsMEV = (sets) => {
        const setsOut = sets.filter(a => a.codig !==null).map(a => a.nombre.trim());
        return setsOut;
    }


    // Calculamos el offset GMT para adicionar al string. La MEV presenta Strings no convertidos, sin sumar esta constante, las fechas de cada paso procesal quedan con un día anterior.
    GMTOffset = new Date().getTimezoneOffset()/60; 
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    _deserializeExpMEVPresenter = (expedienteJson) => {
        expedienteJson.link = expedienteJson.link;
        expedienteJson.hasNewPasosProcesales = isDateInMinutesLapse(expedienteJson.ultimo_paso, _timeLapseAlertNewPasosProcesales);
        
        expedienteJson.fecha = new Date(expedienteJson.fecha_inicio);
        expedienteJson.fecha.setHours(expedienteJson.fecha.getHours() + this.GMTOffset);
        expedienteJson.fecha = expedienteJson.fecha.toISOString();
        
        expedienteJson.ultimo_paso = new Date(expedienteJson.ultimo_paso);
        expedienteJson.ultimo_paso.setHours(expedienteJson.ultimo_paso.getHours() + this.GMTOffset);
        expedienteJson.ultimo_paso = expedienteJson.ultimo_paso.toISOString();
                

        expedienteJson.caratula = expedienteJson.caratula;
        expedienteJson.estado = expedienteJson.estado;
        expedienteJson.numero_expediente = expedienteJson.numero_expediente;
        expedienteJson.set_nombre = expedienteJson.set_nombre.trim();
        expedienteJson.juzgado = expedienteJson.juri_code;

        return expedienteJson;
    }

    _deserializeFechaPasosProcesalesExpediente = (expedienteJson) => {
        expedienteJson.pasos.map(paso => {
            paso.fecha = new Date(paso.fecha);
            paso.fecha.setHours(paso.fecha.getHours() + this.GMTOffset);
            paso.fecha = paso.fecha.toISOString();
        })
        return expedienteJson;
    }
}
