import CriterioFiltrado from "./CriterioFiltrado";

export default class CriterioFiltradoExpedientesMEV extends CriterioFiltrado{
    constructor(loggedUser){ 
        super();
        this._estaEntidad = 'MEV';
        const today = new Date(); 
        const defaultSearchDate = new Date(today.setFullYear(today.getFullYear() - this._timeLapse));

        this._defaultValue = defaultSearchDate;
        this._defaultSelected = this.getAlternativasBusquedaMEV()[4];
        this._defaultCriterio = this._defaultSelected.criterio;
        this._defaultUsers = loggedUser;
        this.setDefaultCriterio();
    }

    applyFilterAndOrder = (_expedientesMEV) => {
        var _expedientesMEVUniqueIds = [];
        _expedientesMEV.map(
            exp => {
                if (_expedientesMEVUniqueIds.filter(filter_exp => exp.id === filter_exp.id).length === 0) {
                    _expedientesMEVUniqueIds.push(exp);
                }
            }
        );
        
        var filtrados = null;
        if (this._criterio === 'estado' || 
            this._criterio === 'numero_expediente' || 
            this._criterio === 'numero_receptoria')
        {
            return filtrados = this.applyFilterAndOrderByStartsWith(_expedientesMEVUniqueIds);
        } else if (this._criterio === 'caratula') {
            return filtrados = this.applyFilterAndOrderByIncludes(_expedientesMEVUniqueIds);
        } else if (this._criterio === 'fecha_inicio' || this._criterio === 'ultimo_paso') {
            return filtrados = this.applyFilterAndOrderByDate(_expedientesMEVUniqueIds);
        } else if (this._criterio === 'set_nombre') {
            return filtrados = this.applyFilterAndOrderByStartsWith(_expedientesMEV);
        } else {
            filtrados = _expedientesMEVUniqueIds
        }
        return filtrados;
    };
    
    getAlternativasBusquedaMEV = (misSetsMEV) => {
        var out = [
            { group:'Alfabética', label: 'Carátula', criterio: 'caratula'},
            { group:'Código', label: 'Número Expediente', criterio: 'numero_expediente'},
            { group:'Código', label: 'Número Receptoría', criterio: 'numero_receptoria'},
            { group:'Fecha', label: 'Fecha Inicio', criterio: 'fecha_inicio'},
            { group:'Fecha', label: 'Fecha Último Paso', criterio: 'ultimo_paso'},
        ];

        if (misSetsMEV) {
            misSetsMEV.forEach((setLabel)=>{
                out.push({ group:'Mis Sets', label: setLabel, criterio: 'set_nombre'})
                //out.push({ group:'Mis Sets', label: setLabel, criterio: 'mis_sets'})
            });
        };

        var tail = [
            { group:'Estado', label: 'En Letra', criterio: 'estado'},
            { group:'Estado', label: 'Paralizada', criterio: 'estado'},
            { group:'Estado', label: 'Archivada', criterio: 'estado'},
            { group:'Estado', label: 'A Despacho', criterio: 'estado'},
            { group:'Estado', label: 'Fuera de Letra - Preparalizado', criterio: 'estado'},
        ];

        return out.concat(tail);
    };
}
