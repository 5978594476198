
import { quasarUser } from "../../shared/api/APIConsts";
import CasosAPI from "../../shared/api/CasosAPI";
import ClientesAPI from "../../shared/api/ClientesAPI";
import ContactosAPI from "../../shared/api/ContactosAPI";
import MEVAPI from "../../shared/api/MEVAPI";
import UsersAPI from "../../shared/api/UsersAPI";

import EstructurasConst from "../components/mensajeria/shared/EstructurasConst";
import CriterioFiltradoCasos from "../components/shared/directorios/CriterioFiltradoCasos";
import CriterioFiltradoContactos from "../components/shared/directorios/CriterioFiltradoContactos";
import CriterioFiltradoClientes from "../components/shared/directorios/CriterioFiltradoClientes";
import CriterioFiltradoExpedientesMEV from "../components/shared/directorios/CriterioFiltradoExpedientesMEV";


class DataSession {
   
    _loggedUser = {
        'estudio': null, 
        'estudio_id': null, 
        'email': null,
        'telefono': null,
        'nombre': null, 
        'apellido': null, 
        'id': null,
        'estudio_name': null,
        'legajo': null,
        'matricula': null,
        'cuit': null,
        'iva': null,
        'last_login': null,
        'permisos': null,
        'roles': null,
        _loggedEstudio: {
            'ciudad': null,
            'código postal': null,
            'dirección': null,
            'telefono_estudio': null,
            'email_estudio': null,
        }
    };

    _quasarUsers = [];
    _users = {};

    _casos = null;
    _contactos = null;
    _clientes = null;
    _expedientesMEV = null;
    _misSetsMEV = null;
    _mails = null;
    _mailsCountCasesClients = null;

    _criterioBusquedaCasos = null;
    _criterioBusquedaContactos = null;
    _criterioBusquedaClientes = null;
    _criterioBusquedaMEV = null;

    _roles = null;
        
   
    constructor() {
    }

    initializeCriterios = () => {
        const defaultUser = this._loggedUser;
        //const defaultUser = Object.values(this._users)[0]; // Pruebas para evitar que un usuario no pueda generar casos ni clientes bajo su responsabilidad
        this._criterioBusquedaCasos = new CriterioFiltradoCasos(defaultUser);
        this._criterioBusquedaContactos = new CriterioFiltradoContactos(defaultUser);
        this._criterioBusquedaClientes = new CriterioFiltradoClientes(defaultUser);
        this._criterioBusquedaMEV = new CriterioFiltradoExpedientesMEV(defaultUser);
    }

    // Roles y Permisos
    isRoleDisabled = (r) => {
        var out = false;
        switch (r){
            case "Honorarios":
                if (this._loggedUser.roles) {
                    //out = ['Dependiente', 'Junior'].some((role)=>this._loggedUser.roles.includes(role));
                    // ['Dependiente', 'Junior']
                    out = this._roles.slice(2).some((role)=>this._loggedUser.roles.includes(role));
                }
                break;
            case "Papelera":
                if (this._loggedUser.roles) {
                    out = this._roles.slice(2).some((role)=>this._loggedUser.roles.includes(role));
                }
                break;
            case "Subscripción":
                if (this._loggedUser.roles) {
                    //out = ['Asociado', 'Dependiente', 'Junior'].some((role)=>this._loggedUser.roles.includes(role));
                    // ['Asociado', 'Dependiente', 'Junior']
                    out = this._roles.slice(1).some((role)=>this._loggedUser.roles.includes(role));
                }
                break;
            case "Administrador":
                if (this._loggedUser.roles) {
                    //out = ['Dependiente', 'Junior'].some((role)=>this._loggedUser.roles.includes(role));
                    // ['Dependiente', 'Junior']
                    out = this._roles.slice(2).some((role)=>this._loggedUser.roles.includes(role));
                }
                break;
            default:
                out = false;
        }
        return out;
    }

    // Setters
    setLoggedUser = async (u) => {
        Object.keys(u).map(key => {
            if (key === 'ciudad' || key === 'código postal' || key === 'dirección' || key === 'telefono_estudio' || key === 'email_estudio') {
                this._loggedUser._loggedEstudio[key] = u[key];
            } else {
                this._loggedUser[key] = u[key];
            }
        })
        
        this.initializeCriterios();
        this._roles = await UsersAPI.getInstance().getRoles();
    }

    setQuasarUsers = (us) => {
        return this._quasarUsers = us
    }
    setUsers = (us) => {
        //delete us[this._loggedUser.email]; // Pruebas para evitar que un usuario no pueda generar casos ni clientes bajo su responsabilidad
        return this._users = us
    }
    setCasos = (casos) => {return this._casos = casos}
    setContactos = (contactos) => {
        return this._contactos = contactos
    }
    setClientes = (clientes) => {return this._clientes = clientes}
    setExpedientesMEV = (mevs) => {return this._expedientesMEV = mevs}
    setMisSetsMEV = (sets) => {return this._misSetsMEV = sets}
    setMails = (mails) => {return this._mails = mails}


    setCriterioBusquedaCasos = async (criterio, nuevoCliente) => {
        this._criterioBusquedaCasos.setCriterio(criterio);
        if (nuevoCliente)
            await ClientesAPI.getInstance().getEstudioClients();
    }
    
    setCriterioBusquedaContactos = async (criterio) => {
        this._criterioBusquedaContactos.setCriterio(criterio);
    }

    setCriterioBusquedaClientes = async (criterio) => {
        this._criterioBusquedaClientes.setCriterio(criterio);
    }
    
    setCriterioBusquedaMEV = async (criterio) => {
        this._criterioBusquedaMEV.setCriterio(criterio);
    }
    
    setMailsCountCasesClients = (c) => {
        this._mailsCountCasesClients = c;
    }

    _updateCasosClientesCountUnreadMails = (entity, c, count) => {
        var countObject = '';
        if (entity === 'casos') {
            countObject = 'casosCount';
        } else { // 'clientes'
            countObject = 'clientesCount';
        };
        
        if (this._mailsCountCasesClients[entity].has(c)) {
            this._mailsCountCasesClients[countObject][c] = this._mailsCountCasesClients[countObject][c]+count;
        } else {
            this._mailsCountCasesClients[entity].add(c);
            this._mailsCountCasesClients[countObject][c] = count;
        }

        if (this._mailsCountCasesClients[countObject][c] < 1) {
            this._mailsCountCasesClients[entity].delete(c);
            delete this._mailsCountCasesClients[countObject][c];
        }
        console.log("UPDATE COUNT: ---------------------------------------", this._mailsCountCasesClients);
    }

    updateCasosCountUnreadMails = (c, count) => {
        this._updateCasosClientesCountUnreadMails('casos', c, count);
    };

    updateClientesCountUnreadMails = (c, count) => {
        this._updateCasosClientesCountUnreadMails('clientes', c, count);
    };

    // Getters
    getLoggedUser = () => {return this._loggedUser}

    getUsers = () => {
        return this._users
    }

    getUserById = (id) => { 
        var recipientContact = null;
        if (quasarUser.id === id) {
            recipientContact = quasarUser;
        } else {
            recipientContact = Object.values(this.getUsers()).filter( user => user.id === id)[0];
        }
        
        var contactName = `Usuario No. ${id}`;
        try { // Session.getInstance().getUserById sólo lee users del estudio, en caso de consulta a QUASAR precisamos un endpoint específico
            contactName = `${recipientContact.nombre} ${recipientContact.apellido}`;
        } catch { 
            //Entro acá pq no encontré el sender del mensaje, se asume que es un usuario de otro estudio y q yo soy el superusuario quasar
            this._quasarUsers.some(estudio => {
                return Object.entries(estudio.users).some(([email, user]) => {
                    if (user.id === id) {
                        contactName = `${user.nombre} ${user.apellido} (${email})`;
                        return true;
                    }
                    return false;
                });
            })
        }

        return contactName;
    }

    _hasCasoClienteUnreadMails = (entity, id) => {
        if (this._mailsCountCasesClients[entity].has(id)) {
            if (entity === 'casos') {
                return this._mailsCountCasesClients.casosCount[id];
            } else {
                return this._mailsCountCasesClients.clientesCount[id];
            }
        } else {
            return 0;
        }
    }

    hasCasoUnreadMails = (caso) => {
        return this._hasCasoClienteUnreadMails('casos', caso);
    }

    hasClienteUnreadMails = (cli) => {
        return this._hasCasoClienteUnreadMails('clientes', cli);
    }

    getCriterioBusquedaCasos = () => {return this._criterioBusquedaCasos.getCriterio()}
    getAlternativasBusquedaCasos = () => {return this._criterioBusquedaCasos.getAlternativasBusquedaCasos()}
        
    getCasos = () => {
        const filtrados = this._criterioBusquedaCasos.applyFilterAndOrder(this._casos);
        return filtrados;
    }

    getCasosBackEnd = async () => {
        this._criterioBusquedaCasos.setDefaultCriterio();
        await CasosAPI.getInstance().getEstudioCasos(true);
        return this.getCasos();
    }

    getUnfilteredCasos = () => {
        return this._casos;
    }

    getCriterioBusquedaContactos = () => {return this._criterioBusquedaContactos.getCriterio()}
    getAlternativasBusquedaContactos = () => {return this._criterioBusquedaContactos.getAlternativasBusquedaContactos()}
        
    getContactos = () => {
        const filtrados = this._criterioBusquedaContactos.applyFilterAndOrder(this._contactos);
        return filtrados;
    }

    getContactosBackEnd = async () => {
        this._criterioBusquedaContactos.setDefaultCriterio();
        await ContactosAPI.getInstance().getEstudioContactos(true);
        return this.getContactos();
    }

    getUnfilteredContactos = () => {
        return this._contactos;
    }
    
    getCriterioBusquedaClientes = () => {return this._criterioBusquedaClientes.getCriterio()}
    getAlternativasBusquedaClientes = () => {return this._criterioBusquedaClientes.getAlternativasBusquedaClientes()}
        
    getClientes = () => {
        const filtrados = this._criterioBusquedaClientes.applyFilterAndOrder(this._clientes);
        return filtrados;
    }

    getClientesBackEnd = async () => {
        this._criterioBusquedaClientes.setDefaultCriterio();
        await ClientesAPI.getInstance().getEstudioClients(true);
        return this.getClientes();
    }

    getUnfilteredClientes = () => {
        return this._clientes;
    }

    getCriterioBusquedaMEV = () => {return this._criterioBusquedaMEV.getCriterio()}
    getAlternativasBusquedaMEV = () => {return this._criterioBusquedaMEV.getAlternativasBusquedaMEV(this._misSetsMEV)}
    
    getExpedientesMEV = () => {
        const filtrados = this._criterioBusquedaMEV.applyFilterAndOrder(this._expedientesMEV);
        return filtrados;
    }
    
    getExpedientesMEVBackEnd = async () => {
        this._criterioBusquedaMEV.setDefaultCriterio();
        await MEVAPI.getInstance().getMiMEV(this._criterioBusquedaMEV.getCriterio());
        await MEVAPI.getInstance().getMisSetsMEV(this._criterioBusquedaMEV.getCriterio());
        return this.getExpedientesMEV();
    }

    getExpedientesMEVBackEndChangedUser = async (idUser) => {
        await MEVAPI.getInstance().getMiMEV(this._criterioBusquedaMEV.getCriterio(), idUser);
        await MEVAPI.getInstance().getMisSetsMEV(this._criterioBusquedaMEV.getCriterio(), idUser);
        return this.getExpedientesMEV();
    }

    getExpedientesMEVBackEndChangedUserUnfiltered = async (idUser) => {
        await MEVAPI.getInstance().getMiMEV(this._criterioBusquedaMEV.getCriterio(), idUser);
        await MEVAPI.getInstance().getMisSetsMEV(this._criterioBusquedaMEV.getCriterio(), idUser);
        return this._expedientesMEV;
    }

    getMisSetsMEV = () => {return this._misSetsMEV}

    getMails = () => {return this._mails}

    getMailsUnreadNumber = () => {
        return this._mails[EstructurasConst.idMailsRecibidos]['data']?
            this._mails[EstructurasConst.idMailsRecibidos]['data']['unReadMails']
        :
            0;
    }
}

export default DataSession;
