
import Session from '../../logged_in/session/Session';
import {storage} from '../../firebase.config';
import TokensAPI from './TokensAPI';

export default class FileManagerAPI {
    static _instance = null;

    constructor() {
        if (FileManagerAPI._instance !== null)
            throw new Error('Falla de instanciación: FileManagerAPI es singleton. Utilizar FileManagerAPI.getInstance()');
        else
            FileManagerAPI._instance = this;
    }

    static getInstance = () => {
        if (FileManagerAPI._instance === null)
            FileManagerAPI._instance = new FileManagerAPI();
        return FileManagerAPI._instance;
    };

    session = Session.getInstance();
    
    getAbsoluteLink = async (link) => {
        if (typeof link !== 'string' || link.slice(0,4) === 'http') {
            return link;
        };
        const indexDirectory = link.lastIndexOf("/");
        const fileName = link.slice(indexDirectory+1);
        const directory = link.slice(0, indexDirectory);
        return await storage.ref(directory).child(fileName).getDownloadURL();
    };

    _uploadFile = async (directory, attachment) => {
        const setSuffix = (name) => {
            var suffix = require("uuid").v4();
            return `${name}_${suffix}`;
        };

        if (attachment == null)
            return;
        
        var fileName = "";
        var fileNameOK = false;
        
        while (!fileNameOK) {
            fileName = setSuffix(attachment.name);
            try{
                const previousURL = await storage.ref(directory).child(fileName).getDownloadURL();
            } catch (err) {
                fileNameOK = true;
            }
        }

        const relativeLink = `${directory}/${fileName}`;
        await storage.ref(relativeLink).put(attachment);
        return relativeLink;
        // return await storage.ref(directory).child(fileName).getDownloadURL(); // Con este return se almacenaban los links absolutos --- Changed!
    }


    // --------------------------------------------------------------------------------------------------
    // La idSeccion corresponde a Mensajeria, Clientes, Casos
    // Si idSeccion es Clientes o Casos entonces
    //      La idDBSeccion corresponde al id del Cliente o Caso correspondiente
    //      La idEstructura corresponde al tab secundario donde se adjuntará el attachment en el Cliente o Caso
    // Sino tanto idDBSeccion como idEstructura se asumen null
    // --------------------------------------------------------------------------------------------------
    _getDirectory = (idEstudio, idUsuario, idSeccion, idDBSeccion, idEstructura) => {
        var directory = `/attachments/Estudio/${idEstudio}/Usuario/${idUsuario}/${idSeccion}`;
        directory = idSeccion==='Mensajeria'? directory : directory.concat(`/${idDBSeccion}/${idEstructura}`);
        return directory;
    }

    _checkToken = async (idSeccion, idDBSeccion, idEstructura) => {
        if (idSeccion==='Casos') {
            await TokensAPI.getInstance().isActiveTokenCaso(idDBSeccion, idEstructura)
            .then(isActiveToken => {
                return isActiveToken;
            })
            .catch (err => {
                throw err;
            });
        } else if (idSeccion==='Clientes') {
            await TokensAPI.getInstance().isActiveTokenCliente(idDBSeccion, idEstructura)
            .then(isActiveToken => {
                return isActiveToken;
            })
            .catch (err => {
                throw err;
            });
        } else return true;
    }

    attachmentsPersistence = async (adjuntos, links, idEstudio, idUsuario, idSeccion, idDBSeccion, idEstructura) => {
        return await this._checkToken(idSeccion, idDBSeccion, idEstructura)
        .then( async isActiveToken => {
            const directory = this._getDirectory(idEstudio, idUsuario, idSeccion, idDBSeccion, idEstructura);
            const attachments = [];
            if (adjuntos !== undefined && adjuntos.length>0) {
                for (var i = 0; i < adjuntos.length; i++) {
                    const obj = {
                        'filename': adjuntos[i].texto, 
                        'link': 
                            links[i].link!==undefined? // Si esta condicion es válida el documento ya estaba previamente almacenado en el storage
                                links[i].link
                            : 
                                await this._uploadFile(directory, links[i])
                    };
                    attachments.push(obj);
                }
            }

            return attachments;
        })
    };
}
