import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC6koel2DDIWnBfef5gAXX7n1M0Q5odl98",
    authDomain: "atomlab-2b3a6.firebaseapp.com",
    projectId: "atomlab-2b3a6",
    storageBucket: "atomlab-2b3a6.appspot.com",
    messagingSenderId: "1072856299966",
    appId: "1:1072856299966:web:a620801c06dbeb0bbda1ef",
    measurementId: "G-50TF9HRWPD"
  };
  
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export var storage = firebase.storage();

export default db;
