import Session from "../../logged_in/session/Session";

export const isLocalhost = () => {
  let isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  
  // Uncomment to force call to cloud functions while in localhost
  isLocal = false;
  if (isLocal) {
    console.log('Localhost detected!');
  }
  return isLocal;
}

const isHeroku = true;

export const host = isLocalhost() ?
  'http://localhost:5000' :
  !isHeroku ? 'https://us-central1-atomlab-2b3a6.cloudfunctions.net' : 'https://quasarlex-backend.herokuapp.com';

export const hostHeroku = isLocalhost() ? 'http://localhost:5000' : 'https://quasarlex-backend.herokuapp.com';
export const USE_AUTH0 = true;

// Session
export const accessTokenStoreId = 'actkn';
export const refreshTokenStoreId = 'rfstkn';
export const recoveryLoggedUser = "recoveryLoggedUser";
export const pendingBrowserAction = "pendingBrowserAction";

// Register of PDFs EndPoints
export const endPointHTML2PDFConvert = 'make_pdf';


// Register of Tokens EndPoints
export const endPointRequestToken = 'write_token';
export const endPointReleaseToken = 'write_token/release';
export const endPointCheckToken = 'write_token/status';

// Register of Users EndPoints
export const endpointNewEstudio = 'estudio';
export const endPointRegister = 'users';
export const endPointSignIn = 'sign_in';
export const endPointSignInAuth0 = 'sign_in_auth0';
export const endPointLogout = 'logout';
export const endPointUsers = 'associated_users';
export const endpointUpdateUser = 'users';
export const endpointUpdateUserDetails = 'users_details';
export const endpointUpdateEstudioDetails = 'estudio_details';
export const endpointRoles = 'roles';
export const endpointResetPass = 'reset_password';
export const endPointQuasarUsers = 'estudio';
export const endpointRefreshToken = 'refresh_token';
export const backOfficeEmail = 'quasar.legalsolutions@gmail.com';
export const quasarUser =
{
  email: backOfficeEmail,
  id: 2,
  active: true,
  nombre: "Quasar",
  apellido: " - Consultas"
};

export const amISuperUser = () => {
  return Session.getInstance().getLoggedUser().id === quasarUser.id;
}

// Register of Cases EndPoints
export const endPointDeleteCase = 'case';
export const endPointCaseDetails = 'case_details';
export const endPointCases = 'get_estudio_cases';
export const endPointCasesContacto = 'estudio_cases_contacto';
export const endPointVincularCasoMEV = 'caso_expediente';
export const endPointTrashCases = 'deleted_cases';
export const endPointRestoreCase = 'restore_case';


// Register of Clients EndPoints
//export const endPointClients = 'get_estudio_clients'; // 'clients'
export const endPointClients = 'clients';
export const endPointEditClient = 'edit_client';
export const endPointTrashClients = 'deleted_clients';
export const endPointRestoreClient = 'restore_client';


// Register of Contactos EndPoints
export const endPointContactos = 'contactos';
export const endPointTrashContactos = 'deleted_contactos';
export const endPointRestoreContacto = 'restore_contacto';


// Register of MEV EndPoints
export const endPointMiMEV = 'expedientes'; //'get_MiMEV';
export const endPointMisSetsMEV = 'sets_mev'; //'get_MisSetsMEV';
export const endPointExpedienteMEV = 'expediente'; //'get_ExpedienteMEV';
export const endPointEtapasMEV = 'mev_etapas';
export const endPointUpdateEtapasMEV = 'etapa_expediente_caso';
export const endPointDoScrapUserMEV = 'mev_fetch';
export const endPointDoUpdateUserMEV = 'mev_update';
export const endPointRegisterMEVUser = 'mev_user';
export const endPointMEVUserExists = 'mev_user/exists';
export const endPointGetDownloadMEVStatus = 'expediente_fetch_status';
export const endPointGetMEVJurisdictions = 'mev_jurisdictions';
export const endPointFetchExpedienteMEV = 'fetch_expediente';
// Constant que indica los minutos correspondientes a una ventana de tiempo de 7 dias.
// (lapso que se considera para remarcar a un paso procesal como nuevo)
export const _timeLapseAlertNewPasosProcesales = 60 * 24 * 7;


// Register of Mensajeria EndPoints
export const endPointMensajeria = 'messages';
export const endPointMessagesLink = 'messages_link';
export const endPointMessagesRead = 'messages_read';
export const endPointForceExternalMessagesRead = 'force_inbox_reader';
export const endPointForceExternalMessagesReadStatus = 'job_status';


// Register of Events endpoints
export const endpointEvents = 'events';

// Register of Honorarios EndPoints
//export const endPointHonorarios = 'get_user_honorarios';
//export const endPointHonorariosDetails = 'honorarios_details';
export const endPointHonorariosCaso = 'get_balance_caso';
export const endPointHonorariosCliente = 'get_balance_cliente';
export const endPointHonorariosUserPorTarea = 'get_honorarios_por_tarea';
export const endPointHonorariosUserPorAbono = 'get_honorarios_por_abono';

// Register of Chat EndPoints
export const endPointChat_msgs = 'chat_msgs';

// Register of Plantillas EndPoints
export const endPointPlantillas = 'plantillas';
export const endPointGetTiposEscritos = 'get_tipos_escritos';
export const endPointGetTemplate = 'get_template';

// Register of Modelos EndPoints
export const endPointModelos = 'modelos';
export const endPointGetClasesModelos = 'get_modelos_escritos';
export const endPointGetModelo = 'get_modelo';
export const endPointModelosPorPath = 'modelos_filtered_from_path';

// Register of Clases EndPoints
export const endPointClases = 'clases';
export const endPointClaseFromPath = 'clase_modelo_from_path';

// Register of Planificador EndPoints
export const endPointPlanificador = 'planificador';
