import axios from 'axios';

import { serializeDateAsJsonUTC } from '../utils/DateUtils'

import Session from '../../logged_in/session/Session';
import {
    endPointClients,
    endPointEditClient,
    endPointRestoreClient,
    endPointTrashClients,
    host
} from './APIConsts';
import EstructurasConst from '../../logged_in/components/clientes/shared/EstructurasConst';
import MensajeriaAPI from './MensajeriaAPI';
import CasosAPI from './CasosAPI';
import HonorariosAPI from './HonorariosAPI';


export default class ClientesAPI {
    static _instance = null;

    constructor() {
        if (ClientesAPI._instance !== null)
            throw new Error('Falla de instanciación: ClientesAPI es singleton. Utilizar ClientesAPI.getInstance()');
        else
            ClientesAPI._instance = this;
    }

    static getInstance = () => {
        if (ClientesAPI._instance === null)
            ClientesAPI._instance = new ClientesAPI();
        return ClientesAPI._instance;
    };

    session = Session.getInstance();


    getEstudioClients = async (cache_it, email) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const creador = email ? email : null;
        const params = email ? `estudio_id=${estudio_id}&creador=${creador}` : `estudio_id=${estudio_id}`;
        
        return axios.get(`${host}/${endPointClients}?${params}`)
        .then(clientes => {
            const clientesOut = [];
            clientes.map((cli) => {
                clientesOut.push(this._deserializeClientePresenter(cli));
            });
            if (cache_it)
                this.session.setClientes(clientesOut);
            return clientesOut;
        })
        .catch (err => {
            throw err;
        });
    }


    getCliente = async (estudio_id, cliente_id) => {
        return axios.get(`${host}/${endPointClients}?estudio_id=${estudio_id}&cliente_id=${cliente_id}`)
        .then(cliente => {
            return this._deserializeCliente(cliente, estudio_id, cliente_id);
        })
        .catch (err => {
            throw err;
        });
    }

    
    getSection = async (estudio_id, cliente_id, idSection) => {
        return axios.get(`${host}/${endPointClients}?estudio_id=${estudio_id}&cliente_id=${cliente_id}`)
        .then(cliente => {
            return this._deserializeSection(cliente, estudio_id, cliente_id, idSection);
        })
        .catch (err => {
            throw err;
        });
    }


    addCliente = async (creador, estructura) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;

        const newCliente = this._serializeNewCliente(creador.id, estructura);
        return axios.post(`${host}/${endPointClients}?`, newCliente)
        .then(cliente => {
            return this._deserializeCliente(cliente, estudio_id, cliente.id);
        })
        .catch (err => {
            throw err;
        });
    }


    editCliente = async (estudio_id, cliente_id, idEstructura, data) => {
        const user_id = this.session.getLoggedUser().id;
        const edit = this._serializeEstructuraCliente(estudio_id, user_id, cliente_id, idEstructura, data);
        return axios.put(`${host}/${endPointClients}?`, edit)
        .then(cliente => {
            return this._deserializeClienteEdited(cliente, estudio_id, cliente.id, data, idEstructura);
        })
        .catch (err => {
            throw err;
        });
    }


    changeOwnershipCliente = async (cliente_id, creador_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = 
            {
                "estudio_id": estudio_id,
                "cliente_id": cliente_id, 
                "creador_id": creador_id
            };
        
        return axios.put(`${host}/${endPointEditClient}?`, edit)
        .catch (err => {
            throw err;
        });
    }


    deleteCliente = async (cliente_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const data = 
            {
                "estudio_id": estudio_id,
                "cliente_id": cliente_id, 
            };
        
        return axios.delete(`${host}/${endPointClients}?`, data)
        .catch (err => {
            throw err;
        });
    }


    getPapelera = async () => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        return axios.get(`${host}/${endPointTrashClients}?estudio_id=${estudio_id}`)
        .then(clientes => {
            return this._deserializePapelera(clientes);
        })
        .catch (err => {
            throw err;
        });
    }


    restoreCliente = async (cliente_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = {
            "cliente_id": cliente_id,
            "estudio_id": estudio_id
        }
        return axios.put(`${host}/${endPointRestoreClient}?`, edit)
        .then(data => {
            return data
        })
        .catch (err => {
            throw err;
        });
    }

    _deserializeClienteEdited = async (clienteJson, estudio_id, cliente_id, clienteOriginal, idEstructura) => {
        clienteJson = await this._deserializeCliente(clienteJson, estudio_id, cliente_id);
        const idEditActual = idEstructura[0]; // Guarda, se asume que el primero del array corresponde con la sección que el usuario está editando

        Object.keys(clienteJson).map(key => {
            if (key !== idEditActual) {
                clienteOriginal[[key]] = clienteJson[[key]];
            }
        });

        return clienteOriginal;
    }


    _serializeNewCliente = (creador, estructura) => {
        return {
            'creador_id': creador,
            'data': estructura
        };
    }


    _serializeEstructuraCliente = (estudio_id, user_id, cliente_id, idEstructura, data) => {
        const date = new Date();

        const payload = {};
        idEstructura.map(
            (id) => {
                payload[id] = data[id]
            }
        );

        return {
            'cliente_id': cliente_id,
            'estudio_id': estudio_id,
            'usuario_id': user_id,
            'key': idEstructura,
            'data': payload,
            'timestamp': serializeDateAsJsonUTC(date)
        }
    }

    checkDBUpgrade = async (clienteJson, estudio_id, cliente_id, idSection) => {
        if (idSection === EstructurasConst.idConsultas || idSection === EstructurasConst.idHonorariosPactados || idSection === EstructurasConst.idFacturacion || idSection === EstructurasConst.idBalance) {
            // Cualquiera de estas secciones requiere chequear si la DB tiene una versión anterior y además el user tiene permiso de grabación para que la actualización sea persistente en el BE
            await this._checkHardcodingUpgradeDB(clienteJson, estudio_id, cliente_id, idSection)
        }
    }

    _deserializeSection = async (clienteJson, estudio_id, cliente_id, idSection) => {
        const creador_id = clienteJson.creador_id;
        const id = clienteJson.id;
        clienteJson = clienteJson['contents'];
        clienteJson.creador_id = creador_id;
        clienteJson.id = id;

        clienteJson.nombre = clienteJson[EstructurasConst.idDatosCliente].nombre;
        clienteJson.apellido = clienteJson[EstructurasConst.idDatosCliente].apellido;
        clienteJson.fecha_inicio = clienteJson[EstructurasConst.idDatosCliente].fecha_inicio;
        
        switch (idSection) {
            case EstructurasConst.idCasos:
                clienteJson[EstructurasConst.idCasos] = await CasosAPI.getInstance().getEstudioCasos(false, cliente_id);
                break;
            case EstructurasConst.idBalance:
                clienteJson[EstructurasConst.idBalance] = await HonorariosAPI.getInstance().getHonorariosCliente(cliente_id);
                break;
            case EstructurasConst.idClaseMensajeria:
                clienteJson[EstructurasConst.idClaseMensajeria] = await MensajeriaAPI.getInstance().getMensajesCliente(estudio_id, cliente_id);
                break;
            case EstructurasConst.idAgendaCliente:
                clienteJson[EstructurasConst.idAgendaCliente] = {};
                break;
            /* 
            case EstructurasConst.idConsultas: // Hardcoding para bases viejas
                if (clienteJson[EstructurasConst.idConsultas] && clienteJson[EstructurasConst.idConsultas].data) {
                    clienteJson[EstructurasConst.idConsultas].data.headersMails.map((consulta, index) => {
                        if (!consulta.honorarios) {
                            consulta.honorarios = consulta.porAbono === "SI" ? "Por Abono" : consulta.monto;
                        };
                        if (!consulta.attachs) { // Este atributo debería quedar persistente en las BDs
                            consulta.attachs = clienteJson[EstructurasConst.idConsultas].data.contentsMails[index].links.length > 0;
                        }
                    });
                };
                break;
            */
            case EstructurasConst.idHonorariosPactados: 
                if (clienteJson[EstructurasConst.idHonorariosPactados] && clienteJson[EstructurasConst.idHonorariosPactados].data) {
                    clienteJson[EstructurasConst.idHonorariosPactados].data.map((hPac, index) => {
                        try{
                            hPac.concepto = clienteJson[EstructurasConst.idConsultas].data.headersMails[hPac.idTarea].concepto;
                            hPac.facturado = clienteJson[EstructurasConst.idConsultas].data.headersMails[hPac.idTarea].facturado;
                        } catch {}
                    });
                }
                break;
            case EstructurasConst.idHonorariosAbono: 
                if (clienteJson[EstructurasConst.idHonorariosAbono] && clienteJson[EstructurasConst.idHonorariosAbono].data) {
                    clienteJson[EstructurasConst.idHonorariosAbono].data.map((hAbono, index) => {
                        try{
                            hAbono.facturado = 
                                clienteJson[EstructurasConst.idFacturacion].data
                                .filter(factura => factura.arrayIdsAbonos)
                                .filter(factura => !(!!factura.inTrash))
                                .filter(factura => factura.arrayIdsAbonos.includes(hAbono.id)).length > 0 ?
                                    "SI" 
                                : 
                                    "NO";
                        } catch {}
                    });
                }
                break;
            case EstructurasConst.idFacturacion: 
                this._deserealizeFacturacion(clienteJson);
                break; 
            case EstructurasConst.idRecientes:
                clienteJson[EstructurasConst.idRecientes] && clienteJson[EstructurasConst.idRecientes].data && 
                clienteJson[EstructurasConst.idRecientes].data.map((row, index) => {
                    row['i'] = index;
                    row['id'] = index;
                });
                break;
        };
        return clienteJson[idSection];
    }

    _deserealizeFacturacion = (clienteJson) => {
        if (clienteJson[EstructurasConst.idFacturacion] && clienteJson[EstructurasConst.idFacturacion].data) {
            clienteJson[EstructurasConst.idFacturacion].data.map((factura, index) => {
                try{ // cargo datos de las tareas correspondientes
                    factura.attachs = factura.comprobante !== undefined;
                    factura.monto = 0;
                    factura.concepto = '';
                    if (factura.arrayIdsAbonos) {
                        factura.arrayIdsAbonos.map(idAbono => {
                            factura.tipo = "Honorarios Abonos";
                            factura.concepto = factura.concepto + clienteJson[EstructurasConst.idHonorariosAbono].data[idAbono].concepto + ', ';
                            factura.monto = factura.monto + clienteJson[EstructurasConst.idHonorariosAbono].data[idAbono].monto;
                        });
                    } else {
                        factura.arrayIdsTareas.map(idTarea => {
                            factura.tipo = "Honorarios Consultas/Tareas";
                            factura.concepto = factura.concepto + clienteJson[EstructurasConst.idConsultas].data.headersMails[idTarea].concepto + ', ';
                            factura.monto = factura.monto + clienteJson[EstructurasConst.idConsultas].data.headersMails[idTarea].monto;
                        });
                    }
                    factura.concepto = factura.concepto.slice(0,-2);
                }
                catch{
                }
            });
        }
    }

    _deserializeCliente = async (clienteJson, estudio_id, cliente_id) => {
        const idRecientes = EstructurasConst.idRecientes;

        const creador_id = clienteJson.creador_id;
        const id = clienteJson.id;
        clienteJson = clienteJson['contents'];
        clienteJson.creador_id = creador_id;
        clienteJson.id = id;

        clienteJson.nombre = clienteJson[EstructurasConst.idDatosCliente].nombre;
        clienteJson.apellido = clienteJson[EstructurasConst.idDatosCliente].apellido;
        clienteJson.fecha_inicio = clienteJson[EstructurasConst.idDatosCliente].fecha_inicio;
        this._deserealizeFacturacion(clienteJson);

        clienteJson[EstructurasConst.idCasos] = await CasosAPI.getInstance().getEstudioCasos(false, cliente_id);
        clienteJson[EstructurasConst.idBalance] = await HonorariosAPI.getInstance().getHonorariosCliente(cliente_id);
        clienteJson[EstructurasConst.idClaseMensajeria] = await MensajeriaAPI.getInstance().getMensajesCliente(estudio_id, cliente_id);
        clienteJson[EstructurasConst.idAgendaCliente] = {};  // Dejando la agenda del cliente vacía, los navbuttons inferiores de la estructuraCasacara lo reconocen. 
        // No se hace el fetch acá (como el caso de la mensajería) para dejar que se haga en Agenda.js con cada click del tab.

        /* try{
        if (clienteJson[EstructurasConst.idHonorariosPactados] && clienteJson[EstructurasConst.idHonorariosPactados].data) {
            clienteJson[EstructurasConst.idHonorariosPactados].data.map((hPac, index) => {
                hPac.concepto = clienteJson[EstructurasConst.idConsultas].data.headersMails[hPac.idTarea].concepto;
                hPac.facturado = clienteJson[EstructurasConst.idConsultas].data.headersMails[hPac.idTarea].facturado;
            });
        }
        */
                
        clienteJson[idRecientes] && clienteJson[idRecientes].data && clienteJson[idRecientes].data.map((row, index) => {
            row['i'] = index;
            row['id'] = index;
        });

        return clienteJson;
    }

    _checkHardcodingUpgradeDB = async (clienteJson, estudio_id, cliente_id, idSection) => {
        var persistenceRequired = false;
        const handlePersistenceRequired = () => {
            persistenceRequired = true;
        }
        
        const checkUpgradeRequired = () => {
            var isRequired = false;

            const isLackingFields = (obj, idSect) => {
                const objKeys = Object.keys(obj);

                const requiredFieldsConsultas = [
                    'attachs', 
                    'concepto',
                    'facturado',
                    'fecha',
                    'honorarios',
                    'i',
                    'id',
                    'idFacturacion',
                    'idHonorariosPactados', // Será -1 si porAbono === 'SI'
                    'monto',
                    'pagado',
                    'porAbono'
                ];

                const requiredFieldsHonorarios = [
                    'i',
                    'id',
                    'idTarea',
                    'monto'
                ];

                const requiredFieldsFacturacion = [
                    'i',
                    'id',
                    //'arrayIdsTareas',
                    'fecha'
                ];

                switch (idSect) {
                    case EstructurasConst.idConsultas:
                        return requiredFieldsConsultas.some(field => !objKeys.includes(field));
                    case EstructurasConst.idHonorariosPactados:
                        return requiredFieldsHonorarios.some(field => !objKeys.includes(field));
                    case EstructurasConst.idFacturacion:
                        const out = requiredFieldsFacturacion.some(field => !objKeys.includes(field)) || (!objKeys.includes('arrayIdsTareas') && !objKeys.includes('arrayIdsAbonos'));
                        return out;
                }
                return false;
            }

            if (clienteJson[EstructurasConst.idConsultas] && clienteJson[EstructurasConst.idConsultas].data) {
                isRequired = isRequired || clienteJson[EstructurasConst.idConsultas].data.headersMails.filter(consulta => isLackingFields(consulta, EstructurasConst.idConsultas)).length > 0
            }

            if (!isRequired && clienteJson[EstructurasConst.idHonorariosPactados] && clienteJson[EstructurasConst.idHonorariosPactados].data) {
                isRequired = isRequired || clienteJson[EstructurasConst.idHonorariosPactados].data.filter(hPac => isLackingFields(hPac, EstructurasConst.idHonorariosPactados)).length > 0
            }
        
            if (!isRequired && clienteJson[EstructurasConst.idFacturacion] && clienteJson[EstructurasConst.idFacturacion].data) {
                return clienteJson[EstructurasConst.idFacturacion].data.filter(factura => isLackingFields(factura, EstructurasConst.idFacturacion)).length > 0
            }

            return isRequired;
        }

        // Hardcoding para incorporar honorarios --- NO ES NECESARIO PARA TODO LO POSTERIOR AL 14/03/2023 (previamente al 16/03/2022)
        if (clienteJson[EstructurasConst.idConsultas] && clienteJson[EstructurasConst.idConsultas].data) {
            const newHonorarios = [];
            const newFacturacion = [];

            // Si hay alguna consulta que no corre por abono y no tiene el id del honorario entonces tenemos DB desactualizada
            if (checkUpgradeRequired() || clienteJson[EstructurasConst.idConsultas].data.headersMails.filter(consulta => consulta.porAbono !== "SI" && consulta.idHonorariosPactados === undefined).length > 0) {
                clienteJson[EstructurasConst.idConsultas].data.headersMails.map((consulta, index) => {
                    if (consulta.idHonorariosPactados === undefined) {
                        handlePersistenceRequired();
                        consulta.idHonorariosPactados = -1;
                    };
                    if (consulta.idFacturacion === undefined) {
                        handlePersistenceRequired();
                        consulta.idFacturacion = -1;
                    };
                    if (consulta.honorarios === undefined) {
                        handlePersistenceRequired();
                        consulta.honorarios = consulta.porAbono === "SI" ? "Por Abono" : consulta.monto;
                    };
                    if (consulta.attachs === undefined) { // Este atributo debería quedar persistente en las BDs
                        handlePersistenceRequired();
                        consulta.attachs = clienteJson[EstructurasConst.idConsultas].data.contentsMails[index].links.length > 0;
                    };
                    if (consulta.porAbono !== "SI") {
                        const idHonorarios = newHonorarios.length;
                        consulta.idHonorariosPactados = idHonorarios;
                        newHonorarios.push({
                            i: idHonorarios,
                            id: idHonorarios,
                            idTarea: consulta.id,
                            monto: consulta.monto, // Necesario para que funquen los endpoints de balance y honorarios
                            fecha: consulta.fecha // Necesario para que funquen los endpoints de balance y honorarios
                        });
                    };
                    if (consulta.facturado === "SI") {
                        const idFactura = newFacturacion.length;
                        consulta.idFacturacion = idFactura;
                        newFacturacion.push({
                            i: idFactura,
                            id: idFactura,
                            arrayIdsTareas: [consulta.id],
                            fecha: consulta.fecha
                        });
                    };
                });
                if (newHonorarios.length > 0) {
                    handlePersistenceRequired();
                    clienteJson[EstructurasConst.idHonorariosPactados] = {
                        data: newHonorarios
                    };
                }
                if (newFacturacion.length > 0) {
                    handlePersistenceRequired();
                    clienteJson[EstructurasConst.idFacturacion] = {
                        data: newFacturacion
                    };
                }
            }
        };
        
        if (persistenceRequired) {
            var arrayIdSections = [EstructurasConst.idConsultas, EstructurasConst.idHonorariosPactados, EstructurasConst.idFacturacion];
            if (idSection !== EstructurasConst.idBalance) {
                arrayIdSections = arrayIdSections.filter(sect => sect !== idSection);
                arrayIdSections = [idSection].concat(arrayIdSections);
            }
            return await this.editCliente(estudio_id, cliente_id, arrayIdSections, clienteJson)
            .then(() => {
                return ;
            });
        } else {
            return ;
        }
    }


    _deserializeClientePresenter = (clienteJson) => {
        const clienteOut = clienteJson;
        clienteOut.hasUnreadMails = this.session.hasClienteUnreadMails(clienteJson.id);
        clienteOut.id = clienteJson.id;
        clienteOut.creador_id = clienteJson.creador_id;
        clienteOut.nombre = clienteJson['contents'][EstructurasConst.idDatosCliente] ? clienteJson['contents'][EstructurasConst.idDatosCliente].nombre : 'NN';
        clienteOut.apellido = clienteJson['contents'][EstructurasConst.idDatosCliente] ? clienteJson['contents'][EstructurasConst.idDatosCliente].apellido : 'NN';
        clienteOut.email = clienteJson['contents'][EstructurasConst.idDatosCliente] && clienteJson['contents'][EstructurasConst.idDatosCliente].email ?
            clienteJson['contents'][EstructurasConst.idDatosCliente].email :
            'N/A';
        clienteOut.fecha_inicio =
            clienteJson['contents'][EstructurasConst.idDatosCliente] ?
                clienteJson['contents'][EstructurasConst.idDatosCliente].fecha_inicio
                :
                serializeDateAsJsonUTC(new Date());
        return clienteOut;
    }

    _deserializePapelera = (clientes) => {
        clientes.map((cliente,index) => {
            cliente['cliente_id'] = cliente.id;
            cliente['cliente'] = `${cliente.contents[EstructurasConst.idDatosCliente].nombre} ${cliente.contents[EstructurasConst.idDatosCliente].apellido}`;
            cliente['actividad'] = cliente.contents[EstructurasConst.idDatosCliente].actividad;
            cliente['email'] = cliente.contents[EstructurasConst.idDatosCliente].email;
            cliente['fecha_inicio'] = cliente.contents[EstructurasConst.idDatosCliente].fecha_inicio;
            cliente['id'] = index;
            cliente['i'] = index;
        });
        return clientes;
    }
}
