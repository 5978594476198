import axios from 'axios';

import { serializeDateAsJsonUTC, deserializeDateStrLocalGmt, isDateInMinutesLapse } from '../utils/DateUtils'

import { 
    endPointCaseDetails, 
    endPointCases, 
    endPointCasesContacto,
    endPointVincularCasoMEV, 
    endPointDeleteCase, 
    endPointTrashCases,
    endPointRestoreCase,
    host,
    _timeLapseAlertNewPasosProcesales, 
} from './APIConsts';

import Session from '../../logged_in/session/Session';

import EstructurasConst from '../../logged_in/components/casos/shared/EstructurasConst';
import EstructurasConstCliente from '../../logged_in/components/clientes/shared/EstructurasConst';
import ClientesAPI from './ClientesAPI';
import MensajeriaAPI from './MensajeriaAPI';
import HonorariosAPI from './HonorariosAPI';
import MEVAPI from './MEVAPI';
import EscritosPlantillasAPI from './EscritosPlantillasAPI';

export default class CasosAPI {
    static _instance = null;

    constructor() {
        if (CasosAPI._instance !== null)
            throw new Error('Falla de instanciación: CasosAPI es singleton. Utilizar CasosAPI.getInstance()');
        else
            CasosAPI._instance = this;
    }

    static getInstance = () => {
        if (CasosAPI._instance === null)
            CasosAPI._instance = new CasosAPI();
        return CasosAPI._instance;
    };

    session = Session.getInstance();

    
    getEstudioCasos = async (cache_it, cliente_id) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const params = cliente_id===undefined?`estudio_id=${estudio_id}`: `estudio_id=${estudio_id}&cliente_id=${cliente_id}`;
        
        return axios.get(`${host}/${endPointCases}?${params}`)
        .then(casos => {
            casos.map((caso) => {
                caso = this._deserializeCasoPresenter(caso);
            });
            if (cache_it)
                this.session.setCasos(casos);
            return casos;
        })
        .catch (err => {
            throw err;
        });
    }

    getEstudioCasosContacto = async (contacto_id) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const params = `estudio_id=${estudio_id}&contacto_id=${contacto_id}`;
        
        return axios.get(`${host}/${endPointCasesContacto}?${params}`)
        .then(casos => {
            casos.map((caso) => {
                caso = this._deserializeCasoPresenter(caso);
            });
            
            return casos;
        })
        .catch (err => {
            throw err;
        });
    }

    getCaso = async (estudio_id, caso_id) => {
        return axios.get(`${host}/${endPointCaseDetails}?estudio_id=${estudio_id}&case_id=${caso_id}`)
        .then(caso => {
            return this._deserializeCaso(caso, estudio_id, caso_id);
        })
        .catch (err => {
            throw err;
        });
    }


    getSection = async (estudio_id, caso_id, idSection) => {
        return axios.get(`${host}/${endPointCaseDetails}?estudio_id=${estudio_id}&case_id=${caso_id}`)
        .then(caso => {
            return this._deserializeSection(caso, estudio_id, caso_id, idSection);
        })
        .catch (err => {
            throw err;
        });
    }

    
    addCaso = async (creador, cliente_id, titulo, motivo, fecha_inicio, representacion, representante_contrario_id, estructura) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
                
        if (!cliente_id) { // No viene cliente_id => primero creamos el Cliente
            const estructuraCli = {...EstructurasConstCliente.estructuraVacia};
            const idDatosCliente = EstructurasConstCliente.idDatosCliente;
            estructuraCli[idDatosCliente] = {...estructura[EstructurasConst.idDatosCliente]};
            delete estructuraCli[idDatosCliente].titulo;
            delete estructuraCli[idDatosCliente].motivo;
            cliente_id = await ClientesAPI.getInstance().addCliente(creador, estructuraCli)
            .then(async newCli => {
                await Session.getInstance().getClientesBackEnd()
                return await newCli.id
            });
        };

        const newCaso = this._serializeNewCaso(estudio_id, creador.id, cliente_id, titulo, motivo, fecha_inicio, representacion, representante_contrario_id, estructura); 
        return axios.post(`${host}/${endPointCaseDetails}?`, newCaso)
        .then(caso => {
            return caso;
        })
        .catch (err => {
            throw err;
        });
    }


    editCaso = async (estudio_id, caso_id, idEstructura, data) => {
        const user_id = this.session.getLoggedUser().id;
        const edit = this._serializeEstructuraCaso(estudio_id, user_id, caso_id, idEstructura, data);
        return axios.put(`${host}/${endPointCaseDetails}?`, edit)
        .then(caso => {
            return this._deserializeCasoEdited(caso, estudio_id, caso.id, data, idEstructura);
        })
        .catch (err => {
            throw err;
        });
    }


    vincularCasoMEV = async (caso_id, expediente_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = 
            {
                "caso_id": caso_id, 
                "expediente_id": expediente_id
            };
        
        return axios.put(`${host}/${endPointVincularCasoMEV}?estudio_id=${estudio_id}`, edit)
        .catch (err => {
            throw err;
        });
    }

    desvincularCasoMEV = async (caso_id) => {
        return await this.vincularCasoMEV(caso_id, null)
        .catch (err => {
            throw err;
        });
    }
    

    changeOwnershipCaso = async (caso_id, creador_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = 
            {
                "estudio_id": estudio_id,
                "caso_id": caso_id, 
                "creador_id": creador_id
            };
        
        return axios.put(`${host}/${endPointCaseDetails}?`, edit)
        .catch (err => {
            throw err;
        });
    }


    changeContactoCaso = async (caso_id, contact_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = 
            {
                "estudio_id": estudio_id,
                "caso_id": caso_id, 
                "contacto_id": contact_id
            };
        
        return axios.put(`${host}/${endPointCaseDetails}?`, edit)
        .catch (err => {
            throw err;
        });
    }


    changeClientCaso = async (caso_id, client_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = 
            {
                "estudio_id": estudio_id,
                "caso_id": caso_id, 
                "cliente_id": client_id
            };
        
        return axios.put(`${host}/${endPointCaseDetails}?`, edit)
        .catch (err => {
            throw err;
        });
    }


    deleteCaso = async (caso_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const data = 
            {
                "estudio_id": estudio_id,
                "caso_id": caso_id, 
            };
        
        return axios.delete(`${host}/${endPointDeleteCase}?`, data)
        .catch (err => {
            throw err;
        });
    }
    

    getPapelera = async (papeleraClientes) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        return axios.get(`${host}/${endPointTrashCases}?estudio_id=${estudio_id}`)
        .then(casos => {
            return this._deserializePapelera(casos, papeleraClientes);
        })
        .catch (err => {
            throw err;
        });
    }
    

    restoreCaso = async (caso_id) => {
        const estudio_id = Session.getInstance().getLoggedUser().estudio_id;
        const edit = {
            "caso_id": caso_id,
            "estudio_id": estudio_id
        }
        return axios.put(`${host}/${endPointRestoreCase}?`, edit)
        .then(data => {
            return data
        })
        .catch (err => {
            throw err;
        });
    }

    _serializeNewCaso = (estudio_id, creador_id, cliente_id, titulo, motivo, fecha_inicio, representacion, representante_contrario_id, estructura) => {
        return {
            'estudio_id': estudio_id,
            'creador_id': creador_id,
            'cliente_id': cliente_id,
            'titulo': titulo,
            'motivo': motivo,
            'fecha_inicio': serializeDateAsJsonUTC(fecha_inicio), 
            'representacion': representacion, 
            'representante_contrario_id': !!representante_contrario_id?representante_contrario_id: null, 
            'estructura': estructura,
        }
    }

    _serializeEstructuraCaso = (estudio_id, user_id, caso_id, arrayIdsEstructuras, data) => {
        const dataSerialized = {};
        arrayIdsEstructuras.map(
            (idEstructura) => {
                dataSerialized[idEstructura] = data[idEstructura]
            }
        );
        return {
            'estudio_id': estudio_id,
            'usuario_id': user_id,
            'caso_id': caso_id,
            'key': arrayIdsEstructuras, 
            'data': dataSerialized,
            'timestamp': serializeDateAsJsonUTC(new Date())
        }
    }

    _deserializeSection = async (casoJson, estudio_id, caso_id, idSection) => {
        const idDatos = EstructurasConst.idDatosCliente;
        const idBalanceCaso = EstructurasConst.idBalance;
        const idClaseMensajeria = EstructurasConst.idClaseMensajeria;
        const idAgendaCaso = EstructurasConst.idAgendaCaso;
        const idExpedienteMEV = EstructurasConst.idExpedienteMEV;
        const idDatosExpediente = EstructurasConst.idDatosExpediente;
        const idRecientes = EstructurasConst.idRecientes;

        casoJson.titulo = decodeURIComponent(casoJson.titulo);
        casoJson.id = caso_id;

        switch (idSection) {
            case idDatos: 
                casoJson[idDatos].titulo = casoJson.titulo;
                casoJson[idDatos].motivo = casoJson.motivo;
                casoJson[idDatos].fecha_inicio = casoJson.fecha_inicio;
                casoJson[idDatos].representante = Session.getInstance().getUserString(casoJson.creador_id);
                casoJson[idDatos].representante_contrario = Session.getInstance().getContactoString(casoJson.representante_contrario_id, "Sin vincular");
                casoJson[idDatos].representacion = casoJson.representacion;
                
                // Hardcoding para que tengamos un default para datos viejos de las BDs cuando no teníamos el tipo
                casoJson[idDatos].tipo = casoJson[idDatos].tipo? casoJson[idDatos].tipo : 'Persona Física'; 
                casoJson[idDatos].representacion = casoJson[idDatos].representacion? casoJson[idDatos].representacion : 'Actor';
                break;
            case idBalanceCaso:
                casoJson[idBalanceCaso] = await HonorariosAPI.getInstance().getHonorariosCaso(caso_id);
                break;
            case idClaseMensajeria:
                casoJson[idClaseMensajeria] = await MensajeriaAPI.getInstance().getMensajesCaso(estudio_id, caso_id);
                break;
            case idAgendaCaso:
                casoJson[idAgendaCaso] = {};
                break;
            case idExpedienteMEV:
                if (!!casoJson.expediente) {
                    casoJson[idExpedienteMEV].id = casoJson.expediente;
                    await MEVAPI.getInstance().getExpedienteMEV(casoJson[idExpedienteMEV].id, caso_id)
                    .then((expediente) => {
                        casoJson[idExpedienteMEV].expediente = expediente.expediente;
                        casoJson[idExpedienteMEV].etapas = expediente.etapas;
                        casoJson[idExpedienteMEV].pasos = expediente.pasos;

                        casoJson[idDatosExpediente] = {
                            // Estos ids se deben corresponder con el objecto declarado en EstructurasConst.datosExpediente
                            'caratula': casoJson[idExpedienteMEV].caratula,
                            'nro_exp': casoJson[idExpedienteMEV].numero_expediente,
                            'nro_receptoria': casoJson[idExpedienteMEV].numero_receptoria,
                            'juzgado': casoJson[idExpedienteMEV].juri_code,
                            'fecha_ini': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].fecha_inicio),
                            'fecha_paso': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].ultimo_paso),
                        }
                    });
                } else {
                    casoJson[idDatosExpediente] = {};
                };
                break;
            case idDatosExpediente:
                if (casoJson.expediente!==null) {
                    casoJson[idExpedienteMEV].id = casoJson.expediente;
                    await MEVAPI.getInstance().getMEVJurisdictions()
                    .then((juris) => {
                        const jurisdiction = juris.filter(juri => juri.id === casoJson[idExpedienteMEV].juri_code);
                        casoJson[idDatosExpediente] = {
                            // Estos ids se deben corresponder con el objecto declarado en EstructurasConst.datosExpediente
                            'caratula': casoJson[idExpedienteMEV].caratula,
                            'nro_exp': casoJson[idExpedienteMEV].numero_expediente,
                            'nro_receptoria': casoJson[idExpedienteMEV].numero_receptoria,
                            'juzgado': jurisdiction.length>0?jurisdiction[0].nombre: '',
                            'fecha_ini': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].fecha_inicio),
                            'fecha_paso': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].ultimo_paso),
                        }
                    })
                } else {
                    casoJson[idDatosExpediente] = {};
                };
                break;
            /* case EstructurasConst.idFacturacion:
                if (casoJson[EstructurasConst.idFacturacion] && casoJson[EstructurasConst.idFacturacion].data) {
                    casoJson[EstructurasConst.idFacturacion].data.map((factura, index) => {
                        factura.attachs = factura.comprobante !== undefined;
                    });
                }
                break; */
            case EstructurasConst.idFacturacion: 
                this._deserializeFacturacion(casoJson);
                break; 
            case idRecientes:
                casoJson[idRecientes] && casoJson[idRecientes].data && casoJson[idRecientes].data.map((row, index) => {
                    row['i'] = index;
                    row['id'] = index;
                });
                break;
        }
        return casoJson[idSection];
    }

    _deserializeFacturacion = (casoJson) => {
        if (casoJson[EstructurasConst.idFacturacion] && casoJson[EstructurasConst.idFacturacion].data) {
            casoJson[EstructurasConst.idFacturacion].data.map((factura, index) => {
                try{ // cargo datos de las facturaciones
                    factura.attachs = factura.comprobante !== undefined;
                    factura.monto = 0;
                    factura.concepto = '';
                    var objetoFacturable;
                    var arrayIds;
                    if (factura.arrayIdsInstancias) {
                        factura.tipo = "Honorarios Pactados";
                        objetoFacturable = casoJson[EstructurasConst.idHonorariosPactados].instancias;
                        arrayIds = factura.arrayIdsInstancias;
                    } else if (factura.arrayIdsRegulaciones) {
                        factura.tipo = "Regulaciones y Costas";
                        objetoFacturable = casoJson[EstructurasConst.idRegulacionesCostas].data;
                        arrayIds = factura.arrayIdsRegulaciones;
                    } else if (factura.arrayIdsAdicionales) {
                        factura.tipo = "Tareas Adicionales";
                        objetoFacturable = casoJson[EstructurasConst.idTareas].data;
                        arrayIds = factura.arrayIdsAdicionales;
                    }
                    arrayIds.map(id => {
                        factura.concepto = factura.concepto + objetoFacturable[id].concepto + ', ';
                        factura.monto = factura.monto + Number(objetoFacturable[id].monto);
                    });
                    factura.concepto = factura.concepto.slice(0,-2);
                }
                catch{
                }
            });
        }
    }

    _deserializeCaso = async (casoJson, estudio_id, caso_id) => {
        const idClaseMensajeria = EstructurasConst.idClaseMensajeria;
        const idAgendaCaso = EstructurasConst.idAgendaCaso;
        const idBalanceCaso = EstructurasConst.idBalance;
        const idExpedienteMEV = EstructurasConst.idExpedienteMEV;
        const idDatosExpediente = EstructurasConst.idDatosExpediente;
        const idDatos = EstructurasConst.idDatosCliente;
        const idTiposEscritos = EstructurasConst.idTiposEscritos;
        const idRecientes = EstructurasConst.idRecientes;
        
        
        casoJson.titulo = decodeURIComponent(casoJson.titulo);
        casoJson.id = caso_id;
        
        if (!(!!(casoJson[idDatos]))) {
            casoJson[idDatos] = {}
        }

        casoJson[idDatos].titulo = casoJson.titulo;
        casoJson[idDatos].motivo = casoJson.motivo;
        casoJson[idDatos].fecha_inicio = casoJson.fecha_inicio;
        casoJson[idDatos].representante = Session.getInstance().getUserString(casoJson.creador_id);
        casoJson[idDatos].representante_contrario = Session.getInstance().getContactoString(casoJson.representante_contrario_id, "Sin vincular");
        casoJson[idDatos].representacion = casoJson.representacion;
        
        // Hardcoding para que tengamos un default para datos viejos de las BDs cuando no teníamos el tipo
        casoJson[idDatos].tipo = casoJson[idDatos].tipo? casoJson[idDatos].tipo : 'Persona Física'; 
        casoJson[idDatos].representacion = casoJson[idDatos].representacion? casoJson[idDatos].representacion : 'Actor';

        casoJson[idTiposEscritos] = await EscritosPlantillasAPI.getInstance().getTiposEscritos();
        
        
        
        if (!!casoJson.expediente) {
            casoJson[idExpedienteMEV].id = casoJson.expediente;
            await MEVAPI.getInstance().getExpedienteMEV(casoJson[idExpedienteMEV].id, caso_id)
            .then((expediente) => {
                casoJson[idExpedienteMEV].expediente = expediente.expediente;
                casoJson[idExpedienteMEV].etapas = expediente.etapas;
                casoJson[idExpedienteMEV].pasos = expediente.pasos;

                casoJson[idDatosExpediente] = {
                    // Estos ids se deben corresponder con el objecto declarado en EstructurasConst.datosExpediente
                    'caratula': casoJson[idExpedienteMEV].caratula,
                    'nro_exp': casoJson[idExpedienteMEV].numero_expediente,
                    'nro_receptoria': casoJson[idExpedienteMEV].numero_receptoria,
                    'juzgado': casoJson[idExpedienteMEV].juri_code,
                    'fecha_ini': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].fecha_inicio),
                    'fecha_paso': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].ultimo_paso),
                }
            });
        } else {
            casoJson[idDatosExpediente] = {};
        };
                
        /* if (casoJson.expediente!==null) {
            casoJson[idExpedienteMEV].id = casoJson.expediente;
        
            casoJson[idDatosExpediente] = {
                // Estos ids se deben corresponder con el objecto declarado en EstructurasConst.datosExpediente
                'caratula': casoJson[idExpedienteMEV].caratula,
                'nro_exp': casoJson[idExpedienteMEV].numero_expediente,
                'nro_receptoria': casoJson[idExpedienteMEV].numero_receptoria,
                'juzgado': casoJson[idExpedienteMEV].juzgado,
                'fecha_ini': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].fecha_inicio),
                'fecha_paso': deserializeDateStrLocalGmt(casoJson[idExpedienteMEV].ultimo_paso),
            }
        } else {
            casoJson[idDatosExpediente] = {};
        } */

        this._deserializeFacturacion(casoJson);
        
        casoJson[idRecientes] && casoJson[idRecientes].data && casoJson[idRecientes].data.map((row, index) => {
            row['i'] = index;
            row['id'] = index;
        });

        casoJson[idClaseMensajeria] = await MensajeriaAPI.getInstance().getMensajesCaso(estudio_id, caso_id);
        casoJson[idBalanceCaso] = await HonorariosAPI.getInstance().getHonorariosCaso(caso_id);
        casoJson[idAgendaCaso] = {};    // Dejando la agenda del caso vacía, los navbuttons inferiores de la estructuraCasacara lo reconocen. 
                                        // No se hace el fetch acá (como el caso de la mensajería) para dejar que se haga en Agenda.js con cada click del tab.
        
        return casoJson;
    }

    _deserializeCasoEdited = async (casoJson, estudio_id, caso_id, casoOriginal, idEstructura) => {
        casoJson['contents'].cliente_id = casoJson.cliente_id;
        casoJson['contents'].creador_id = casoJson.creador_id;
        casoJson['contents'].expediente_id = casoJson.expediente_id;
        casoJson['contents'].fecha_inicio = casoJson.fecha_inicio;
        casoJson['contents'].id = casoJson.id;
        casoJson['contents'].motivo = casoJson.motivo;
        casoJson['contents'].titulo = casoJson.titulo;
        casoJson['contents'].representacion = casoJson.representacion;
        casoJson['contents'].representante_contrario_id = casoJson.representante_contrario_id;
        
        
        //casoJson['contents'][idDatos] = ESTA VIENIENDO VACIO?
        casoJson = await this._deserializeCaso(casoJson['contents'], estudio_id, caso_id);
                
        const idEditActual = idEstructura[0]; // Guarda, se asume que el primero del array corresponde con la sección que el usuario está editando

        Object.keys(casoJson).map(key => {
            if (key !== idEditActual) {
                casoOriginal[[key]] = casoJson[[key]];
            }
        });

        return casoOriginal;
    }
    
    
    _deserializeCasoPresenter = (casoJson) => {
        casoJson.hasUnreadMails = this.session.hasCasoUnreadMails(casoJson.id);

        var motivoPlain = casoJson.motivo?casoJson.motivo:"";
        motivoPlain = motivoPlain.replace(/<style([\s\S]*?)<\/style>/gi, '');
        motivoPlain = motivoPlain.replace(/<script([\s\S]*?)<\/script>/gi, '');
        motivoPlain = motivoPlain.replace(/<\/div>/ig, '\n');
        motivoPlain = motivoPlain.replace(/<\/li>/ig, '\n');
        motivoPlain = motivoPlain.replace(/<li>/ig, '  *  ');
        motivoPlain = motivoPlain.replace(/<\/ul>/ig, '\n');
        motivoPlain = motivoPlain.replace(/<\/p>/ig, '\n');
        motivoPlain = motivoPlain.replace(/<br\s*[\/]?>/gi, "\n");
        motivoPlain = motivoPlain.replace(/<[^>]+>/ig, '');

        
        casoJson.id = casoJson.id;
        casoJson.src = casoJson.id;
        casoJson.timestamp = deserializeDateStrLocalGmt(casoJson.fecha_inicio);
        casoJson.name = decodeURIComponent(casoJson.titulo);
        casoJson.caratula = casoJson.caratula;
        casoJson.motivo = motivoPlain;
        casoJson.clienteId = casoJson.cliente_id;
        casoJson.hasNewPasosProcesales = isDateInMinutesLapse(casoJson.ultimo_paso, _timeLapseAlertNewPasosProcesales);
        return casoJson;
    }

    _deserializePapelera = (casos, papeleraClientes) => {
        casos.map((caso,index) => {
            const filtro = papeleraClientes.filter(cliente => cliente.cliente_id === caso.cliente_id);
            if (filtro.length > 0) {
                caso['cliente_inTrash'] = 
                    `${filtro[0].contents[EstructurasConstCliente.idDatosCliente].nombre} ${filtro[0].contents[EstructurasConstCliente.idDatosCliente].apellido}`;
            } else {
                caso['cliente_inTrash'] = null
            }

            caso['caso_id'] = caso.id;
            caso['id'] = index;
            caso['i'] = index;
        });
        return casos;
    }
}
