import { format } from 'date-fns';

/**
 * Funciones de utilidad para operaciones con fechas
 */
export const DATE_FORMAT = 'dd/MM/yyyy';
export const MONTH_FORMAT = 'yyyyMM';
export const NUMERIC_DATE_FORMAT = 'yyyyMMdd';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const TIME_FORMAT = 'HH:mm';

export const isValidDate = (date) => date instanceof Date && !isNaN(date);
export const equalsDate = (dateA, dateB) => dateA >= dateB && dateA <= dateB;
export const stripTime = (date) => new Date(date.toDateString());
export const getMonthAsStr = (date) => format(date, MONTH_FORMAT);
export const getDateAsStr = (date) => format(date, NUMERIC_DATE_FORMAT);
export const serializeDateAsJsonUTC = (date) => date.toJSON();
export const deserializeDateStrLocalGmt = (dateStr) => new Date(dateStr);
export const getDateAsStrShortFormatES = (date) => {
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
};
export const getTimeAsStrShortFormatES = (date) => {
    return date.toLocaleTimeString();
};
export const getDateTimeAsStrShortFormatES = (date) => {
    return getDateAsStrShortFormatES(date) + ' ' + date.toLocaleTimeString();
};
export const getDateAsStrVeryShortFormatES = (date) => {
    return date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'});
};
export const getDateTimeAsStrVeryShortFormatES = (date) => {
    return date.toLocaleDateString('en-GB', {day: '2-digit', month: '2-digit'}) + ' - ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
};
export const getDateMinusDays = (date, days) => {
    var dateOut = new Date(date);
    dateOut.setDate(date.getDate()-days);
    return dateOut;
}

export const isDateOutdated = (jsonDate, days) => {
    if (jsonDate !== undefined) {
        const now = new Date();
        const date = new Date(jsonDate);
        return now.getTime() - date.getTime() > (days * 24 * 60 * 60 * 1000);
    };
    return false;
}

export const getMonthYearAsShortStr = (date) => {
    const formatter = new Intl.DateTimeFormat('es', { month: 'short' });
    return formatter.format(date) + ' ' + date.getFullYear();
}

/* 
Si ahora es anterior a la fecha de ingreso (jsonDate) + mins => el lapso de tiempo (para un token) aún es válido. Es decir, jsonDate + mins aún es futuro
- jsonDate es un date que viene de las BDs, está en el formato resultante de this.serializeDateAsJsonUTC
- mins es la cantidad de minutos del lapso, el tiempo de validez del token
*/
export const isDateInMinutesLapse = (jsonDate, mins) => {
    if (jsonDate !== undefined) {
        const now = new Date();
        const date = new Date(jsonDate);
        return now.getTime() < date.getTime() + (mins * 60 * 1000);
    };
    return false;
}
