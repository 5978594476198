import CriterioFiltrado from "./CriterioFiltrado";
import EstructurasConst from "../../casos/shared/EstructurasConst";

export default class CriterioFiltradoCasos extends CriterioFiltrado{

    constructor(loggedUser){
        super();
        this._estaEntidad = 'Casos';
        const today = new Date(); 
        const defaultSearchDate = new Date(today.setFullYear(today.getFullYear() - this._timeLapse));

        this._defaultValue = defaultSearchDate;
        this._defaultSelected = this.getAlternativasBusquedaCasos()[4];
        this._defaultCriterio = this._defaultSelected.criterio;
        this._defaultUsers = loggedUser;
        this.setDefaultCriterio();
    }


    applyFilterAndOrder = (_casos) => {
        var filtrados = null;
        if (this._criterio === 'name' || this._criterio === 'caratula') {
            return filtrados = this.applyFilterAndOrderByStartsWith(_casos);
        } else if (this._criterio === 'motivo') {
            return filtrados = this.applyFilterAndOrderByIncludes(_casos);
        } else if (this._criterio === 'ultima_modificacion') {
            return filtrados = this.applyFilterAndOrderByRecientes(_casos, EstructurasConst.idRecientes);
        } else if (this._criterio === 'fecha_inicio') {
            return filtrados = this.applyFilterAndOrderByDate(_casos);
        } else {
            filtrados = _casos
        }
        return filtrados;
    };

    getAlternativasBusquedaCasos = () => {
        return [
            { group:'Alfabética', label: 'Título', criterio: 'name'},
            { group:'Alfabética', label: 'Carátula', criterio: 'caratula'},
            { group:'Alfabética', label: 'Motivo', criterio: 'motivo'},
            
            { group:'Fecha', label: 'Fecha Inicio', criterio: 'fecha_inicio'},
            { group:'Fecha', label: 'Última Modificación', criterio: 'ultima_modificacion'},
        ];
    }
}
