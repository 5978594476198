import ContactosAPI from "../../../../shared/api/ContactosAPI";
import ClientesAPI from "../../../../shared/api/ClientesAPI";
import CasosAPI from "../../../../shared/api/CasosAPI";
import FileManagerAPI from "../../../../shared/api/FileManagerAPI";
import Session from "../../../session/Session";
import TokensAPI from "../../../../shared/api/TokensAPI";


// idSeccion: "Clientes" o "Casos"
// idEntity: cliente.id o caso.id
const handlePersistenceAttachments = async (adjuntos, links, idSeccion, idEntity, idEstructura) => {
    const loggedUser = Session.getInstance().getLoggedUser();
    return await FileManagerAPI.getInstance()
        .attachmentsPersistence(
            adjuntos, 
            links, 
            loggedUser.estudio_id, 
            loggedUser.id, 
            idSeccion,
            idEntity, 
            idEstructura
        )
        .catch (err => {
            throw err;
        });
};


export const handlePersistenceClientesAttachments = async (adjuntos, links, idCliente, idEstructura) => {
    return await handlePersistenceAttachments(adjuntos, links, "Clientes", idCliente, idEstructura)
    .catch (err => {
        throw err;
    });
}

export const handlePersistenceCasosAttachments = async (adjuntos, links, idCaso, idEstructura) => {
    return await handlePersistenceAttachments(adjuntos, links, "Casos", idCaso, idEstructura)
    .catch (err => {
        throw err;
    });
}

export const handlePersistenceContactos = async (arrayIdsEstructuras, contacto) => {
    const loggedUser = Session.getInstance().getLoggedUser();
    await ContactosAPI.getInstance().editContacto(loggedUser.estudio_id, contacto.id, arrayIdsEstructuras, contacto)
    .catch (err => {
        throw err;
    });
};

export const handlePersistenceClientes = async (arrayIdsEstructuras, cliente) => {
    const loggedUser = Session.getInstance().getLoggedUser();
    await ClientesAPI.getInstance().editCliente(loggedUser.estudio_id, cliente.id, arrayIdsEstructuras, cliente)
    .catch (err => {
        throw err;
    });
};

export const handlePersistenceCasos = async (arrayIdsEstructuras, caso) => {
    const loggedUser = Session.getInstance().getLoggedUser();
    await CasosAPI.getInstance().editCaso(loggedUser.estudio_id, caso.id, arrayIdsEstructuras, caso)
    .catch (err => {
        throw err;
    });
};

export const requestTokenContacto = async (contacto, idSeccion, flexibleTokenization) => {
    return await TokensAPI.getInstance().requestTokenContacto(contacto.id, idSeccion, flexibleTokenization)
    .catch (err => {
        throw err;
    });
};

export const requestTokenCliente = async (cliente, idSeccion, flexibleTokenization) => {
    return await TokensAPI.getInstance().requestTokenCliente(cliente.id, idSeccion, flexibleTokenization)
    .catch (err => {
        throw err;
    });
};

export const requestTokenCaso = async (caso, idSeccion, flexibleTokenization) => {
    return await TokensAPI.getInstance().requestTokenCaso(caso.id, idSeccion, flexibleTokenization)
    .catch (err => {
        throw err;
    });
};

export const requestTokenAdmin = async (idSeccion, flexibleTokenization) => {
    return await TokensAPI.getInstance().requestTokenAdmin(idSeccion, flexibleTokenization)
    .catch (err => {
        throw err;
    });
};

export const releaseToken = () => {
    return TokensAPI.getInstance().releaseToken()
    .catch (err => {
        throw err;
    });
};

export const isOnlyReadSectionContactos = (idSection) => {
    return TokensAPI.getInstance().isOnlyReadSectionContactos(idSection);
}

export const isOnlyReadSectionClients = (idSection) => {
    return TokensAPI.getInstance().isOnlyReadSectionClients(idSection);
}

export const isOnlyReadSectionCases = (idSection) => {
    return TokensAPI.getInstance().isOnlyReadSectionCases(idSection);
}

export const isOnlyReadSectionAdmin = (idSection) => {
    return TokensAPI.getInstance().isOnlyReadSectionAdmin(idSection);
}
