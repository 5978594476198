import axios from 'axios';

import { getDateAsStr } from '../utils/DateUtils'

import Session from '../../logged_in/session/Session';
import { 
    /* endPointHonorarios,  */
    endPointHonorariosCaso, 
    endPointHonorariosCliente, 
    endPointHonorariosUserPorTarea, 
    endPointHonorariosUserPorAbono, 
    host 
} from './APIConsts';
import EstructurasConst from '../../logged_in/components/casos/shared/EstructurasConst';
import EstructurasConstClientes from '../../logged_in/components/clientes/shared/EstructurasConst';

export default class HonorariosAPI {
    static _instance = null;

    constructor() {
        if (HonorariosAPI._instance !== null)
            throw new Error('Falla de instanciación: HonorariosAPI es singleton. Utilizar HonorariosAPI.getInstance()');
        else
            HonorariosAPI._instance = this;
    }

    static getInstance = () => {
        if (HonorariosAPI._instance === null)
            HonorariosAPI._instance = new HonorariosAPI();
        return HonorariosAPI._instance;
    };

    session = Session.getInstance();


    getHonorariosUserPorTarea = async () => {
        const u_id = this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointHonorariosUserPorTarea}?user_id=${u_id}`)
        .then(honorarios => {
            return this._deserializeHonorariosUserPorTarea(honorarios);
        })
        .catch (err => {
            throw err;
        });
    }

    
    getHonorariosUserPorAbono = async (startDate, endDate) => {
        const startDateString = getDateAsStr(startDate);
        const endDateString = getDateAsStr(endDate);
        const u_id = this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointHonorariosUserPorAbono}?user_id=${u_id}&start_date_inclusive=${startDateString}&end_date_exclusive=${endDateString}`)
        .then(honorarios => {
            return this._deserializeHonorariosUserPorAbono(honorarios);
        })
        .catch (err => {
            throw err;
        });
    }

    
    getHonorariosUserPorPeriodo = async (startDate, endDate) => {
        const startDateString = getDateAsStr(startDate);
        const endDateString = getDateAsStr(endDate);
        const u_id = this.session.getLoggedUser().id;
        return axios.get(`${host}/${endPointHonorariosUserPorTarea}?user_id=${u_id}&start_date_inclusive=${startDateString}&end_date_exclusive=${endDateString}`)
        .then(honorarios => {
            return this._deserializeHonorariosUserPorTarea(honorarios);
        })
        .catch (err => {
            throw err;
        });
    }

    
    getHonorariosCaso = async (caso_id) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        return axios.get(`${host}/${endPointHonorariosCaso}?caso_id=${caso_id}&estudio_id=${estudio_id}`)
        .then(honorarios => {
            return this._deserializeHonorariosCaso(honorarios);
        })
        .catch (err => {
            throw err;
        });
    }

    
    getHonorariosCliente = async (cliente_id) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        return axios.get(`${host}/${endPointHonorariosCliente}?cliente_id=${cliente_id}&estudio_id=${estudio_id}`)
        .then(honorarios => {
            return this._deserializeHonorariosCliente(honorarios);
        })
        .catch (err => {
            throw err;
        });
    }

    /* 
    getEstudioHonorarios = async (cache_it) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        return axios.get(`${host}/${endPointHonorarios}?estudio_id=${estudio_id}`)
        .then(honorarios => {
            if (cache_it)
                this.session.setHonorarios(honorarios);
            return honorarios;
        })
        .catch (err => {
            throw err;
        });
    }

    
    getHonorarios = async (estudio_id) => {
        const user_id = this.session.getLoggedUser().user_id;
        return axios.get(`${host}/${endPointHonorarios}?estudio_id=${estudio_id}&user_id=${user_id}`)
        .then(honorarios => {
            return this._deserializeHonorarios(honorarios, estudio_id, user_id);
        })
        .catch (err => {
            throw err;
        });
    }

        
    editHonorarios = async (estudio_id, idEstructura, data) => {
        const user_id = this.session.getLoggedUser().user_id;
        const edit = this._serializeEstructuraHonorarios(estudio_id, user_id, idEstructura, data);
        return axios.put(`${host}/${endPointHonorarios}?`, edit)
        .catch (err => {
            throw err;
        });
    }
 */
  
    _serializeEstructuraHonorarios = (estudio_id, user_id, idEstructura, data) => {
        return {
            'user_id': user_id,
            'estudio_id': estudio_id,
            'payload': {
                [idEstructura]: data
            }
        }
    }

    _deserializeHonorariosUserPorTarea = async (honorariosJson) => {
        honorariosJson.list = honorariosJson.list.filter((item) => !(item.adeudado === 0 && item.honorarios === 0));
        return honorariosJson;
    }

    _deserializeHonorariosUserPorAbono = async (honorariosJson) => {
        honorariosJson.list = honorariosJson.list.filter((item) => item.honorarios !== 0);
        return honorariosJson;
    }
    
    _deserializeHonorariosCaso = async (honorariosJson) => {
        const honorariosOut = {};
        honorariosOut[EstructurasConst.idBalance] = honorariosJson.balance?honorariosJson.balance:0;
        honorariosOut[EstructurasConst.idHonorariosPactados] = honorariosJson.honorariosPactados?honorariosJson.honorariosPactados:0;
        honorariosOut[EstructurasConst.idRegulacionesCostas] = honorariosJson.honorariosRegulaciones?honorariosJson.honorariosRegulaciones:0;
        honorariosOut[EstructurasConst.idPagos] = honorariosJson.pagos?honorariosJson.pagos:0;
        honorariosOut[EstructurasConst.idTareas] = honorariosJson.tareas?honorariosJson.tareas:0;

        return honorariosOut;
    }

    _deserializeHonorariosCliente = async (honorariosJson) => {
        const honorariosOut = {};
        honorariosOut[EstructurasConstClientes.idBalance] = honorariosJson.balance?honorariosJson.balance:0;
        honorariosOut[EstructurasConstClientes.idHonorariosAbono] = honorariosJson.honorariosAbono?honorariosJson.honorariosAbono:0;
        honorariosOut[EstructurasConstClientes.idHonorariosPactados] = honorariosJson.honorariosPactados?honorariosJson.honorariosPactados:0;
        honorariosOut[EstructurasConstClientes.idPagos] = honorariosJson.pagos?honorariosJson.pagos:0;
        
        return honorariosOut;
    }

    _deserializeHonorarios = async (honorariosJson, estudio_id, user_id) => {
        /* honorariosJson.nombre = decodeURIComponent(honorariosJson.nombre);
        honorariosJson.apellido = decodeURIComponent(honorariosJson.apellido);
        honorariosJson.fecha_inicio = deserializeDateStrLocalGmt(honorariosJson.fecha_inicio);
        honorariosJson.id = user_id; */
        
        return honorariosJson;
    }
}
