import axios from 'axios';

import { serializeDateAsJsonUTC, deserializeDateStrLocalGmt } from '../utils/DateUtils'

import { 
    endPointPlantillas, 
    endPointGetTiposEscritos,
    endPointGetTemplate,
    host,
} from './APIConsts';

import Session from '../../logged_in/session/Session';
import MEVAPI from './MEVAPI';


export default class EscritosPlantillasAPI {
    static _instance = null;

    constructor() {
        if (EscritosPlantillasAPI._instance !== null)
            throw new Error('Falla de instanciación: EscritosPlantillasAPI es singleton. Utilizar EscritosPlantillasAPI.getInstance()');
        else
            EscritosPlantillasAPI._instance = this;
    }

    static getInstance = () => {
        if (EscritosPlantillasAPI._instance === null)
            EscritosPlantillasAPI._instance = new EscritosPlantillasAPI();
        return EscritosPlantillasAPI._instance;
    };

    session = Session.getInstance();

    
    getPlantillas = async () => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const params = `estudio_id=${estudio_id}`;
        
        return axios.get(`${host}/${endPointPlantillas}?${params}`)
        .then(plantillas => {
            return this._deserializePlantillasPresenter(plantillas);
        })
        .catch (err => {
            throw err;
        });
    }

    editPlantilla = async (plantilla_id, tipo_escrito, template, in_trash) => {
        const modifico_id = this.session.getLoggedUser().id;
        const edit = this._serializePlantilla(plantilla_id, modifico_id, tipo_escrito, template, in_trash);
        return axios.put(`${host}/${endPointPlantillas}?`, edit)
        .then(plantilla => {
            return plantilla;
        })
        .catch (err => {
            throw err;
        });
    }

    addPlantilla = async (tipo_escrito, template) => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const creador_id = this.session.getLoggedUser().id;
        
        const plantilla = this._serializeNewPlantilla(estudio_id, creador_id, tipo_escrito, template);
        return axios.post(`${host}/${endPointPlantillas}?`, plantilla)
        .then(plantillaNueva => {
            plantillaNueva.fecha_inicio = deserializeDateStrLocalGmt(plantillaNueva.fecha_inicio);
            plantillaNueva.idBE = plantillaNueva.id;
            return plantillaNueva;
        })
        .catch (err => {
            throw err;
        });
    }

    _getTemplate = async (tipo_escrito) => {
        if (tipo_escrito === 'Otro') return '';
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const params = `estudio_id=${estudio_id}&tipo_escrito=${tipo_escrito}`;
        
        return axios.get(`${host}/${endPointGetTemplate}?${params}`)
        .then(template => {
            return decodeURIComponent(template);
        })
        .catch (err => {
            throw err;
        });
    }

    getTiposEscritos = async () => {
        const estudio_id = this.session.getLoggedUser().estudio_id;
        const params = `estudio_id=${estudio_id}`;
        
        return axios.get(`${host}/${endPointGetTiposEscritos}?${params}`)
        .then(tipos => {
            tipos.push('Otro');
            return tipos;
        })
        .catch (err => {
            throw err;
        });
    }

    
    _serializePlantilla = (plantilla_id, modifico_id, tipo_escrito, template, in_trash) => {
        return {
            'plantilla_id': plantilla_id, 
            'modifico_id': modifico_id, 
            'fecha': serializeDateAsJsonUTC(new Date()), 
            'tipo_escrito': tipo_escrito, 
            'template': template, 
            'in_trash': in_trash
        }
    }
    

    _serializeNewPlantilla = (estudio_id, creador_id, tipo_escrito, template) => {
        return {
            'estudio_id': estudio_id,
            'creador_id': creador_id, 
            'fecha': serializeDateAsJsonUTC(new Date()), 
            'tipo_escrito': tipo_escrito, 
            'template': template, 
        }
    }
    
    
    _deserializePlantillasPresenter = (plantillas) => {
        const data = [];
        const htmlEscrito = [];

        plantillas.map((plantilla, index) => {
            plantilla.idBE = plantilla.id;
            plantilla.i = index;
            plantilla.id = index; // i e id son iguales por malinterpretación en customTable
            plantilla.estudio_id = plantilla.estudio_id;
            plantilla.modifico_id = plantilla.modifico_id;
            plantilla.fecha_inicio = deserializeDateStrLocalGmt(plantilla.fecha_inicio);
            plantilla.tipo_escrito = plantilla.tipo_escrito;
            //plantilla.template = decodeURIComponent(plantilla.template);
            plantilla.template = plantilla.template;
            plantilla.in_trash = plantilla.in_trash;
            
            data.push(
                {
                    fecha: plantilla.fecha_inicio,
                    i: plantilla.i,
                    id: plantilla.id,
                    idBE: plantilla.idBE,
                    modif: plantilla.modifico_id,
                    tipo: plantilla.tipo_escrito,
                    inTrash: plantilla.in_trash
                  }
            );
            htmlEscrito.push(plantilla.template);
        });

        return {
            'data': data,
            'htmlEscrito': htmlEscrito
        }
    }


    loggedUser = Session.getInstance().getLoggedUser();

    tagContents = {
        'estudio.estudio': {
            tag: 'ESTUDIO',
            symbol: '🏠',
            label: 'Nombre del estudio',
            value: this.loggedUser.estudio,
        },
        'estudio.domicilio': {
            tag: 'DOMICILIO',
            symbol: '❿',
            label: 'Domicilio del estudio',
            value: this.loggedUser._loggedEstudio['dirección'],
        },
        'estudio.ciudad': {
            tag: 'CIUDAD',
            symbol: '🏢',
            label: 'Ciudad',
            value: this.loggedUser._loggedEstudio.ciudad,
        },
        'estudio.cp': {
            tag: 'CP',
            symbol: '✉',
            label: 'Código Postal',
            value: this.loggedUser._loggedEstudio['código postal'],
        },
        'estudio.email': {
            tag: 'EMAIL',
            symbol: '＠',
            label: 'Email del estudio',
            value: this.loggedUser._loggedEstudio.email_estudio,
        },
        'estudio.telefono': {
            tag: 'TEL',
            symbol: '☏',
            label: 'Teléfono del estudio',
            value: this.loggedUser._loggedEstudio['telefono_estudio'],
        },
        'user.name': {
            tag: 'NOMBRE',
            symbol: '👱',
            label: 'Usuario Quasar',
            value: `${this.loggedUser.nombre} ${this.loggedUser.apellido}`,
        },
        'user.colegio': {
            tag: 'COLEGIO',
            symbol: '📑',
            label: 'Colegio',
            value: this.loggedUser.colegio,
        },
        'user.matricula': {
            tag: 'MATRICULA',
            symbol: '💼',
            label: 'Matrícula',
            value: this.loggedUser.matricula,
        },
        'user.legal': {
            tag: 'LEGAL',
            symbol: '📬',
            label: 'Domicilio Legal',
            value: this.loggedUser.dom_legal,
        },
        'user.electronico': {
            tag: 'ELECTRONICO',
            symbol: '📧',
            label: 'Domicilio Electrónico',
            value: this.loggedUser.dom_electronico,
        },
        'user.iva': {
            tag: 'IVA',
            symbol: '🎫',
            label: 'IVA',
            value: this.loggedUser.iva,
        },
        'user.cuit': {
            tag: 'CUIT',
            symbol: '🔨',
            label: 'CUIT',
            value: this.loggedUser.cuit,
        },
        'user.legajo': {
            tag: 'LEGAJO',
            symbol: '📜',
            label: 'Legajo',
            value: this.loggedUser.legajo,
        },
        'user.email': {
            tag: 'P.EMAIL',
            symbol: '📩',
            label: 'Email de usuario Quasar',
            value: this.loggedUser.email,
        },
        'user.telefono': {
            tag: 'P.TEL',
            symbol: '📞',
            label: 'Teléfono de usuario Quasar',
            value: this.loggedUser.telefono,
        },
        'cliente.name': {
            tag: 'CLIENTE',
            symbol: '👤',
            label: 'Cliente',
            value: null,
        },
        'caso.caratula': {
            tag: 'CARATULA',
            symbol: '⚖',
            label: 'Carátula',
            value: null,
        },
        'caso.numero_expediente': {
            tag: 'EXPEDIENTE',
            symbol: '📖',
            label: 'Nro. Expediente',
            value: null,
        }
    }
    
        
    getTagContentArray = (keyType, secondaryKeyType) => {
        if (!(!!keyType)){
            return Object.keys(this.tagContents).map(key => this.tagContents[key].symbol)
        }

        const keys = Object.keys(this.tagContents);
        const keysMatchingType = keys.filter(key => key.split(".")[0] === keyType || key.split(".")[0] === secondaryKeyType);
        return keysMatchingType.map(key => this.tagContents[key].symbol)
        
        return Object.keys(this.tagContents).map(key => this.tagContents[key].symbol)
        //return Object.keys(this.tagContents).map(key => this.tagContents[key].tag)
    }

    setInstanceEscrito = async (tipo, cliente, expediente) => {

        const _constructTag = (tagContent) => {
            return tagContent;
            //return `&lt;${tagContent}&gt;`;
        }
        
        this.tagContents['cliente.name'].value = Session.getInstance().getClienteString(cliente);
        if (!!expediente) {
            await MEVAPI.getInstance().getExpedienteMEV(expediente)
            .then(resp => {
                this.tagContents['caso.caratula'].value = resp.expediente.caratula;
                this.tagContents['caso.numero_expediente'].value = resp.expediente.numero_expediente;
            })
            .catch(err => {
                throw err;
            });
        } else {
            this.tagContents['caso.caratula'].value = '(...)';
            this.tagContents['caso.numero_expediente'].value = '(...)';
        }

        return await this._getTemplate(tipo)
        .then((template) => {
            Object.keys(this.tagContents).map(key => {
                template = template.replaceAll(_constructTag(this.tagContents[key].symbol), !!this.tagContents[key].value? this.tagContents[key].value: '(...)');
                //template = template.replaceAll(_constructTag(this.tagContents[key].tag), !!this.tagContents[key].value? this.tagContents[key].value: '(...)');
            });
            return template;
        })
    }
}
